import GraphQLAdapter from './GraphQLAdapter'
import { GraphQLQueryParams } from './GraphQLAdapter'
// import {  } from '../../../graphql/queries';
// import { updateFreestyleClass } from '../../../graphql/mutations';
import {
  createFreestyleClass,
  updateFreestyleClassWithoutSongs,
} from '../../../graphql/freestyleClassMutations'
import {
  listFreestyleClasss,
  getFreestyleClassWithSongs,
  getFreestyleClass,
} from '../../../graphql/freestyleClassQueries'
import { deleteFreestyleClass } from '../../../graphql/mutations'
import {
  FREESTYLE_CLASS_ENTITY_NUMBER_LIST,
  FREESTYLE_CLASS_ENTITY_STRING_LIST,
} from '../../entities/FreestyleClassEntity'

export default class FreestyleClassGraphQLAdapter extends GraphQLAdapter {
  constructor() {
    super()
    this._adapter = new GraphQLAdapter()
  }

  async createEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // console.info('FreestyleClassGraphQLAdapter: createEntity  entity= ', entity)
    const response = await this._adapter
      .mutation(createFreestyleClass, { input: entity })
      .catch(e => {
        this.logDBError(e)
      })
    // console.info('createEntity = ',response)
    if (response) {
      return response.data.createFreestyleClass
    } else {
      return []
    }
  }

  async fetchEntity(id) {
    const response = await this._adapter
      .query(getFreestyleClass, { id: id })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.log('FreestyleClassGraphQLAdapter: fetchEntity: response=', response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.getFreestyleClass
    } else {
      return isError
    }
  }

  async updateEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // entity.freestyleClassCreatedByUserId = entity.createdByUser.id // Required by resolvers
    // delete entity.createdByUser
    // delete entity.classSongs // remove list
    // console.info('FreestyleClassGraphQLAdapter: updateEntity  entity= ',entity)
    const response = await this._adapter
      .mutation(updateFreestyleClassWithoutSongs, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('FreestyleClassGraphQLAdapter: updateEntity  response= ',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.updateFreestyleClass
    } else {
      return isError
    }
  }

  // deleteEntity
  async deleteEntity(id) {
    // console.info('FreestyleClassGraphQLAdapter: deleteEntity  id= ', id)
    const response = await this._adapter
      .mutation(deleteFreestyleClass, { input: { id: id } })
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.deleteFreestyleClass === null) {
        return null
      }
      return response.data.deleteFreestyleClass
    } else {
      return isError
    }
  }

  async fetchAll(limit = 999999) {
    let queryOptions = GraphQLQueryParams
    queryOptions.limit = limit
    const response = await this._adapter
      .query(listFreestyleClasss, queryOptions)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listFreestyleClasss.items.length === 0) {
        this._seedData()
      }
      return response.data.listFreestyleClasss.items
    } else {
      return isError
    }
  }

  async fetchFreestyleClasssForOwner(owner, limit = 999999) {
    let queryOptions = GraphQLQueryParams
    queryOptions.limit = limit
    queryOptions.filter = {
      owner: { eq: owner },
    }
    const response = await this._adapter
      .query(listFreestyleClasss, queryOptions)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listFreestyleClasss.items.length === 0) {
        this._seedData()
      }
      return response.data.listFreestyleClasss.items
    } else {
      return isError
    }
  }

  async fetchFreestyleClasssForLevel(maxLevel, limit = 999999) {
    if (maxLevel === null) {
      maxLevel = 0
    }
    let queryOptions = GraphQLQueryParams
    queryOptions.limit = limit
    queryOptions.filter = {
      layoutLevel: { le: maxLevel },
      shared: { eq: true },
    }
    const response = await this._adapter
      .query(listFreestyleClasss, queryOptions)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listFreestyleClasss.items.length === 0) {
        this._seedData()
      }
      return response.data.listFreestyleClasss.items
    } else {
      return isError
    }
  }

  async fetchEntityWithSongs(id) {
    const response = await this._adapter
      .query(getFreestyleClassWithSongs, { id: id })
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      // console.log('FreestyleClassGraphQLAdapter:', { response })
      return response.data.getFreestyleClass
    } else {
      return isError
    }
  }

  mapEntityToAdapter(entity) {
    if (entity.id === '_new_') {
      entity.id = null
    }
    if (
      typeof entity.createdByUser !== 'undefined' &&
      entity.createdByUser !== null
    ) {
      entity.freestyleClassCreatedByUserId = entity.createdByUser.id
    }

    delete entity.createdByUser
    delete entity.freestyleSongs

    // INT values should not be empty string
    FREESTYLE_CLASS_ENTITY_NUMBER_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = 0
      }
    })
    // STRING values should be null instead of ''
    FREESTYLE_CLASS_ENTITY_STRING_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = null
      }
    })

    return entity
  }
}
