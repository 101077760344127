import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs' // https://github.com/iamkun/dayjs , tutorial http://zetcode.com/javascript/dayjs/
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import { red } from '@material-ui/core/colors'
import { Button } from '../../components/atoms/Button'

import User, { USER_COMPLETE, USER_ACTIVE } from '../../../../../entities/User'

export const MasterReleaseCard = ({
  userRows,
  userService,
  reloadUsers,
  refresh,
}) => {
  // const [usersToAdvance, setUsersToAdvance] = useState()
  const classes = useStyles()

  const today = dayjs()
  const nextReleaseDate = today.endOf('month').add(1, 'minute')
  const lastDayOfMonth =
    today.format('YYYY-MM-DD') === today.endOf('month').format('YYYY-MM-DD')
  const releaseDate = lastDayOfMonth ? nextReleaseDate : today.startOf('month')
  const usersToAdvance = getUsersToAdvance(userRows, releaseDate)

  if (usersToAdvance === undefined) {
    return null
  }
  return (
    <Card className={classes.card}>
      <CardHeader
        title="Instructor Curated Master Class Release"
        subheader={`Current Release Date: ${releaseDate.format(
          'dddd, YYYY-MM-DD',
        )}`}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Users eligible for release update: {usersToAdvance.length}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          onClick={() =>
            AdvanceReleaseLevel(
              usersToAdvance,
              userService,
              reloadUsers,
              // setUsersToAdvance,
            )
          }
        >
          {`Update Release level to current: ${releaseDate.format(
            'dddd, YYYY-MM-DD',
          )}`}
        </Button>
      </CardActions>
    </Card>
  )
}
MasterReleaseCard.propTypes = {
  userRows: PropTypes.arrayOf(PropTypes.instanceOf(User)),
  userService: PropTypes.object,
  reloadUsers: PropTypes.func,
  refresh: PropTypes.number,
}

const getUsersToAdvance = (userRows, releaseDate) => {
  let users = []
  if (userRows !== undefined && userRows.length) {
    userRows.forEach(user => {
      if (IsBehindRelease(user, releaseDate)) {
        users.push(
          new User({
            id: user.id,
            currentClassRelease: user.currentClassRelease + 1,
            lastReleaseDate: releaseDate.format('YYYY-MM-DD'),
            status: USER_ACTIVE,
          }),
        )
      }
    })
  }
  return users
}

const AdvanceReleaseLevel = async (
  usersToAdvance,
  userService,
  reloadUsers,
) => {
  if (usersToAdvance.length) {
    await userService.batchUpdateUsers(usersToAdvance)
    reloadUsers()
  }
}

const IsBehindRelease = (user, releaseDate) => {
  if (user.lastReleaseDate === '') {
    user.lastReleaseDate = '2019-12-29'
  }
  if (user.lastName === 'ZTest') {
    return false
  }
  if (
    user.active &&
    user.subscriber &&
    (user.status === USER_COMPLETE || user.status === USER_ACTIVE)
  ) {
    return dayjs(user.lastReleaseDate) < releaseDate ? true : false
  }
  return false
}

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 'lg',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}))
