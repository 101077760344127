import UsersGraphQLAdapter from '../adapters/drivers/UsersGraphQLAdapter'
import ActiveUserRepository from '../adapters/repositories/ActiveUserRepository'
import ActiveUserService from './ActiveUserService'
import ClassDecorator from './ClassDecorator'

class AppUserService {
  constructor() {
    this._initializeUserService()
    return this._activeUserService
  }

  _initializeUserService() {
    this._adapter = new UsersGraphQLAdapter()
    this._activeUserRepository = new ActiveUserRepository(this._adapter)
    this._activeUserService = new ActiveUserService(
      this._activeUserRepository,
      this._adapter,
    )
  }
}
export default ClassDecorator.makeSingleton(AppUserService)
