import { Exception } from './Exception'

const retryMessage = 'Please try again later.'

export class ErrorException extends Exception {
  message = `${this} ${retryMessage}`
}

export class Generic2 extends Exception {
  message = `Generic 2 failed. ${retryMessage}`
}

export class DeleteNotFoundException extends Exception {
  message = `${this} ${retryMessage}`
}
