export const RenderBoolean = (value, labels) => {
  if (labels === undefined) {
    labels = ['N', 'Y']
  }
  if (value !== true) {
    return labels[0]
  } else {
    return labels[1]
  }
}
