export const RPMRange = (rpm_a, rpm_b, connector = ' - ') => {
  if (isEmpty(rpm_a) && isEmpty(rpm_b)) {
    return null
  }
  if (!isEmpty(rpm_a) && isEmpty(rpm_b)) {
    if (rpm_a >= 80) {
      return rpm_a + ' (' + Math.round(rpm_a / 2) + ')'
    } else {
      return rpm_a
    }
  }
  if (!isEmpty(rpm_a) && !isEmpty(rpm_b)) {
    if (rpm_a === rpm_b) {
      return rpm_a
    }
    return rpm_a + connector + rpm_b
  }
}

function isEmpty(value) {
  if (value === null || value === 0 || value === undefined) {
    return true
  }
  return false
}
