import { useState, useCallback } from 'react'

export const useDataRefresh = (initial = 0) => {
  const [dataVersion, setDataVersion] = useState(initial)

  return [
    dataVersion,
    useCallback(() => setDataVersion(dataVersion + 1), [dataVersion]),
  ]
}

/*
Usage notes:
const [refresh, setRefresh] = useDataRefresh(0)
setRefresh() // pass 'refresh' as dependecy to hook that fetches data
*/
