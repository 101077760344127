import Amplify, { API, graphqlOperation } from 'aws-amplify'
import config from '../../../aws-exports'
import { createDbErrorLog } from '../../../graphql/mutations'

Amplify.configure(config)

export const GraphQLQueryParams = {
  filter: null,
  limit: 10,
  nextToken: null,
}

export default class GraphQLAdapter {
  constructor() {
    this._provider = API
  }

  async query(query, data, authMode = 'AMAZON_COGNITO_USER_POOLS') {
    const response = await API.graphql(graphqlOperation(query, data, authMode))
    // console.log('GraphQLAdapter: query',response)
    return response
  }

  async mutation(mutation, data) {
    return await API.graphql(graphqlOperation(mutation, data))
  }

  async logDBError(error) {
    const input = { error: error }
    const response = await this._adapter
      .mutation(createDbErrorLog, { input: input })
      .catch(e => {
        console.log('Error', e)
      })
    console.info('createDbErrorLog = ', response, error)
    return { errors: error.errors }
  }

  checkForError(data) {
    if (data.hasOwnProperty('errors')) {
      // console.log('GraphQLAdapter: checkForError',data)
      return data
    } else if (data === null) {
      console.log('checkForError', data)
      return { errors: 'not found' }
    } else {
      return false
    }
  }

  resolveIntegers(entity, fields) {
    fields.forEach(field => {
      if (entity.hasOwnProperty(field) && entity[field] === '') {
        entity[field] = 0
      }
    })
    return entity
  }

  resolveNullableStrings(entity, fields) {
    fields.forEach(field => {
      if (entity.hasOwnProperty(field) && entity[field] === '') {
        entity[field] = null
      }
    })
    return entity
  }
}
