import GraphQLAdapter from './GraphQLAdapter'
import { GraphQLQueryParams } from './GraphQLAdapter'
// import {  } from '../../../graphql/queries';
import { deleteCuratedClass } from '../../../graphql/mutations'
import {
  createCuratedClass,
  updateCuratedClassWithoutSongs,
} from '../../../graphql/curatedClassMutations'
import {
  listCuratedClasss,
  getCuratedClassWithSongs,
  getCuratedClass,
} from '../../../graphql/curatedClassQueries'
// import { CURATED_CLASS_TYPE } from '../../entities/CuratedClassEntity'
import { CuratedClassSeedData } from './JSONSeedData'

export default class CuratedClassGraphQLAdapter extends GraphQLAdapter {
  constructor() {
    super()
    this._adapter = new GraphQLAdapter()
  }

  async createEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    const response = await this._adapter
      .mutation(createCuratedClass, { input: entity })
      .catch(e => {
        this.logDBError(e)
      })
    // console.info('createEntity = ',response)
    if (response) {
      return response.data.createCuratedClass
    } else {
      return []
    }
  }

  async fetchEntity(id) {
    const response = await this._adapter
      .query(getCuratedClass, { id: id })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.log('CuratedClassGraphQLAdapter: fetchEntity: response=', response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.getCuratedClass
    } else {
      return isError
    }
  }

  async updateEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    const response = await this._adapter
      .mutation(updateCuratedClassWithoutSongs, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('CuratedClassGraphQLAdapter: updateEntity  response= ',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.updateCuratedClass
    } else {
      return isError
    }
  }

  // deleteEntity
  async deleteEntity(id) {
    // console.info('CuratedClassGraphQLAdapter: deleteEntity  id= ', id)
    const response = await this._adapter
      .mutation(deleteCuratedClass, { input: { id: id } })
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.deleteCuratedClass === null) {
        return null
      }
      return response.data.deleteCuratedClass
    } else {
      return isError
    }
  }

  async fetchAll(limit = 999999) {
    let queryOptions = GraphQLQueryParams
    queryOptions.limit = limit
    const response = await this._adapter
      .query(listCuratedClasss, queryOptions)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listCuratedClasss.items.length === 0) {
        this._seedData()
      }
      return response.data.listCuratedClasss.items
    } else {
      return isError
    }
  }

  async fetchCuratedClasssForLevel(
    maxLevel,
    classType = 'curated',
    limit = 999999,
  ) {
    if (maxLevel === null) {
      maxLevel = 0
    }
    let queryOptions = GraphQLQueryParams
    queryOptions.limit = limit
    queryOptions.filter = {
      layoutLevel: { le: maxLevel },
      type: { eq: classType },
    }
    // console.log('queryFilter', queryOptions.filter)
    const response = await this._adapter
      .query(listCuratedClasss, queryOptions)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listCuratedClasss.items.length === 0) {
        this._seedData()
      }
      return response.data.listCuratedClasss.items
    } else {
      return isError
    }
  }

  async fetchEntityWithSongs(id, sortBy) {
    const response = await this._adapter
      .query(getCuratedClassWithSongs, { id: id })
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.getCuratedClass
    } else {
      return isError
    }
  }

  mapEntityToAdapter(entity) {
    if (entity.id === '_new_') {
      entity.id = null
    }
    if (
      typeof entity.createdByUser !== 'undefined' &&
      entity.createdByUser !== null
    ) {
      entity.curatedClassCreatedByUserId = entity.createdByUser.id
    }

    delete entity.createdByUser
    delete entity.classSongs

    const intFields = ['layoutLevel', 'group']
    entity = this.resolveIntegers(entity, intFields)

    const strFields = ['classPDFLink', 'playlistURL', 'description']
    entity = this.resolveNullableStrings(entity, strFields)

    return entity
  }

  _seedData() {
    CuratedClassSeedData.map(action => {
      this.createEntity(action)
      return null
    })
  }
}
