export default class ActionComponentService {
  constructor(repository, adapter) {
    this._repository = repository
    this._adapter = adapter
  }

  createActionComponent(entity) {
    return this._repository.createEntity(entity)
  }

  fetchActionComponent(id) {
    return this._repository.fetchEntity(id)
  }

  updateActionComponent(entity) {
    return this._repository.updateEntity(entity)
  }

  deleteActionComponent(id) {
    return this._repository.deleteEntity(id)
  }

  fetchAllActionComponents(sortBy) {
    return this._repository.fetchAll(sortBy)
  }

  FetchAllActionComponentsByLevel(maxLevel, sortBy) {
    return this._repository.FetchAllActionComponentsByLevel(maxLevel, sortBy)
  }

  addActionComponentEntity(entity) {
    // console.log('service: addActionComponentEntity', entity)
    this._repository.addEntity(entity)
    return this._repository.get()[0]
  }

  clear() {
    this._repository.clear()
  }
}
