import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'

import { SortArrayObject } from './components/functions/SortArrayObject'
import AppActiveUserService from '../../../services/AppActiveUserService'
import { FetchActiveUserEntity } from './hooks/useActiveUserService'

import Footer from './components/organisms/Footer'
import AdminRoutes from './features/admin/AdminRoutes'
import HomePage from '../../../../pages/HomePage'
import CuratedPage from './features/classes/curated/CuratedPage'
import CuratedEdit from './features/classes/curated/CuratedEdit'
import CuratedSongDelete from './features/classes/songs/CuratedSongDelete'
import CuratedClassDelete from './features/classes/curated/CuratedClassDelete'
import FreestyleEdit from './features/classes/freestyle/FreestyleEdit'
import FreestyleSongEdit from './features/classes/songs/FreestyleSongEdit'
import FreestyleLayoutPage from './features/classes/freestyle/FreestyleLayoutPage'
import FreestyleSongDelete from './features/classes/songs/FreestyleSongDelete'
import FreestyleClassDelete from './features/classes/freestyle/FreestyleClassDelete'
import CuratedLayoutPage from './features/classes/curated/CuratedLayoutPage'
import LayoutEditPage from '../../../../pages/LayoutEditPage'
import SocialPage from '../../../../pages/SocialPage'
import ToolsPage from '../../../../pages/ToolsPage'
import ProfilePage from '../../../../pages/ProfilePage'
import BrandUsagePage from '../../../../pages/BrandUsagePage'
import ActionComponentsPage from './features/actionComponents/ActionComponentsPage'
import GuidedFreestylePage from './features/classes/freestyle/GuidedFreestylePage'
import CertificationPage from '../../../../pages/CertificationPage'
import OnlineCoachingPage from '../../../../pages/OnlineCoachingPage'
import SuggestionPage from '../../../../pages/SuggestionPage'
import SongEdit from './features/classes/songs/CuratedSongEdit'
import ResponsiveMenuDrawer from '../../../../components/ResponsiveMenuDrawer'
import PageEntity from '../../../entities/PageEntity'
import Terms from '../../../../pages/Terms'
import CustomPage from '../../../../pages/CustomPage'
import { CopyCuratedClass } from './features/classes/curated/CopyCuratedClass'
import { CopyFreestyleClass } from './features/classes/freestyle/CopyFreestyleClass'
import SharedFreestylePage from './features/classes/shared/SharedFreestylePage'

export const Routes = ({ activeUser, handleSignOut }) => {
  // console.log('Routes props = ', { activeUser, handleSignOut })
  const [isLoading, setIsLoading] = useState(true)

  function usePageViews() {
    let location = useLocation()
    const pageDetail = getPageProps(location.pathname)
    const title = pageDetail.pageContent.pageTitle

    useEffect(() => {
      ReactGA.set({ page: location.pathname }) // Update the user's current page
      ReactGA.pageview(location.pathname, [], title) // Record a pageview for the given page
    }, [location, title])
  }

  const activeUserService = new AppActiveUserService()
  const activeUserData = FetchActiveUserEntity(
    activeUser.id,
    activeUser.currentClassRelease,
    activeUserService,
  )

  useEffect(() => {
    if (isLoading && activeUserData) {
      setIsLoading(false)
    }
  }, [isLoading, activeUserData, setIsLoading])

  if (isLoading) {
    return null
  }

  const sortPages = ['sortBy']
  const pages = SortArrayObject(activeUserData.pages, sortPages)
  const actionComponents = SortArrayObject(activeUserData.actionComponents, [
    'name',
  ])

  const getPageProps = linkPath => {
    const page = pages
      .filter(page => {
        return page.linkPath === linkPath
      })
      .sort()
    if (page.length === 1) {
      return { pageContent: page[0] }
    } else {
      return { pageContent: new PageEntity() }
    }
  }

  function RecordPageView() {
    usePageViews()
    return null
  }

  const customPages = pages.filter(page => {
    return page.menuSection === 'Custom'
  })

  if (activeUser.agreeTerms !== true) {
    const pageContent = getPageProps('/terms')
    return (
      <Router>
        <Terms {...{ activeUser, ...pageContent, handleSignOut }} />
      </Router>
    )
  }

  return (
    <Router>
      <RecordPageView />
      <ResponsiveMenuDrawer
        anchor="right"
        {...{ pages, activeUser, handleSignOut }}
      >
        <Route
          exact
          path={['/', '/index.html']}
          render={props => (
            <HomePage {...getPageProps('/')} activeUser={activeUser} />
          )}
        />

        {/* // Curated Classes */}
        <Route
          path="/curated/list"
          exact
          render={props => (
            <CuratedPage
              {...getPageProps('/curated/list')}
              activeUser={activeUser}
            />
          )}
        />
        <Route
          path="/curated/edit/:curatedId"
          exact
          render={props => <CuratedEdit {...props} activeUser={activeUser} />}
        />

        <Route
          path="/curated/delete/:classID"
          exact
          render={props => (
            <CuratedClassDelete
              {...props}
              activeUser={activeUser}
              classType="curated"
            />
          )}
        />

        <Route
          path="/curated/copy/:classID/type/:destinationType"
          exact
          render={props => (
            <CopyCuratedClass {...props} activeUser={activeUser} />
          )}
        />

        <Route
          path="/curated/class/:classID/songs"
          exact
          render={props => (
            <CuratedLayoutPage {...props} activeUser={activeUser} />
          )}
        />
        <Route
          path="/curated/class/:classID/songs/:songID/edit"
          exact
          render={props => (
            <SongEdit
              {...props}
              activeUser={activeUser}
              actionComponents={actionComponents}
            />
          )}
        />

        <Route
          path="/curated/class/:classID/songs/:songID/delete"
          exact
          render={props => (
            <CuratedSongDelete {...props} activeUser={activeUser} />
          )}
        />
        {/* Freestyle Classes */}

        <Route
          path="/freestyle/list"
          render={props => (
            <GuidedFreestylePage
              {...getPageProps('/freestyle/list')}
              {...props}
              activeUser={activeUser}
            />
          )}
        />

        <Route
          path="/freestyle/edit/:freestyleId"
          exact
          render={props => (
            <FreestyleEdit
              {...props}
              activeUser={activeUser}
              classType="freestyle"
            />
          )}
        />

        <Route
          path="/freestyle/delete/:classID"
          exact
          render={props => (
            <FreestyleClassDelete
              {...props}
              activeUser={activeUser}
              classType="freestyle"
            />
          )}
        />

        <Route
          path="/freestyle/copy/:classID/type/:destinationType"
          exact
          render={props => (
            <CopyFreestyleClass {...props} activeUser={activeUser} />
          )}
        />

        <Route
          path="/(freestyle)/class/:classID/songs"
          exact
          render={props => (
            <FreestyleLayoutPage {...props} activeUser={activeUser} />
          )}
        />

        <Route
          path="/freestyle/class/:classID/songs/:songID/edit"
          exact
          render={props => (
            <FreestyleSongEdit
              {...props}
              activeUser={activeUser}
              actionComponents={actionComponents}
            />
          )}
        />

        <Route
          path="/freestyle/class/:classID/songs/:songID/delete"
          exact
          render={props => (
            <FreestyleSongDelete {...props} activeUser={activeUser} />
          )}
        />

        <Route
          path="/freestyle/shared"
          render={props => (
            <SharedFreestylePage
              {...getPageProps('/freestyle/shared')}
              {...props}
              activeUser={activeUser}
            />
          )}
        />

        <Route
          path="/shared/class/:classID/songs"
          exact
          render={props => (
            <FreestyleLayoutPage
              {...props}
              activeUser={activeUser}
              sharedFreestyle={true}
            />
          )}
        />

        <Route
          path="/layouts/:classLayoutId/edit"
          exact
          render={props => (
            <LayoutEditPage {...props} activeUser={activeUser} />
          )}
        />

        <Route
          path="/certification_tools"
          render={props => (
            <CertificationPage
              {...getPageProps('/certification_tools')}
              {...props}
              activeUser={activeUser}
            />
          )}
        />
        <Route
          path="/online_coaching"
          render={props => (
            <OnlineCoachingPage
              {...getPageProps('/online_coaching')}
              {...props}
              activeUser={activeUser}
            />
          )}
        />
        <Route
          path="/suggest_upload"
          render={props => (
            <SuggestionPage
              {...getPageProps('/suggest_upload')}
              {...props}
              activeUser={activeUser}
            />
          )}
        />
        <Route
          path="/social"
          render={props => (
            <SocialPage
              {...getPageProps('/social')}
              {...props}
              activeUser={activeUser}
            />
          )}
        />
        <Route
          path="/tools"
          render={props => <ToolsPage {...getPageProps('/tools')} {...props} />}
        />
        <Route
          path="/profile"
          render={props => (
            <ProfilePage
              {...getPageProps('/profile')}
              {...props}
              activeUser={activeUser}
            />
          )}
        />
        <Route
          path="/brand_usage"
          render={props => (
            <BrandUsagePage {...getPageProps('/brand_usage')} {...props} />
          )}
        />
        <Route
          path="/components/view"
          exact
          render={props => (
            <ActionComponentsPage
              {...getPageProps('/components/view')}
              {...props}
              activeUser={activeUser}
            />
          )}
        />
        <AdminRoutes {...{ activeUser, pages }} />
        {customPages.length > 0
          ? customPages.map(page => {
              return (
                <Route
                  key={page.linkPath}
                  path={page.linkPath}
                  render={props => (
                    <CustomPage
                      pageContent={page}
                      {...props}
                      activeUser={activeUser}
                    />
                  )}
                />
              )
            })
          : null}
        <Footer {...{ activeUser, pages }} />
      </ResponsiveMenuDrawer>
    </Router>
  )
}
