export const listCuratedClasss = `query ListCuratedClasss(
  $filter: ModelCuratedClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listCuratedClasss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
      createdByUser {
        id
        firstName
        lastName
      }
    }
    nextToken
  }
}
`

export const getCuratedClass = `query GetCuratedClass($id: ID!) {
  getCuratedClass(id: $id) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      active
    }
    owner
  }
}
`

export const getCuratedClassWithSongs = `query GetCuratedClass($id: ID!) {
  getCuratedClass(id: $id) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
    }
    classSongs(limit:999) {
      items {
        id
        song
        artist
        coaching_notes
        coaching_notes_simple
        owner
        pr_a
        pr_b
        rpm_a
        rpm_b
        sortOrder
        videoURL
        actionComponent {
          id
          name
          pr_range
          rpm_high
          rpm_low
          coachingNotes
          simpleCoaching
          level
        }
      }
    }
    owner
  }
}
`
