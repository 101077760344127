import React, { useState, useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles, Table, TableBody, Button } from '@material-ui/core'

import AppPageService from '../../../../../services/AppPageService'
import { FetchAllPages } from '../../hooks/usePageService'
import { Page } from '../../components/molecules/Page'
import PageLoader from '../../components/molecules/PageLoader'
import User from '../../../../../entities/User'
import { ErrorException } from '../../errors/Exceptions'
// import { SortArrayObject } from '../../components/functions/SortArrayObject'
import { useDataRefresh } from '../../hooks/useDataRefresh'
import { FilterBar } from '../../components/atoms/FilterBar'
import { SitePageRow, SitePageRowHeader } from './SitePageRow'

const PageListPage = ({ activeUser, pageContent }) => {
  const [isLoading, setIsLoading] = useState(true)
  const defaultSortBy = ['sortBy']
  const [sortBy] = useState(defaultSortBy)
  const [pageRows, setPageRows] = useState()
  const [refresh, setRefresh] = useDataRefresh(0)

  const pageService = new AppPageService()
  const fetchedData = FetchAllPages(sortBy, pageService, refresh)

  // const sort = sortBy => {
  //   const sortedPages = SortArrayObject(fetchedData, sortBy)
  //   const query = ['adminOnly', true]
  //   const filteredPages = FilterArrayObject(sortedPages, query)
  //   setPageRows(filteredPages)
  // }

  const onFilterChange = event => {
    const filter = event.target.value
    const filtdata = PagesRowFilter(fetchedData, filter)
    setPageRows(filtdata)
  }

  useEffect(() => {
    if (isLoading && fetchedData) {
      // console.log('isLoading', fetchedData.length, refresh)
      setPageRows(fetchedData)
      setIsLoading(false)
    }
  }, [isLoading, fetchedData, setIsLoading, refresh])

  if (isLoading) {
    return <PageLoader />
  }

  const onDeletePage = async id => {
    // console.log('onDeletePage', id)
    const result = await pageService.deletePage(id)
    if (result === null) {
      throw new ErrorException('Specified Page was not found.')
    }
    await setIsLoading(true)
    await setRefresh()
  }

  if (pageRows !== undefined && pageRows !== null) {
    return (
      <Page.WithIntro pageContent={pageContent}>
        <RenderAddButton activeUser={activeUser} />
        <FilterBar onChange={onFilterChange} title="Filter Site Pages" />
        <Table className={styles.table} size="small">
          <SitePageRowHeader {...{ activeUser }} />
          <TableBody>
            {pageRows.map(page => (
              <SitePageRow
                key={page.id}
                {...{ page, activeUser, onDeletePage }}
              />
            ))}
          </TableBody>
        </Table>
      </Page.WithIntro>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(PageListPage)

PageListPage.propTypes = {
  activeUser: PropTypes.instanceOf(User),
}

const RenderAddButton = ({ activeUser }) => {
  if (activeUser && activeUser.isAdminUser()) {
    return (
      <Button variant="contained" className={styles.button}>
        <NavLink to="/admin/pages/edit/_new_">New Page</NavLink>
      </Button>
    )
  } else {
    return null
  }
}

const PagesRowFilter = (data, filter) => {
  const filtered = data.filter(function(row) {
    const inLinkText = row.linkText.toLowerCase().includes(filter.toLowerCase())
    const inPageTitle = row.pageTitle
      .toLowerCase()
      .includes(filter.toLowerCase())
    return inLinkText || inPageTitle
  })
  return filtered
}

const styles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(6),
    // marginTop: 10,
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  headline: {
    padding: 6,
  },
  table: {
    minWidth: 650,
  },
}))
