import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  Grid,
  FormControlLabel,
  Switch,
} from '@material-ui/core'

import ClassDisplayModeButtons from './ClassDisplayModeButtons'
import { SongRow, SongRowHeader } from './SongRow'
import User from '../../../../../../entities/User'
import { CURATED_CLASS_TYPE } from '../../../../../../entities/CuratedClassEntity'
import { FREESTYLE_CLASS_TYPE } from '../../../../../../entities/FreestyleClassEntity'
import { Button } from '../../../components/atoms/Button'
import { Link } from '../../../components/atoms/Link'
import { LinkButton } from '../../../components/atoms/LinkButton'
import AppFreestyleClassService from '../../../../../../services/AppFreestyleClassService'
import { Spacing } from '../../../components/atoms/Spacing'
import { ExternalLinkButtonTracker } from '../../../components/molecules/ExternalLinkButtonTracker'
import { BlankClassLayoutButtonLink } from '../../../components/molecules/BlankClassLayoutButtonLink'

const ClassSongs = ({ classLayout, sharedFreestyle, activeUser }) => {
  const [classShared, setClassShared] = useState(classLayout.shared)
  const [classFullNotes, setClassFullNotes] = useState(false)
  const classID = classLayout.id
  const classType = classLayout.type
  let classSongs = []
  if (classType === CURATED_CLASS_TYPE) {
    classSongs = classLayout.classSongs
  } else if (classType === FREESTYLE_CLASS_TYPE) {
    classSongs = classLayout.freestyleSongs
  }

  const [songDisplayMode, setSongMode] = useState('detailed')
  const classes = useStyles()
  const freestyleClassService = new AppFreestyleClassService()

  const handleModeChange = value => {
    setSongMode(value)
  }
  const toggleSharing = async () => {
    const newValue = !classShared
    console.log('toggle', newValue)
    await freestyleClassService.updateFreestyleClass({
      id: classID,
      shared: newValue,
    })
    setClassShared(newValue)
  }
  const toggleClassFullNotes = () => {
    setClassFullNotes(!classFullNotes)
  }

  if (classSongs !== []) {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6} lg={6}>
            <RenderSpotifyLink classLayout={classLayout} />
            {songDisplayMode !== 'class' ? (
              <>
                <RenderBPMFinderLink />
                <RenderCopyFreestyle {...{ activeUser, classLayout }} />
                <RenderClassPDFLink classLayout={classLayout} />
                <BlankClassLayoutButtonLink />
                <RenderAddSong
                  {...{
                    activeUser,
                    classID,
                    classType,
                    sharedFreestyle,
                    classes,
                  }}
                />
              </>
            ) : null}
          </Grid>
          <ClassDisplayModeButtons changeHandler={handleModeChange} />
        </Grid>
        <Paper className={classes.paper}>
          <Spacing p={1}>
            <RenderSharedSwitch
              {...{
                activeUser,
                classType,
                sharedFreestyle,
                classShared,
                toggleSharing,
              }}
            />
            <RenderClassFullNotes
              {...{ classFullNotes, songDisplayMode, toggleClassFullNotes }}
            />
          </Spacing>
          <Table className={classes.table} size="small">
            <SongRowHeader
              {...{ songDisplayMode, sharedFreestyle, activeUser }}
            />
            <TableBody>
              {classSongs.map(song => (
                <SongRow
                  key={song.id}
                  {...{
                    song,
                    classID,
                    classType,
                    songDisplayMode,
                    sharedFreestyle,
                    activeUser,
                    classFullNotes,
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  } else {
    return null
  }
}

export default ClassSongs
ClassSongs.propTypes = {
  classLayout: PropTypes.object,
  activeUser: PropTypes.instanceOf(User),
}

const RenderAddSong = ({
  activeUser,
  classID,
  classType,
  sharedFreestyle,
  classes,
}) => {
  // console.log('RenderAddSong', activeUser)
  // /curated/class/:classLayoutId/songs/:songID/edit
  if (sharedFreestyle) {
    return null
  }
  let canAdd = false

  if (activeUser && activeUser.isAdminUser()) {
    canAdd = true
  }
  if (
    classType === FREESTYLE_CLASS_TYPE &&
    activeUser.currentClassRelease >= 7
  ) {
    canAdd = true
  }
  if (canAdd) {
    return (
      <Link to={`/${classType}/class/${classID}/songs/_new_/edit`}>
        <Button>Add Song</Button>
      </Link>
    )
  } else {
    return null
  }
}

const RenderSharedSwitch = ({
  activeUser,
  classType,
  sharedFreestyle,
  classShared,
  toggleSharing,
}) => {
  let canShare = false
  if (classType === FREESTYLE_CLASS_TYPE && sharedFreestyle !== true) {
    canShare = true
  }
  if (classType === FREESTYLE_CLASS_TYPE && activeUser.isAdminUser()) {
    canShare = true
  }
  if (canShare) {
    return (
      <FormControlLabel
        control={
          <Switch
            checked={classShared}
            onChange={toggleSharing}
            value="true"
            color="primary"
          />
        }
        label="Share this Layout"
      />
    )
  } else {
    return null
  }
}

const RenderCopyFreestyle = ({ activeUser, classLayout }) => {
  let canCopy = false
  if (activeUser.isAdminUser()) {
    canCopy = true
  }
  if (classLayout.layoutLevel <= activeUser.currentClassRelease) {
    canCopy = true
  }
  if (canCopy) {
    return (
      <LinkButton
        to={`/${classLayout.type}/copy/${classLayout.id}/type/freestyle/`}
      >
        Copy to My Guided Freestyle and Customize
      </LinkButton>
    )
  } else {
    return null
  }
}

const RenderSpotifyLink = ({ classLayout }) => {
  const linkText = 'Suggested Spotify Playlist'
  const href = classLayout.playlistURL
  const target = '_spotify'
  const trackerData = {
    category: 'External Link',
    action: 'Open Suggested Spotify Playlist',
    label: `${classLayout.title} (${classLayout.type})`,
  }
  return (
    <ExternalLinkButtonTracker {...{ linkText, href, target, trackerData }} />
  )
}

const RenderBPMFinderLink = () => {
  const linkText = 'Find My Playlist BPM to Convert to RPM'
  const href = 'http://sortyourmusic.playlistmachinery.com/'
  const target = '_bpm'
  const trackerData = {
    category: 'External Link',
    action: 'Find My Playlist BPM',
  }
  return (
    <ExternalLinkButtonTracker {...{ linkText, href, target, trackerData }} />
  )
}

const RenderClassPDFLink = ({ classLayout }) => {
  const linkText = 'Printable Curated Class Layout PDF'
  const href = classLayout.classPDFLink
  const target = '_classPDF'
  const trackerData = {
    category: 'External Link',
    action: 'Open Class Layout PDF',
    label: `${classLayout.title} (${classLayout.type})`,
  }
  return (
    <ExternalLinkButtonTracker {...{ linkText, href, target, trackerData }} />
  )
}

const RenderClassFullNotes = ({
  classFullNotes,
  songDisplayMode,
  toggleClassFullNotes,
}) => {
  if (songDisplayMode !== 'class') {
    return null
  }
  return (
    <FormControlLabel
      control={
        <Switch
          checked={classFullNotes}
          onChange={toggleClassFullNotes}
          value="true"
          color="primary"
        />
      }
      label="Show Full Coaching Notes"
    />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 400,
  },
}))
