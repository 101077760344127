import React, { useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Page } from '../../components/molecules/Page'
import PageLoader from '../../components/molecules/PageLoader'
import SimpleSelect from '../../components/molecules/SimpleSelect'
import AppActionComponentService from '../../../../../services/AppActionComponentService'

// import ActionComponentRepository from '../../../../repositories/ActionComponentRepository'
// import ActionComponentGraphQLAdapter from '../../../../drivers/ActionComponentGraphQLAdapter'

const ActionComponentEdit = ({ activeUser, match, history }) => {
  const actionComponentId = match.params.actionComponentId
  const [actionComponent, setActionComponent] = React.useState(null)

  const actionComponentService = new AppActionComponentService()

  useEffect(() => {
    console.log('useEffect', actionComponentId)
    if (actionComponentId !== '_new_') {
      ;(async actionComponentId => {
        const data = await actionComponentService.fetchActionComponent(
          actionComponentId,
        )
        if (data.createdByUser === null) {
          data.createdByUser = { id: activeUser.id } // assume ownership
        }
        if (!data.errors) {
          setActionComponent(data)
        } else {
          console.log('ActionComponent: data', data)
        }
      })(actionComponentId)
    } else {
      actionComponentService.clear()
      const newActionComponent = actionComponentService.addActionComponentEntity(
        {
          id: '_new_',
          createdByUser: { id: activeUser.id },
        },
      )
      setActionComponent(newActionComponent)
    }
  }, [actionComponentId, actionComponentService, activeUser.id])

  const handleChange = name => event => {
    let newValue = Object.assign(
      Object.create(Object.getPrototypeOf(actionComponent)),
      actionComponent,
    ) // update value while preserving class object
    newValue[name] = event.target.value
    // console.log('handleChange: after',newValue)
    setActionComponent(newValue)
  }

  const saveHandler = async updated => {
    console.log('saveHandler', updated)
    if (updated.id !== '_new_') {
      await actionComponentService.updateActionComponent(updated)
    } else {
      updated.id = null
      const response = await actionComponentService.createActionComponent(
        updated,
      )
      console.log(response)
    }
    history.push('/components/view')
  }

  //
  // const actionId = props.match.params.actionId
  // const activeUser = props.activeUser
  // // const actionAdapter = new bbmockactioncomponentsapi() // Mock Adapter
  // const actionAdapter = new ActionComponentGraphQLAdapter() // GraphQL Adapter
  // const repository = new ActionComponentRepository(actionAdapter)
  // const [actionComponent, setActionComponent] = React.useState(null)

  // useEffect(() => {
  //   if (actionId !== '_new_') {
  //     ;(async actionId => {
  //       const data = await repository.fetchEntity(actionId)
  //       if (data.createdByUser === null) {
  //         data.createdByUser = { id: activeUser.id } // assume ownership
  //       }
  //       console.log('ActionComponentEdit: data', data)
  //       setActionComponent(data)
  //     })(actionId)
  //   } else {
  //     repository.addActionComponent({
  //       id: '_new_',
  //       createdByUser: { id: activeUser.id },
  //     })
  //     setActionComponent(repository.get()[0])
  //   }
  // }, [actionId])
  // const handleChange = name => event => {
  //   console.log(event.target.name)
  //   let newValue = Object.assign(
  //     Object.create(Object.getPrototypeOf(actionComponent)),
  //     actionComponent,
  //   ) // update value while preserving class object
  //   newValue[name] = event.target.value
  //   // console.log('handleChange: after',newValue)
  //   setActionComponent(newValue)
  // }

  // const saveHandler = async updated => {
  //   // console.log('saveHandler',updated)
  //   if (updated.id !== '_new_') {
  //     await repository.updateEntity(updated)
  //   } else {
  //     updated.id = null
  //     await repository.createEntity(updated)
  //   }
  //   // console.log('ActionComponentEdit - saveHandler: updated',updated)
  //   props.history.push('/components/view')
  // }

  const classes = useStyles()
  if (actionComponent !== undefined && actionComponent !== null) {
    console.log('actionComponent', actionComponent)
    const pageContent = {
      pageTitle: 'Action Component Edit:',
      introText: `Editing Notes:
    level = level at which instructors will see this component based on
    their level
    sortBy = sorting position on the screen, sorted ascending.`,
    }
    return (
      <Page.WithIntro pageContent={pageContent}>
        <Card key={actionComponent.id} className={classes.card}>
          {/* <CardHeader title={actionComponent.email} /> */}
          <CardContent>
            {/* <form onSubmit={onFormSubmit} className={classes.container} noValidate autoComplete="off"> */}
            <SimpleSelect
              label="Folder"
              options={actionComponent.folderOptions()}
              optionValue="key"
              optionLabel="value"
              selectedValue={actionComponent.folder}
              variant="outlined"
              onChangeHandler={handleChange('folder')}
            />
            <TextField
              required
              id="outlined-required-name"
              label="Name"
              value={actionComponent.name}
              onChange={handleChange('name')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              id="outlined-required-simpleCoaching"
              label="Simple Coaching"
              value={actionComponent.simpleCoaching}
              onChange={handleChange('simpleCoaching')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            {/* <TextField required id="outlined-required-folder" label="Folder" value={actionComponent.folder} onChange={handleChange('folder')}
					className={classes.textField} margin="normal" variant="outlined"
				/> */}
            <TextField
              required
              id="outlined-required-rpm_low"
              label="RPM Low"
              value={actionComponent.rpm_low}
              onChange={handleChange('rpm_low')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required-rpm_high"
              label="RPM High"
              value={actionComponent.rpm_high}
              onChange={handleChange('rpm_high')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required-pr_range"
              label="PR Range"
              value={actionComponent.pr_range}
              onChange={handleChange('pr_range')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required-coachingNotes"
              label="Coaching Notes"
              value={actionComponent.coachingNotes}
              onChange={handleChange('coachingNotes')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required-level"
              label="Release"
              value={actionComponent.level}
              onChange={handleChange('level')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required-videoURL"
              label="Video URL"
              value={actionComponent.videoURL}
              onChange={handleChange('videoURL')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required-active"
              label="Active"
              value={actionComponent.active}
              onChange={handleChange('active')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            <TextField
              required
              id="outlined-required-sortBy"
              label="Sort By"
              value={actionComponent.sortBy}
              onChange={handleChange('sortBy')}
              className={classes.textField}
              margin="normal"
              variant="outlined"
            />
            {/* </form> */}
          </CardContent>
          <CardActions>
            <NavLink to="/components/view">
              <Button size="small">Cancel</Button>
            </NavLink>
            <Button
              onClick={() => {
                saveHandler(actionComponent)
              }}
              size="small"
              color="primary"
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </Page.WithIntro>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(ActionComponentEdit)

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    minWidth: 275,
    marginBottom: 5,
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
