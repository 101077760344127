import ActiveUserEntity from '../../entities/ActiveUserEntity'
import Repository from './Repository'

export default class UserRepository extends Repository {
  constructor(adapter) {
    super()
    this._adapter = adapter
  }

  addUser(data) {
    this.add(new ActiveUserEntity(data))
  }

  async fetchActiveUserEntity(id, userLevel) {
    // @TODO: verify requestor is user (adapter does auth?)
    const result = await this._adapter.fetchActiveUserEntity(id, userLevel)
    // console.log('ActiveUserRepository: fetchActiveUserEntity result=', result)
    if (result == null || result.hasOwnProperty('errors')) {
      return result
    }
    this._mapFetchedDataToEntity(result)
    let data = this.find({ id: id })[0]
    data.pages = this._orderBy(data.pages, ['sortBy'])
    data.actionComponents = this._orderBy(data.actionComponents, [
      'folder',
      'sortBy',
    ])
    return data
  }

  _mapFetchedDataToEntity(fetchedData) {
    if (fetchedData === [] || fetchedData === undefined) {
      return
    }
    if (Array.isArray(fetchedData)) {
      fetchedData.map(data => this.add(new ActiveUserEntity(data)))
    } else {
      this.add(new ActiveUserEntity(fetchedData))
    }
    return
  }
}
