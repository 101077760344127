import GraphQLAdapter from './GraphQLAdapter'
import {
  PAGE_ENTITY_NUMBER_LIST,
  PAGE_ENTITY_STRING_LIST,
} from '../../entities/PageEntity'
import { createPage, updatePage, deletePage } from '../../../graphql/mutations'
import { getPage, listPages } from '../../../graphql/queries'

import { PageSeedData } from './JSONSeedData'

const _queryParams = { limit: 999999 }

export default class PageGraphQLAdapter extends GraphQLAdapter {
  constructor() {
    super()
    this._adapter = new GraphQLAdapter()
  }

  async createEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // console.info('PageGraphQLAdapter: createEntity  entity= ',entity)
    const response = await this._adapter
      .mutation(createPage, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('createEntity = ',response)
    if (response) {
      return response.data.createPage
    } else {
      return []
    }
  }

  async fetchEntity(id) {
    const response = await this._adapter.query(getPage, { id: id }).catch(e => {
      return this.logDBError(e)
    })
    // console.log('PageGraphQLAdapter: fetchEntity: response=',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.getPage
    } else {
      return isError
    }
  }

  async updateEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // console.info('PageGraphQLAdapter: updateEntity  entity= ',entity)
    const response = await this._adapter
      .mutation(updatePage, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('UserGraphQLAdapter: updateEntity  response= ',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.updatePage
    } else {
      return isError
    }
  }

  // deleteEntity

  async deleteEntity(id) {
    console.info('PageGraphQLAdapter: deleteEntity  id= ', id)
    const response = await this._adapter
      .mutation(deletePage, { input: { id: id } })
      .catch(e => {
        return this.logDBError(e)
      })
    console.info('UserGraphQLAdapter: deleteEntity  response= ', response)
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.deletePage === null) {
        return null
      }
      return response.data.deletePage
    } else {
      return isError
    }
  }

  async fetchAll(queryParams) {
    if (queryParams === undefined) {
      queryParams = _queryParams
    } else {
      queryParams = { ..._queryParams, ...queryParams }
    }
    const response = await this._adapter
      .query(listPages, queryParams)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listPages.items.length === 0) {
        this._seedData()
      }
      return response.data.listPages.items
    } else {
      return isError
    }
  }

  mapEntityToAdapter(entity) {
    if (entity.id === '_new_') {
      entity.id = null
    }

    // INT values should not be empty string
    PAGE_ENTITY_NUMBER_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = 0
      }
    })
    // STRING values should be null instead of ''
    PAGE_ENTITY_STRING_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = null
      }
    })

    return entity
  }

  _seedData() {
    PageSeedData.map(page => {
      this.createEntity(page)
      return null
    })
  }
}
