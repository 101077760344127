export default class ActiveUserService {
  constructor(repository, adapter) {
    this._repository = repository
    this._adapter = adapter
  }

  fetchActiveUserEntity(id, userLevel) {
    // console.log('ActiveUserService: fetchActiveUserEntity', id, userLevel)
    return this._repository.fetchActiveUserEntity(id, parseInt(userLevel))
  }
}
