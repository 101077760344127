import React from 'react'

import { BLANK_LAYOUT_FOLDER_LINK } from '../../AppConstants'
import { ExternalLinkButtonTracker } from './ExternalLinkButtonTracker'

export const BlankClassLayoutButtonLink = () => {
  const linkText = 'Blank Class Layout Templates: Simple and Detailed'
  const href = BLANK_LAYOUT_FOLDER_LINK
  const target = '_drive'
  const trackerData = {
    category: 'External Link',
    action: 'Blank Class Layout Templates: Simple and Detailed',
  }
  return (
    <ExternalLinkButtonTracker {...{ linkText, href, target, trackerData }} />
  )
}
