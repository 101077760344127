import Entity from './Entity'
import CuratedSongEntity from './CuratedSongEntity'

export const CURATED_CLASS_TYPE = 'curated'

export default class CuratedClassEntity extends Entity {
  id
  group
  title
  description
  active
  type = CURATED_CLASS_TYPE
  shared
  layoutLevel
  classPDFLink
  playlistURL
  sortOrder
  createdByUser

  classSongs

  constructor(initialValues = {}) {
    if (initialValues === null) {
      initialValues = {}
    }
    let _defaults = { group: 1, active: false }
    // console.log({ initialValues })
    super({ ..._defaults, ...initialValues })

    if ('classSongs' in initialValues) {
      const { classSongs } = initialValues
      if ('items' in classSongs) {
        this.classSongs = classSongs.items.map(
          data => new CuratedSongEntity(data),
        )
      }
    }
    // console.log('CuratedClassEntity', this)
  }

  groupOptions() {
    return [
      { key: 0, value: '' },
      { key: 1, value: 'Foundational' },
      { key: 2, value: 'Building on Athleticism/Conditioning' },
      { key: 3, value: 'Adding 7 New Components/Upper Body and Layering' },
      { key: 4, value: 'Guided Freestyle Master Class Layout Examples' },
    ]
  }

  groupLabel() {
    let options = this.groupOptions()
    if (this.group !== undefined && this.group > 0) {
      return options[this.group].value
    } else {
      return 'Not Grouped'
    }
  }
}
