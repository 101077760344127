import React from 'react'
import PropTypes from 'prop-types'

import User from '../../../../../entities/User'
import { Spacing } from '../atoms/Spacing'

export const UserIsApproved = ({
  activeUser,
  children,
  message = 'Your account has not been approved.',
}) => {
  if (activeUser.active !== true) {
    return (
      <Spacing p={2}>
        <div>{message}</div>
      </Spacing>
    )
  }
  return children
}
UserIsApproved.propTypes = {
  activeUser: PropTypes.instanceOf(User).isRequired,
  children: PropTypes.node.isRequired,
  message: PropTypes.string,
}
