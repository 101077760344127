// eslint-disable
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    authUsername
    email
    phoneNumber
    firstName
    lastName
    currentClassRelease
    active
    status
    subscriber
    certificationDate
    admin
    address
    address2
    city
    state
    zip
    agreeTerms
    currentlyInstructing
    instructingLocation
    notes
    lastLogin
  }
}
`

// GetUser without notes
export const getActiveUser = `query GetActiveUser($id: ID!) {
  getUser(id: $id) {
    id
    authUsername
    email
    phoneNumber
    firstName
    lastName
    currentClassRelease
    active
    status
    subscriber
    certificationDate
    admin
    address
    address2
    city
    state
    zip
    agreeTerms
    lastLogin
  }
}
`

export const getActiveUserEntity = `query GetActiveUser($id: ID!, $acfilter: ModelActionComponentFilterInput, $limit: Int) {
  getUser(id: $id) {
    id
    authUsername
    email
    phoneNumber
    firstName
    lastName
    currentClassRelease
    active
    status
    subscriber
    certificationDate
    admin
    address
    address2
    city
    state
    zip
    lastLogin
  }

  listPages(limit: $limit) {
    items {
      id
      pageTitle
      linkPath
      menuSection
      linkText
      linkIcon
      introText
      pageContent
      videoLink
      videoLabel
      buttonLink
      buttonLabel
      sortBy
      includeNavigationMenu
      includeFooter
      adminOnly
      owner
    }
    nextToken
  }

  listActionComponents(filter: $acfilter, limit: $limit) {
    items {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    nextToken
  }
}
`
