import React from 'react'

import { Page } from '../clean/adapters/presenters/react/components/molecules/Page'
// import LinkIcon from '@material-ui/icons/Link';

const BrandUsagePage = ({ pageContent }) => {
  return <Page.WithIntro pageContent={pageContent}></Page.WithIntro>
}

export default BrandUsagePage
