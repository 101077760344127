import GraphQLAdapter from './GraphQLAdapter'
import { listClassSongs, getClassSong } from '../../../graphql/queries'
import {
  createClassSong,
  updateClassSong,
  deleteClassSong,
} from '../../../graphql/mutations'
// import { createClassSong, updateClassSongWithoutSongs } from '../../../graphql/classSongMutations'
// import { listClassSongsWithComponent } from '../../../graphql/classSongQueries'

import { ClassSongSeedData } from './JSONSeedData'
import {
  CURATED_SONG_ENTITY_NUMBER_LIST,
  CURATED_SONG_ENTITY_STRING_LIST,
} from '../../entities/CuratedSongEntity'

const _queryParams = { limit: 999999 }

export default class CuratedSongGraphQLAdapter extends GraphQLAdapter {
  constructor() {
    super()
    this._adapter = new GraphQLAdapter()
  }

  async createEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // console.info('CuratedSongGraphQLAdapter: createEntity  entity= ', entity)
    const response = await this._adapter
      .mutation(createClassSong, { input: entity })
      .catch(e => {
        this.logDBError(e)
      })
    // console.info('createEntity = ',response)
    if (response) {
      return response.data.createClassSong
    } else {
      return []
    }
  }

  async fetchEntity(id) {
    const response = await this._adapter
      .query(getClassSong, { id: id })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.log('CuratedSongGraphQLAdapter: fetchEntity: response=', response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.getClassSong
    } else {
      return isError
    }
  }

  async updateEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // console.info('CuratedSongGraphQLAdapter: updateEntity  entity= ', entity)
    const response = await this._adapter
      .mutation(updateClassSong, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('CuratedSongGraphQLAdapter: updateEntity  response= ',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.updateClassSong
    } else {
      return isError
    }
  }

  // deleteEntity
  async deleteEntity(id) {
    // console.info('CuratedSongGraphQLAdapter: deleteEntity  id= ', id)
    const response = await this._adapter
      .mutation(deleteClassSong, { input: { id: id } })
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.deleteClassSong === null) {
        return null
      }
      return response.data.deleteClassSong
    } else {
      return isError
    }
  }

  async fetchAll(queryParams) {
    if (queryParams === undefined) {
      queryParams = _queryParams
    } else {
      queryParams = { ..._queryParams, ...queryParams }
    }
    const response = await this._adapter
      .query(listClassSongs, queryParams)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listActionComponents.items.length === 0) {
        this._seedData()
      }
      return response.data.listClassSongs.items
    } else {
      return isError
    }
  }

  async fetchClassSongsForLevel(level) {
    const response = await this._adapter
      .query(listClassSongs, { filter: { layoutLevel: { le: level } } })
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.listClassSongs.items
    } else {
      return isError
    }
  }

  mapEntityToAdapter(entity) {
    if (entity.id === '_new_') {
      entity.id = null
    }
    if (entity.classSongActionComponentId) {
      entity.actionComponent = { id: entity.classSongActionComponentId }
    }
    if (
      typeof entity.actionComponent !== 'undefined' &&
      entity.actionComponent !== null
    ) {
      entity.classSongActionComponentId = entity.actionComponent.id
    }

    // INT values should not be empty string
    CURATED_SONG_ENTITY_NUMBER_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = 0
      }
    })
    // STRING values should be null instead of ''
    CURATED_SONG_ENTITY_STRING_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = null
      }
    })

    delete entity.actionComponent
    delete entity.curatedClass

    return entity
  }

  _seedData() {
    ClassSongSeedData.map(action => {
      this.createEntity(action)
      return null
    })
  }
}
