import PageGraphQLAdapter from '../adapters/drivers/PageGraphQLAdapter'
import PageRepository from '../adapters/repositories/PageRepository'
import PageService from './PageService'
import ClassDecorator from './ClassDecorator'

class AppPageService {
  constructor() {
    this._initializeAppService()
    return this._pageService
  }

  _initializeAppService() {
    this._adapter = new PageGraphQLAdapter()
    this._pageRepository = new PageRepository(this._adapter)
    this._pageService = new PageService(this._pageRepository, this._adapter)
  }
}
export default ClassDecorator.makeSingleton(AppPageService)
