import React, { useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AppCuratedSongService from '../../../../../../services/AppCuratedSongService'
import CURATED_CLASS_TYPE from '../../../../../../entities/CuratedClassEntity'
import PageLoader from '../../../components/molecules/PageLoader'
import SimpleSelect from '../../../components/molecules/SimpleSelect'
import { Page } from '../../../components/molecules/Page'
import { RPMRange } from '../../../components/atoms/RPMRange'

const CuratedSongEdit = ({ activeUser, actionComponents, match, history }) => {
  const { songID, classID } = match.params
  const [curatedSong, setCuratedSong] = React.useState(null)

  const curatedSongService = new AppCuratedSongService()

  useEffect(() => {
    if (songID !== '_new_') {
      ;(async songID => {
        const data = await curatedSongService.fetchCuratedSong(songID)
        // console.log('CuratedSongEdit: data',data)
        setCuratedSong(data)
      })(songID)
    } else {
      curatedSongService.clear()
      const newPage = curatedSongService.addCuratedSongEntity({
        id: '_new_',
        curatedClass: { id: classID },
        type: CURATED_CLASS_TYPE,
        actionComponent: { id: '_new_' },
      })
      setCuratedSong(newPage)
    }
    // console.log('CuratedSongEdit: data',curatedSong)
  }, [songID, classID, curatedSongService])

  const handleChange = name => event => {
    let newValue = Object.assign(
      Object.create(Object.getPrototypeOf(curatedSong)),
      curatedSong,
    ) // update value while preserving class object
    newValue[name] = event.target.value
    if (name === 'classSongActionComponentId') {
      // console.log(song.actionComponent)
      const component = actionComponents.filter(
        component => component.id === event.target.value,
      )[0]
      newValue['actionComponent'] = component
      if (
        newValue['coaching_notes_simple'] === undefined ||
        newValue['coaching_notes_simple'] === ''
      ) {
        newValue['coaching_notes_simple'] = component.simpleCoaching
      }
    }
    // console.log('handleChange: after', newValue)
    setCuratedSong(newValue)
  }

  const saveHandler = async updated => {
    if (updated.id !== '_new_') {
      await curatedSongService.updateCuratedSong(updated)
    } else {
      updated.id = null
      await curatedSongService.createCuratedSong(updated)
    }
    history.goBack()
    // history.push('/admin/pages/list')
  }

  const styles = useStyles()
  // console.log('curatedSong ', { curatedSong })
  if (curatedSong !== null) {
    const pageContent = {
      pageTitle: 'Edit Song:',
      introText: '',
    }

    return (
      <Page.WithTitle pageContent={pageContent}>
        {curatedSong.length !== 0 ? (
          <Card key={curatedSong.id} className={styles.card}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-song"
                    label="Song Title"
                    value={curatedSong.song}
                    onChange={handleChange('song')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-artist"
                    label="Artist"
                    value={curatedSong.artist}
                    onChange={handleChange('artist')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SimpleSelect
                    fullWidth
                    label="Action Component"
                    options={actionComponents}
                    optionValue="id"
                    optionLabel="name"
                    selectedValue={curatedSong.classSongActionComponentId}
                    variant="outlined"
                    onChangeHandler={handleChange('classSongActionComponentId')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  RPM:{' '}
                  {RPMRange(
                    curatedSong.actionComponent.rpm_low,
                    curatedSong.actionComponent.rpm_high,
                  )}
                  , PR: {curatedSong.actionComponent.pr_range}, Release:{' '}
                  {curatedSong.actionComponent.level}, <br />
                  {curatedSong.actionComponent.coachingNotes}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  key={curatedSong.classSongActionComponentId + 'simple'}
                >
                  <TextField
                    fullWidth
                    multiline
                    rowsMax="4"
                    required
                    id="outlined-required-coaching_notes_simple"
                    label="coaching_notes_simple"
                    value={curatedSong.coaching_notes_simple}
                    onChange={handleChange('coaching_notes_simple')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  key={curatedSong.classSongActionComponentId + 'full'}
                >
                  <TextField
                    fullWidth
                    multiline
                    rowsMax="4"
                    required
                    id="outlined-required-coaching_notes"
                    label="coaching_notes"
                    value={curatedSong.coaching_notes}
                    onChange={handleChange('coaching_notes')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-rpm_a"
                    label="RPM A"
                    value={curatedSong.rpm_a}
                    onChange={handleChange('rpm_a')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-rpm_b"
                    label="RPM B"
                    value={curatedSong.rpm_b}
                    onChange={handleChange('rpm_b')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-pr_a"
                    label="pr_a"
                    value={curatedSong.pr_a}
                    onChange={handleChange('pr_a')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-pr_b"
                    label="pr_b"
                    value={curatedSong.pr_b}
                    onChange={handleChange('pr_b')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    multiline
                    rowsMax="4"
                    required
                    id="outlined-required-videoURL"
                    label="video URL"
                    value={curatedSong.videoURL}
                    onChange={handleChange('videoURL')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-sortOrder"
                    label="Song Order"
                    value={curatedSong.sortOrder}
                    onChange={handleChange('sortOrder')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                    helperText="This is the sorting order of songs for this class layout"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3}>
								<SimpleSelect fullWidth label="Active" options={[{value:true,label:"Yes"},{value:false, label:"No"}]} selectedValue={song.active} variant="outlined" onChangeHandler={handleChange('active')} />
							</Grid> */}
              </Grid>
            </CardContent>

            <CardActions>
              <NavLink to={`/curated/class/${classID}/songs`}>
                <Button size="small">Cancel</Button>
              </NavLink>
              <Button
                onClick={() => {
                  saveHandler(curatedSong)
                }}
                size="small"
                color="primary"
              >
                Save
              </Button>
            </CardActions>
          </Card>
        ) : (
          <div>Song Not Found</div>
        )}
      </Page.WithTitle>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(CuratedSongEdit)

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    minWidth: 275,
    marginBottom: 5,
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
