import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PageLoader from '../../components/molecules/PageLoader'
import SimpleSelect from '../../components/molecules/SimpleSelect'
// import UserRepository from '../../../../repositories/UserRepository'
// import UsersGraphQLAdapter from '../../../../drivers/UsersGraphQLAdapter'
import { Page } from '../../components/molecules/Page'
import { Button } from '../../components/atoms/Button'
import AppUserService from '../../../../../services/AppUserService'
import { LinkButton } from '../../components/atoms/LinkButton'
import { Spacing } from '../../components/atoms/Spacing'

const UserEdit = props => {
  const userId = props.match.params.userId
  const userService = new AppUserService()
  // const userAdapter = new UsersGraphQLAdapter() // GraphQL Adapter
  // const repository = new UserRepository(userAdapter)
  const [user, setUser] = React.useState(null)

  useEffect(() => {
    ;(async userId => {
      const data = await userService.fetchUser(userId)
      // const data = await repository.fetchEntity(userId)
      // console.log('UserEdit: data',data)
      setUser(data)
    })(userId)
  }, [userId, userService])

  const handleChange = name => event => {
    let newValue = Object.assign(
      Object.create(Object.getPrototypeOf(user)),
      user,
    ) // update value while preserving class object
    newValue[name] = event.target.value
    // console.log('handleChange: after',newValue)
    setUser(newValue)
  }

  const approveUser = level => {
    console.log('approveUser', level)
    let newValue = Object.assign(
      Object.create(Object.getPrototypeOf(user)),
      user,
    ) // update value while preserving class object
    if (level === 'Beta') {
      newValue['status'] = 4 // active
      newValue['currentClassRelease'] = 7
      newValue['subscriber'] = true
      newValue['active'] = true
    }
    if (level === 'Welcome') {
      newValue['status'] = 1 // welcome
      newValue['currentClassRelease'] = 0
      newValue['subscriber'] = true
      newValue['active'] = true
    }
    if (level === 'Started') {
      newValue['status'] = 2 // started
      newValue['currentClassRelease'] = 1
      newValue['subscriber'] = true
      newValue['active'] = true
    }
    if (level === 'Complete') {
      newValue['status'] = 3 // complete
      newValue['currentClassRelease'] = 2
      newValue['subscriber'] = true
      newValue['active'] = true
    }
    setUser(newValue)
  }

  const saveHandler = async updatedUser => {
    // console.log('saveHandler',updatedUser)
    // const userRepository = userRepositoryStore
    await userService.updateUser(updatedUser)
    // await repository.updateEntity(updatedUser)
    // console.log('AdminUsersPage - saveHandler: updated',updated)
    props.history.push('/admin/users/list')
  }

  const styles = useStyles()
  if (user !== null) {
    return (
      <Page.WithTitle pageContent={{ pageTitle: `User Edit: ${user.email}` }}>
        <Spacing p={1}>
          <Button onClick={() => approveUser('Beta')}>Set to Beta Group</Button>
          <Button onClick={() => approveUser('Welcome')}>Set to Welcome</Button>
          <Button onClick={() => approveUser('Started')}>Set to Started</Button>
          <Button onClick={() => approveUser('Complete')}>
            Set to Cert Complete
          </Button>
        </Spacing>
        <Card key={user.id} className={styles.card}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-firstName"
                  label="First"
                  value={user.firstName}
                  onChange={handleChange('firstName')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-lastName"
                  label="Last"
                  value={user.lastName}
                  onChange={handleChange('lastName')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-email"
                  label="Email"
                  value={user.email}
                  onChange={handleChange('email')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-address"
                  label="Address"
                  value={user.address}
                  onChange={handleChange('address')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-address2"
                  label="Address2"
                  value={user.address2}
                  onChange={handleChange('address2')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-city"
                  label="City"
                  value={user.city}
                  onChange={handleChange('city')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-state"
                  label="State"
                  value={user.state}
                  onChange={handleChange('state')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-zip"
                  label="Zip"
                  value={user.zip}
                  onChange={handleChange('zip')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-phoneNumber"
                  label="Phone"
                  value={user.phoneNumber}
                  onChange={handleChange('phoneNumber')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-certificationDate"
                  label="Certification Date"
                  value={user.certificationDate}
                  onChange={handleChange('certificationDate')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <SimpleSelect
                  fullWidth
                  label="currentlyInstructing"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  selectedValue={user.currentlyInstructing}
                  variant="outlined"
                  onChangeHandler={handleChange('currentlyInstructing')}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  id="outlined-required-instructingLocation"
                  label="Instructing Location"
                  value={user.instructingLocation}
                  onChange={handleChange('instructingLocation')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <SimpleSelect
                  input
                  label="Status"
                  options={user.statusOptions()}
                  optionValue="key"
                  optionLabel="value"
                  selectedValue={user.status}
                  variant="outlined"
                  onChangeHandler={handleChange('status')}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <SimpleSelect
                  fullWidth
                  label="Subscriber"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  selectedValue={user.subscriber}
                  variant="outlined"
                  onChangeHandler={handleChange('subscriber')}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <SimpleSelect
                  fullWidth
                  label="Active"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  selectedValue={user.active}
                  variant="outlined"
                  onChangeHandler={handleChange('active')}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <SimpleSelect
                  fullWidth
                  label="Agreed to Terms"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  selectedValue={user.agreeTerms}
                  variant="outlined"
                  onChangeHandler={handleChange('agreeTerms')}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-currentClassRelease"
                  label="Class Release"
                  value={user.currentClassRelease}
                  onChange={handleChange('currentClassRelease')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-lastReleaseDate"
                  label="Last Release Date"
                  value={user.lastReleaseDate}
                  onChange={handleChange('lastReleaseDate')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={2}>
                <SimpleSelect
                  fullWidth
                  label="Admin"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  selectedValue={user.admin}
                  variant="outlined"
                  onChangeHandler={handleChange('admin')}
                />
              </Grid>

              {/* </form> */}
            </Grid>
          </CardContent>
          <CardActions>
            <LinkButton to="/admin/users/list">Cancel</LinkButton>
            <Button
              onClick={() => {
                saveHandler(user)
              }}
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </Page.WithTitle>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(UserEdit)

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    minWidth: 275,
    marginBottom: 5,
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
