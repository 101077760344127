import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody } from '@material-ui/core'

import User from '../../../../../entities/User'
import { ClassRow, ClassRowHeader } from './ClassRow'

export const ClassTable = ({
  activeUser,
  classRows,
  classType,
  sharedFreestyle,
  styles,
}) => {
  return (
    <Table className={styles.table} size="small" stickyHeader={true}>
      <ClassRowHeader {...{ activeUser, classType, sharedFreestyle }} />
      <TableBody>
        {classRows.map(classRow => (
          <ClassRow
            key={classRow.id}
            {...{ classRow, classType, sharedFreestyle, activeUser }}
          />
        ))}
      </TableBody>
    </Table>
  )
}
ClassTable.propTypes = {
  activeUser: PropTypes.instanceOf(User),
  classRows: PropTypes.array,
  classType: PropTypes.string,
  sharedFreestyle: PropTypes.bool,
  styles: PropTypes.func,
}
