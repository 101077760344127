import Entity from './Entity'

export default class PageEntity extends Entity {
  id
  pageTitle
  linkPath //## should match Router path (if contains ':', do not render in menu)
  menuSection //## Admin, (none)
  linkText //## User Admin, Curated Class Layouts
  linkIcon
  introText
  pageContent
  videoLink
  videoLabel
  buttonLink
  buttonLabel
  sortBy //## order to display under specific menuSection
  includeNavigationMenu
  includeFooter
  adminOnly //## only render for admin users

  constructor(initialValues = {}) {
    let _defaults = { sortBy: 1 }
    super({ ..._defaults, ...initialValues })

    PAGE_ENTITY_STRING_LIST.forEach(field => {
      if (this[field] === null) {
        this[field] = ''
      }
    })
    PAGE_ENTITY_NUMBER_LIST.forEach(field => {
      if (this[field] === null) {
        this[field] = 0
      }
    })
  }

  sections() {
    return [
      { sortBy: 0, value: '' },
      { key: 1, value: 'Admin' },
    ]
  }
}

export const PAGE_ENTITY_NUMBER_LIST = ['sortBy']
export const PAGE_ENTITY_STRING_LIST = [
  'pageTitle',
  'linkPath',
  'menuSection',
  'linkIcon',
  'introText',
  'pageContent',
  'videoLink',
  'videoLabel',
  'buttonLink',
  'buttonLabel',
]
