export const createActionComponent = `mutation CreateActionComponent($input: CreateActionComponentInput!) {
  createActionComponent(input: $input) {
    id
    name
    simpleCoaching
    sortBy
    folder
    rpm_low
    rpm_high
    pr_range
    coachingNotes
    level
    videoURL
    createdByUser {
      id
      authUsername
      active
    }
    active
    owner
  }
}
`;

export const updateActionComponent = `mutation UpdateActionComponent($input: UpdateActionComponentInput!) {
  updateActionComponent(input: $input) {
    id
    name
    simpleCoaching
    sortBy
    folder
    rpm_low
    rpm_high
    pr_range
    coachingNotes
    level
    videoURL
    createdByUser {
      id
      authUsername
      admin
    }
    active
    owner
  }
}
`;
