import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import AppFreestyleClassService from '../../../../../../services/AppFreestyleClassService'
import { FetchAllFreestyleClasssByLevel } from '../../../hooks/useFreestyleClassService'
import { Page } from '../../../components/molecules/Page'
import PageLoader from '../../../components/molecules/PageLoader'
import User from '../../../../../../entities/User'
import { useDataRefresh } from '../../../hooks/useDataRefresh'
import { FilterBar } from '../../../components/atoms/FilterBar'
import { ClassTable } from '../ClassTable'
import { Spacing } from '../../../components/atoms/Spacing'
import { BlankClassLayoutButtonLink } from '../../../components/molecules/BlankClassLayoutButtonLink'
import { UserIsApproved } from '../../../components/molecules/UserIsApproved'
import { CreatedByName } from '../../../components/functions/CreatedByName'

// const classType = 'freestyle'

const GuidedFreestylePage = ({ activeUser, pageContent }) => {
  const [isLoading, setIsLoading] = useState(true)
  const defaultSortBy = ['layoutLevel', 'title']
  const [sortBy] = useState(defaultSortBy)
  const [classRows, setClassRows] = useState()
  const [refresh] = useDataRefresh(0) // setRefresh
  const classType = 'freestyle'
  const sharedFreestyle = true
  const maxLevel = activeUser.currentClassRelease
    ? activeUser.currentClassRelease
    : 0

  const freestyleClassService = new AppFreestyleClassService()
  const fetchedData = FetchAllFreestyleClasssByLevel(
    maxLevel,
    sortBy,
    freestyleClassService,
    refresh,
  )
  const onFilterChange = event => {
    const filter = event.target.value
    if (fetchedData.length) {
      const filteredData = ClassRowFilter(fetchedData, filter)
      setClassRows(filteredData)
    }
  }

  useEffect(() => {
    if (isLoading && fetchedData) {
      setClassRows(fetchedData)
      setIsLoading(false)
    }
  }, [isLoading, fetchedData, setIsLoading, refresh])

  if (isLoading) {
    return <PageLoader />
  }

  if (classRows !== undefined && classRows !== null) {
    return (
      <Page.WithIntro pageContent={pageContent}>
        <Spacing p={1}>
          <BlankClassLayoutButtonLink />
        </Spacing>
        <UserIsApproved activeUser={activeUser}>
          <FilterBar
            onChange={onFilterChange}
            title="Filter Shared Freestyle Layouts"
          />
          {classRows.length ? (
            <ClassTable
              {...{ activeUser, classRows, classType, sharedFreestyle, styles }}
            />
          ) : (
            <div>There are no classes found</div>
          )}
        </UserIsApproved>
      </Page.WithIntro>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(GuidedFreestylePage)

GuidedFreestylePage.propTypes = {
  activeUser: PropTypes.instanceOf(User),
}

const ClassRowFilter = (data, filter) => {
  const filtered = data.filter(row => {
    const haystack =
      row.title + row.owner + row.description + CreatedByName(row) + ''
    const isMatch = haystack
      .toLocaleLowerCase()
      .includes(filter.toLocaleLowerCase())
    return isMatch
  })
  return filtered
}

const styles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(6),
    // marginTop: 10,
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  headline: {
    padding: 6,
  },
  table: {
    minWidth: 650,
  },
}))
