import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import PageLoader from '../../components/molecules/PageLoader'
import AppUserService from '../../../../../services/AppUserService'
import { FetchAllUsers } from '../../hooks/useUserService'
import User from '../../../../../entities/User'
import { useDataRefresh } from '../../hooks/useDataRefresh'
import { FilterBar } from '../../components/atoms/FilterBar'
import { Page } from '../../components/molecules/Page'
import { UserTable } from './UserTable'

const AdminUsersPage = ({ activeUser, pageContent, ...props }) => {
  const [isLoading, setIsLoading] = useState(true)
  const defaultSortBy = ['lastName', 'firstName']
  const [sortBy] = useState(defaultSortBy)
  const [userRows, setUserRows] = useState()
  const [refresh] = useDataRefresh(0) // setRefresh

  const userService = new AppUserService()
  const fetchedData = FetchAllUsers(sortBy, userService, refresh)

  const onFilterChange = event => {
    const filter = event.target.value
    if (fetchedData.length) {
      const filteredData = UserRowFilter(fetchedData, filter)
      setUserRows(filteredData)
    }
  }

  useEffect(() => {
    if (isLoading && fetchedData) {
      setUserRows(fetchedData)
      setIsLoading(false)
    }
  }, [isLoading, fetchedData, setIsLoading])

  if (isLoading) {
    return <PageLoader />
  }

  if (userRows !== undefined && userRows !== null) {
    return (
      <Page.WithIntro pageContent={pageContent}>
        <FilterBar onChange={onFilterChange} title="Filter Users" />
        {userRows.length ? (
          <UserTable {...{ users: userRows, activeUser, styles }} />
        ) : null}
      </Page.WithIntro>
    )
  } else {
    return <div>No Users Found</div>
  }
}

export default AdminUsersPage
AdminUsersPage.propTypes = {
  activeUser: PropTypes.instanceOf(User),
  pageContent: PropTypes.object,
}

const UserRowFilter = (data, filter) => {
  const filtered = data.filter(row => {
    const haystack =
      row.firstName + row.lastName + row.email + row.phone + row.id + ''
    const isMatch = haystack
      .toLocaleLowerCase()
      .includes(filter.toLocaleLowerCase())
    return isMatch
  })
  return filtered
}

const styles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  headline: {
    padding: 6,
  },
  table: {
    minWidth: 400,
  },
}))
