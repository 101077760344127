// eslint-disable
export const getActionComponentWithUser = `query GetActionComponentWithUser($id: ID!) {
  getActionComponent(id: $id) {
    id
    name
    simpleCoaching
    sortBy
    folder
    rpm_low
    rpm_high
    pr_range
    coachingNotes
    level
    videoURL
    createdByUser {
      id
			authUsername
    }
    active
    owner
  }
}
`
export const listActionComponents = `query ListActionComponents(
  $filter: ModelActionComponentFilterInput
  $limit: Int
  $nextToken: String
) {
  listActionComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    nextToken
  }
}
`
