import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function AlertConfirmation({
  trigger = true, // render trigger button when true, else set open
  actionButtonLabel = 'Confirm',
  title = 'Are you sure?',
  content = 'Click to confirm.',
  cancelLabel = 'Cancel',
  onCancel = () => {},
  confirmLabel = 'Confirm',
  onConfirm = () => {},
}) {
  const [open, setOpen] = React.useState(!trigger)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleConfirm = () => {
    onConfirm()
    setOpen(false)
  }
  const handleClose = (event, data, ...args) => {
    setOpen(false)
    onCancel()
  }

  return (
    <div>
      {trigger ? (
        <Button color="primary" onClick={handleClickOpen}>
          {actionButtonLabel}
        </Button>
      ) : null}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelLabel}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
AlertConfirmation.propTypes = {
  trigger: PropTypes.bool,
  actionButtonLabel: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
}
