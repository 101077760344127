import React, { useState, useEffect } from 'react'
import ClassSongs from '../songs/ClassSongs'

import AppCuratedClassService from '../../../../../../services/AppCuratedClassService'
import PageLoader from '../../../components/molecules/PageLoader'
import { Page } from '../../../components/molecules/Page'
import { Spacing } from '../../../components/atoms/Spacing'

const CuratedLayoutPage = ({ activeUser, match, history }) => {
  const classID = match.params.classID
  const [sortBy] = useState(['sortOrder'])
  const [classLayout, setLayout] = useState(null)

  const curatedClassService = new AppCuratedClassService()

  useEffect(() => {
    ;(async classID => {
      curatedClassService.clear()
      const data = await curatedClassService.fetchCuratedClassWithSongs(
        classID,
        sortBy,
      )
      if (data.createdByUser === null) {
        data.createdByUser = { id: activeUser.id } // assume ownership
      }
      data.shared = false // assume shared @TODO: this needs to be optional for curated
      // console.log('fetchCuratedClassWithSongs', data, classID)
      if (data === [] || 'errors' in data) {
        setLayout({})
      } else {
        setLayout(data)
      }
    })(classID)
  }, [classID, sortBy, curatedClassService, activeUser.id])

  if (classLayout === null) {
    return <PageLoader />
  }
  if (classLayout.id === classID) {
    return (
      <Page.WithTitle
        pageContent={{ pageTitle: `Curated Class - ${classLayout.title}` }}
      >
        <Spacing p={1}>
          USE OF TOUCH SCREENS IN CLASS WHEN INSTRUCTING Whether in Detailed or
          Simple View when teaching a class and you are using your iPhone or
          iPad to follow Class Layout, scroll/swipe then pause and repeat to
          unlock the scroll/swipe ability.
        </Spacing>
        <ClassSongs {...{ classLayout, activeUser }} />
      </Page.WithTitle>
    )
  } else {
    return (
      <Page.WithTitle pageContent={{ pageTitle: 'Curated Class' }}>
        Class not found
      </Page.WithTitle>
    )
  }
}

export default CuratedLayoutPage
