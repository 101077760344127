import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

export const ExternalLinkButton = ({
  href,
  variant = 'outlined',
  color = 'link',
  size = 'small',
  children,
  target = '_blank',
  onClick,
}) => {
  return (
    <Button {...{ href, target, variant, size, onClick }} rel="noreferrer">
      {children}
    </Button>
  )
}

ExternalLinkButton.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  variant: PropTypes.string,
  color: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
}
