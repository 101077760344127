import GraphQLAdapter from './GraphQLAdapter'
import { GraphQLQueryParams } from './GraphQLAdapter'
import {
  listActionComponents,
  getActionComponentWithUser,
} from '../../../graphql/actionComponentQueries'
import {
  createActionComponent,
  updateActionComponent,
} from '../../../graphql/actionComponentMutations'

import { ActionComponentSeedData } from './JSONSeedData'

export default class ActionComponentGraphQLAdapter extends GraphQLAdapter {
  constructor() {
    super()
    this._adapter = new GraphQLAdapter()
  }

  async createEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // if (entity.createdByUser !== null && entity.createdByUser !== undefined) {
    //   entity.actionComponentCreatedByUserId = entity.createdByUser.id
    // }
    // delete entity.createdByUser
    // console.info('ActionComponentGraphQLAdapter: createEntity  entity= ',entity)
    const response = await this._adapter
      .mutation(createActionComponent, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('createEntity = ',response)
    if (response) {
      return response.data.createActionComponent
    } else {
      return []
    }
  }

  async fetchEntity(id) {
    const response = await this._adapter
      .query(getActionComponentWithUser, { id: id })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.log('ActionComponentGraphQLAdapter: fetchEntity: response=',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.getActionComponent
    } else {
      return isError
    }
  }

  async updateEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // entity.actionComponentCreatedByUserId = entity.createdByUser.id // Required by resolvers
    // delete entity.createdByUser
    // console.info('ActionComponentGraphQLAdapter: updateEntity  entity= ',entity)
    const response = await this._adapter
      .mutation(updateActionComponent, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('UserGraphQLAdapter: updateEntity  response= ',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.updateActionComponent
    } else {
      return isError
    }
  }

  // deleteEntity

  async fetchAll(limit = 999999) {
    let queryOptions = GraphQLQueryParams
    queryOptions.limit = limit
    const response = await this._adapter
      .query(listActionComponents, queryOptions)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listActionComponents.items.length === 0) {
        this._seedData()
      }
      return response.data.listActionComponents.items
    } else {
      return isError
    }
  }

  async FetchAllActionComponentsByLevel(maxLevel, limit = 999999) {
    let queryOptions = GraphQLQueryParams
    queryOptions.limit = limit
    queryOptions.filter = { level: { le: maxLevel } }
    const response = await this._adapter
      .query(listActionComponents, queryOptions)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.listActionComponents.items
    } else {
      return isError
    }
  }

  async fetchAndSeed() {
    let queryOptions = GraphQLQueryParams
    queryOptions.limit = 999999
    const response = await this._adapter
      .query(listActionComponents, queryOptions)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listActionComponents.items.length === 0) {
        this._seedData()
      }
      return response.data.listActionComponents.items
    } else {
      return isError
    }
  }

  mapEntityToAdapter(entity) {
    if (entity.id === '_new_') {
      entity.id = null
    }
    if (
      typeof entity.createdByUser !== 'undefined' &&
      entity.createdByUser !== null
    ) {
      entity.actionComponentCreatedByUserId = entity.createdByUser.id
    }
    // INT values should not be empty string
    const intValues = ['sortBy', 'folder', 'rpm_low', 'rpm_high']
    intValues.forEach(field => {
      if (entity[field] === '') {
        entity[field] = 0
      }
    })

    delete entity.createdByUser

    const intFields = ['sortBy', 'folder', 'rpm_low', 'rpm_high']
    entity = this.resolveIntegers(entity, intFields)

    const strFields = [
      'simpleCoaching',
      'videoURL',
      'pr_range',
      'coachingNotes',
    ]
    entity = this.resolveNullableStrings(entity, strFields)

    return entity
  }

  _seedData() {
    ActionComponentSeedData.map(action => {
      this.createEntity(action)
      return []
    })
  }
}
