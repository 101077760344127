import _ from 'lodash'

export default class Repository {
  constructor() {
    this._data = []
  }

  clear() {
    // console.log('clear() called')
    this._data = []
  }

  async createEntity(entity) {
    let result = await this._adapter.createEntity(entity)
    return this.processEntityResponse(result, result.id)
  }

  async fetchEntity(id) {
    const result = await this._adapter.fetchEntity(id)
    // console.log('Repository: fetchEntity',result)
    return result !== null ? this.processEntityResponse(result, id) : []
  }

  async updateEntity(entity) {
    const previousEntity = this.find({ id: entity.id })
    // console.log('Repository: previousEntity',previousEntity)
    const updated = await this._adapter.updateEntity(entity)
    if (updated !== []) {
      // console.log('Repository: updateEntity updated=',updated)
      if (previousEntity.length === 1) {
        // console.log('Repository: updateEntity - removing previous entity',previousEntity)
        this.removeRange(previousEntity)
      }
      // console.log('Repository: updateEntity - adding updated entity via mapper',updated)
      return this.processEntityResponse(updated, entity.id)
      // return this.fetchEntity(entity.id)
    }
    return []
  }

  async deleteEntity(id) {
    let result = await this._adapter.deleteEntity(id)
    // console.log('Repository: deleteEntity result', result, id)
    return result
  }

  async fetchAll(sortBy) {
    let result = await this._adapter.fetchAll()
    return this.processListResponse(result, sortBy)
  }

  add(entity) {
    this._data.push(entity)
  }

  remove(entity) {
    this._data = _.reject(this._data, data => {
      return _.isEqual(data, entity)
    })
  }

  addRange(entities) {
    entities.map(entity => this.add(entity))
  }

  removeRange(entities) {
    entities.map(entity => this.remove(entity)) // _.forEach(entities, entity => this.remove(entity))
  }

  get(orderBy) {
    if (orderBy === undefined) {
      return this._data
    } else {
      return _.orderBy(this._data, orderBy)
    }
  }

  _orderBy(data, orderBy) {
    return _.orderBy(data, orderBy)
  }

  find(query) {
    return this._searchRepository(query)
  }

  processListResponse(data, sortBy) {
    // console.log('Repository: processListResponse =', data, sortBy)
    if (data === null || data.hasOwnProperty('errors')) {
      return data
    }
    this._mapFetchedDataToEntity(data)
    return this.get(sortBy)
  }

  processEntityResponse(data, id) {
    // console.log('Repository: processEntityResponse =', data, id)
    if (data.hasOwnProperty('errors')) {
      return data
    }
    this._mapFetchedDataToEntity(data)
    // console.log('Repository: _mapFetchedDataToEntity', this.find({ id: id }))
    return this.find({ id: id })[0]
  }

  _searchRepository(query = {}) {
    const result = _.filter(this._data, query)
    if (result !== undefined) {
      return result
    } else {
      return []
    }
  }
}
