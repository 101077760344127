import UsersGraphQLAdapter from '../../../../drivers/UsersGraphQLAdapter'
import UserRepository from '../../../../repositories/UserRepository'
import UTCTimeNow from '../atoms/UTCTimeNow'

const InitUserData = async login => {
  const usersGQLAdapter = new UsersGraphQLAdapter()
  const userRepository = new UserRepository(usersGQLAdapter)
  const userSub = login.attributes.sub
  let user = await userRepository.fetchActiveUser(userSub)
  // console.log('InitUserData: ',user)
  if (user !== null && user.hasOwnProperty('errors')) {
    return user
  }
  if (user == null) {
    const newUser = {
      id: userSub,
      authUsername: login.username,
      email: login.attributes.email,
      phoneNumber: login.attributes.phone_number,
      owner: login.username,
      admin: false,
      active: false,
      subscriber: false,
      status: 0,
      currentClassRelease: 0,
      lastLogin: UTCTimeNow(),
    }
    user = await userRepository.createEntity(newUser)
  } else {
    // user = await userRepository.userLoggedIn(user)
    // console.log('userLoggedIn',user)
  }
  return user
}

export default InitUserData
