import React from 'react'
// import { Link as RouterLink } from 'react-router-dom';
import { Typography } from '@material-ui/core'
import { Page } from '../clean/adapters/presenters/react/components/molecules/Page'

const ToolsPage = ({ pageContent }) => {
  return (
    <Page.WithIntro pageContent={pageContent}>
      <Typography variant="body1">
        <a
          href="http://sortyourmusic.playlistmachinery.com/"
          target="_spotify"
          rel="noreferrer"
        >
          Sort Your Music
        </a>
        <br />
      </Typography>
      <Typography variant="body1">
        <a
          href="https://open.spotify.com/user/1293700361/playlist/2z2B5uRvE7My08CyS9gTny?si=S-o3jvBaSPi-n3pTq_l_FA"
          target="_spotify"
          rel="noreferrer"
        >
          BeatBoss 40's RPM Playlist
        </a>
        <br />
        <a
          href="https://open.spotify.com/user/1293700361/playlist/0nIyYKKZJCGsORcGkJk8Jm?si=nVzIm2t-TEOqMIKKysl27Q"
          target="_spotify"
          rel="noreferrer"
        >
          BeatBoss 50's RPM Playlist
        </a>
        <br />
        <a
          href="https://open.spotify.com/user/1293700361/playlist/4CSlg5LmgzeOEN5lToaJvg?si=hLG7eT-pQielUdlpF33xAA"
          target="_spotify"
          rel="noreferrer"
        >
          BeatBoss 60's RPM Playlist
        </a>
        <br />
        <a
          href="https://open.spotify.com/user/1293700361/playlist/2xjsKJln9bLnsnGhGaEvQA?si=hS9xtXrzQ5e2t85rTw_Kgw"
          target="_spotify"
          rel="noreferrer"
        >
          BeatBoss 70's RPM Playlist
        </a>
        <br />
        <a
          href="https://open.spotify.com/user/1293700361/playlist/6JtuGEmRuZAyBDIDTTpdDu?si=qOYScDzzTyCV1tgTVjXTig"
          target="_spotify"
          rel="noreferrer"
        >
          BeatBoss 80's RPM Playlist
        </a>
        <br />
        <a
          href="https://open.spotify.com/user/1293700361/playlist/2ZuDNxYrUtn0avzJ5m52zJ?si=AjqdiIukT8KwgqEjk-Misg"
          target="_spotify"
          rel="noreferrer"
        >
          BeatBoss Warmup 70-110 RPM Playlist
        </a>
        <br />
      </Typography>
    </Page.WithIntro>
  )
}

export default ToolsPage
