import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent } from '@material-ui/core'

import { Exception } from './Exception'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    // we can log the error to a reporting service or whatever else
    // ErrorService.log(error, errorInfo)

    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  render() {
    const { error } = this.state
    // console.log({ error })
    if (error) {
      return (
        <Card>
          <CardContent>
            <h1>
              {error instanceof Exception
                ? error.message
                : 'Something went wrong!'}
            </h1>
          </CardContent>
        </Card>
      )
    }

    return this.props.children || null
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export class AppErrorBoundary extends ErrorBoundary {}
