export const createCuratedClass = `mutation CreateCuratedClass($input: CreateCuratedClassInput!) {
  createCuratedClass(input: $input) {
    id
    group
    title
    description
    active
    type
    layoutLevel
    classPDFLink
    playlistURL
    createdByUser {
      id
      authUsername
      active
    }
    owner
  }
}
`

export const updateCuratedClassWithoutSongs = `mutation UpdateCuratedClassWithoutSongs($input: UpdateCuratedClassInput!) {
  updateCuratedClass(input: $input) {
    id
    group
    title
    description
    active
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      active
    }
  }
}
`
