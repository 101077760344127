export default class PageService {
  constructor(repository, adapter) {
    this._repository = repository
    this._adapter = adapter
  }

  createPage(entity) {
    return this._repository.createEntity(entity)
  }

  fetchPage(id) {
    return this._repository.fetchEntity(id)
  }

  updatePage(entity) {
    return this._repository.updateEntity(entity)
  }

  deletePage(id) {
    return this._repository.deleteEntity(id)
  }

  fetchAllPages(sortBy) {
    return this._repository.fetchAll(sortBy)
  }

  addPageEntity(entity) {
    this._repository.addEntity(entity)
    return this._repository.get()[0]
  }

  sort(sortBy) {
    return this._repository.get(sortBy)
  }

  clear() {
    this._repository.clear()
  }
}
