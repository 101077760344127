import Entity from './Entity'
import ActionComponentEntity from './ActionComponentEntity'

export default class User extends Entity {
  id
  authUsername
  email
  phoneNumber
  firstName
  lastName
  currentClassRelease
  lastReleaseDate
  active
  status
  subscriber
  admin
  address
  address2
  city
  state
  zip
  agreeTerms
  currentlyInstructing
  instructingLocation
  notes
  certificationDate
  canChangeSongs
  canGuidedFreestyle
  lastLogin
  actionComponents
  actionComponentCreatedByUserId
  owner

  constructor(initialValues = {}) {
    let _defaults = {}
    if (initialValues.currentClassRelease === null) {
      initialValues.currentClassRelease = 0
    }
    super({ ..._defaults, ...initialValues })

    if (initialValues !== null) {
      const { actionComponents } = initialValues
      if (actionComponents !== undefined) {
        this.actionComponents = new ActionComponentEntity(actionComponents)
      }
    }

    USER_ENTITY_STRING_LIST.forEach(field => {
      if (this[field] === null) {
        this[field] = ''
      }
    })
    USER_ENTITY_NUMBER_LIST.forEach(field => {
      if (this[field] === null) {
        this[field] = 0
      }
    })
    USER_ENTITY_BOOLEAN_LIST.forEach(field => {
      if (this[field] === null) {
        this[field] = false
      }
    })
  }

  isAdminUser() {
    return this.admin === true
  }
  isActiveUser() {
    return this.active === true
  }

  isSubscriber() {
    return this.subscriber === true
  }

  statusOptions() {
    return [
      { key: 0, value: 'New Signup' },
      { key: 1, value: 'Welcome' },
      { key: 2, value: 'Started' },
      { key: 3, value: 'Complete' },
      { key: 4, value: 'Active' },
      { key: 5, value: 'Paused' },
    ]
  }

  statusLabel() {
    let statuses = this.statusOptions()
    let label = 'Waiting For Approval'
    if (this.status !== undefined) {
      let data = statuses.find(label => {
        return label.key === this.status
      })
      if (data !== undefined) {
        return data.value
      }
    }
    return label
  }
}

export const USER_NEW_SIGNUP = 0
export const USER_WELCOME = 1
export const USER_STARTED = 2
export const USER_COMPLETE = 3
export const USER_ACTIVE = 4
export const USER_PAUSED = 5

export const USER_ENTITY_NUMBER_LIST = [
  'sortBy',
  'status',
  'currentClassRelease',
]
export const USER_ENTITY_STRING_LIST = [
  'phoneNumber',
  'firstName',
  'lastName',
  'currentClassRelease',
  'lastReleaseDate',
  'address',
  'address2',
  'city',
  'state',
  'zip',
  'instructingLocation',
  'notes',
  'certificationDate',
]
export const USER_ENTITY_BOOLEAN_LIST = [
  'active',
  'subscriber',
  'admin',
  'agreeTerms',
  'currentlyInstructing',
]
