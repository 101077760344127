import FreestyleSongGraphQLAdapter from '../adapters/drivers/FreestyleSongGraphQLAdapter'
import FreestyleSongRepository from '../adapters/repositories/FreestyleSongRepository'
import FreestyleSongService from './FreestyleSongService'
import ClassDecorator from './ClassDecorator'

class AppFreestyleSongService {
  constructor() {
    this._initializeService()
    return this._freestyleClassService
  }

  _initializeService() {
    this._adapter = new FreestyleSongGraphQLAdapter()
    this._freestyleClassRepository = new FreestyleSongRepository(this._adapter)
    this._freestyleClassService = new FreestyleSongService(
      this._freestyleClassRepository,
      this._adapter,
    )
  }
}
export default ClassDecorator.makeSingleton(AppFreestyleSongService)
