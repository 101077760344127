import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined'
import User from '../../../../../entities/User'

export const MyAppBar = ({
  activeUser,
  handleSignOut,
  handleDrawerToggle,
  classes,
}) => {
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <NavLink to="/" style={{ color: 'white', textDecoration: 'none' }}>
              <Environment />
              BeatBossONline™ CE Instructor Portal
            </NavLink>
          </Typography>
          <RenderProfileIcon {...{ activeUser }} />
          <Button onClick={handleSignOut} color="inherit">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}

MyAppBar.propTypes = {
  handleSignOut: PropTypes.func,
  activeUser: PropTypes.instanceOf(User),
}

const Environment = () => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    return '** ' + process.env.REACT_APP_ENV + ' ** - '
  }
  return null
}

const RenderProfileIcon = ({ activeUser }) => {
  return (
    <NavLink to="/profile">
      {activeUser.isAdminUser() ? (
        <SupervisorAccountIcon htmlColor="white" />
      ) : (
        <PersonOutlineOutlinedIcon htmlColor="white" />
      )}
    </NavLink>
  )
}

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//   },
//   appBar: {
//     backgroundColor: '#000000',
//     [theme.breakpoints.up('md')]: {
//       // width: `calc(100% - ${drawerWidth}px)`, // appBar shifts to allow drawer
//       // marginLeft: drawerWidth,
//       zIndex: theme.zIndex.drawer + 1, // appBar covers full top of page
//     },
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   title: {
//     flexGrow: 1,
//   },
// }))
