import Entity from './Entity'
import User from './User'

export default class ActionComponent extends Entity {
  id
  name
  simpleCoaching
  sortBy
  folder
  rpm_low
  rpm_high
  pr_range
  coachingNotes
  level
  videoURL
  owner
  active
  createdByUser
  actionComponentCreatedByUserId

  constructor(initialValues = {}) {
    let _defaults = { sortBy: 1, folder: 1, level: 0, active: true }
    super({ ..._defaults, ...initialValues })
    // console.log({ initialValues })

    if (initialValues !== null) {
      const { createdByUser = null } = initialValues
      if (createdByUser !== null) {
        this.createdByUser = new User(createdByUser)
        this.actionComponentCreatedByUserId = createdByUser.id
      }
    }
  }

  isReleased() {
    return this.active === true
  }

  isLevel(level) {
    return this.level >= level
  }

  isSuitableForBPM(bpm) {
    return this.rpm_low <= bpm && this.rpm_high >= bpm
  }

  folderOptions() {
    return [
      { key: 0, value: '' },
      { key: 1, value: 'Pre Class Scripts' },
      { key: 2, value: 'Warm Ups' },
      { key: 3, value: 'BB Universal / BWD%' },
      { key: 4, value: 'Jumps' },
      { key: 5, value: 'Drafts / BWD%' },
      { key: 6, value: 'Drafts Combinations' },
      { key: 7, value: 'Shift Backs' },
      { key: 8, value: 'Foundational Drills' },
      { key: 9, value: 'Additional Drills' },
      { key: 10, value: 'Drill Combinations' },
      { key: 11, value: 'Foundational Upper Body Add-Ons' },
      { key: 12, value: 'Additional Upper Body Add-Ons' },
      { key: 13, value: 'Upper Body Add-Ons Combinations' },
      { key: 14, value: 'Cool Down' },
      { key: 15, value: 'Post Cool Down' },
      { key: 16, value: 'Cool Down and Post Cool Down Combined' },
    ]
  }

  folderLabel() {
    let options = this.folderOptions()
    if (this.folder !== undefined && this.folder >= 0) {
      return options.find(option => {
        return option.key === this.folder
      }).value
    } else {
      return ''
    }
  }
}
