import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import AppCuratedClassService from '../../../../../../services/AppCuratedClassService'
import AppCuratedSongService from '../../../../../../services/AppCuratedSongService'
import PageLoader from '../../../components/molecules/PageLoader'
import { Page } from '../../../components/molecules/Page'
import AlertConfirmation from '../../../components/molecules/AlertConfirmation'
// import { CURATED_CLASS_TYPE } from '../../../../../../entities/CuratedClassEntity'
// import { FREESTYLE_CLASS_TYPE } from '../../../../../../entities/CuratedClassEntity'

const CuratedClassDelete = ({ activeUser, classType, match, history }) => {
  const { classID } = match.params
  const [selectedClass, setSelectedClass] = React.useState(null)
  const pageContent = {
    pageTitle: 'Delete Curated Class:',
    introText: '',
  }

  const CuratedClassService = new AppCuratedClassService()
  const curatedSongService = new AppCuratedSongService()

  const maxLevel = activeUser.currentClassRelease
    ? activeUser.currentClassRelease
    : 0

  useEffect(() => {
    ;(async classID => {
      const sortOrder = []
      CuratedClassService.clear()
      const data = await CuratedClassService.fetchCuratedClassWithSongs(
        classID,
        maxLevel,
        sortOrder,
      )
      console.log('CuratedClassDelete: data', data, maxLevel)
      setSelectedClass(data)
    })(classID)
    // console.log('CuratedClassDelete: data',selectedClass)
  }, [classID, maxLevel, CuratedClassService])

  const deleteHandler = async selectedClass => {
    if ('curatedSongs' in selectedClass) {
      selectedClass.curatedSongs.forEach(async song => {
        await curatedSongService.deleteCuratedSong(song.id)
      })
    }
    await CuratedClassService.deleteCuratedClass(selectedClass.id)
    history.push(`/${classType}/list`)
  }

  const cancelHandler = () => {
    history.push(`/${classType}/list`)
  }

  if (selectedClass !== null) {
    console.log('CuratedClassDelete: ', { selectedClass })
    if (selectedClass.length === 0) {
      history.push(`/${classType}/list`) // Not Found
      return null
    }

    let canDelete = false
    if (activeUser.isAdminUser()) {
      canDelete = true
    }

    if (canDelete) {
      return (
        <Page.WithTitle pageContent={pageContent}>
          <RenderDeleteConfirmButton
            {...{
              selectedClass,
              onConfirm: deleteHandler,
              onCancel: cancelHandler,
            }}
          />
        </Page.WithTitle>
      )
    }
    return (
      <Page.WithTitle pageContent={pageContent}>
        You do not have permission to delete this class.
      </Page.WithTitle>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(CuratedClassDelete)

const RenderDeleteConfirmButton = ({ selectedClass, onConfirm, onCancel }) => {
  const songCount =
    'curatedSongs' in selectedClass ? selectedClass.curatedSongs.length : 0
  const songs = songCount ? ' with ' + songCount + ' songs ' : ''
  const confirmDeleteProps = {
    trigger: false,
    title: 'Are you sure?',
    content: `Deleting "${selectedClass.title}"${songs} is permanent.`,
    cancelLabel: 'Cancel',
    onCancel: onCancel,
    confirmLabel: 'Delete',
    onConfirm: onConfirm.bind(this, selectedClass),
  }
  return <AlertConfirmation {...confirmDeleteProps} />
}
