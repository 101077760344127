export default class CuratedSongService {
  constructor(repository, adapter) {
    this._repository = repository
    this._adapter = adapter
  }

  createCuratedSong(entity) {
    return this._repository.createEntity(entity)
  }

  fetchCuratedSong(id) {
    return this._repository.fetchEntity(id)
  }

  updateCuratedSong(entity) {
    return this._repository.updateEntity(entity)
  }

  deleteCuratedSong(id) {
    return this._repository.deleteEntity(id)
  }

  fetchAllCuratedSongs(sortBy) {
    return this._repository.fetchAll(sortBy)
  }

  fetchAllCuratedSongsByLevel(maxLevel, classType, sortBy) {
    return this._repository.fetchCuratedSongsForLevel(
      maxLevel,
      classType,
      sortBy,
    )
  }

  addCuratedSongEntity(entity) {
    this._repository.addEntity(entity)
    return this._repository.get()[0]
  }

  clear() {
    this._repository.clear()
  }
}
