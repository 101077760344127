import React, { Component } from 'react'
import { withAuthenticator } from 'aws-amplify-react'
import Amplify, { Auth, Hub } from 'aws-amplify'
import awsmobile from '../../../../aws-exports'
import ReactGA from 'react-ga'

import InitUserData from './components/molecules/InitUserData'
import PageLoader from './components/molecules/PageLoader'
import { Routes } from './Routes'
import { AppErrorBoundary } from './errors/ErrorBoundary'

Amplify.configure(awsmobile)
// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js')
//   whyDidYouRender(React, { include: [/^PageListPage/] })
// }
class App extends Component {
  state = {
    loadComplete: false,
    user: null,
    activeUser: null,
    classRepository: null,
    layoutSongRepository: null,
    pages: null,
    actionComponents: null,
  }

  componentDidMount() {
    Hub.listen('auth', data => {
      console.log(
        'A new auth event has happened: ',
        data.payload.data.username + ' has ' + data.payload.event,
      )
      this.onHubCapsule(data)
    })

    this.getUserData()
  }
  componentWillUnmount() {
    Hub.remove('auth')
  }
  onHubCapsule = capsule => {
    console.log('capsule', capsule)
    switch (capsule.payload.event) {
      case 'signIn':
        this.getUserData()
        ReactGA.event({
          category: 'Authentication',
          action: 'User Sign In',
        })
        break

      case 'signUp':
        ReactGA.event({
          category: 'Authentication',
          action: 'User Create Account',
        })
        break
      case 'signOut':
        this.setState({ user: null, activeUser: null })
        ReactGA.event({
          category: 'Authentication',
          action: 'User Sign Out',
        })
        break
      default:
        return
    }
  }
  getUserData = async () => {
    const user = await Auth.currentAuthenticatedUser()
    user ? this.setState({ user }) : this.setState({ user: null })
    const trackingId = 'UA-155214597-1' // Replace with your Google Analytics tracking ID
    ReactGA.initialize(trackingId)
    // console.log(user)
    this.initUser()
  }
  initUser = async () => {
    let activeUser = await InitUserData(this.state.user)
    // console.log('App: initUser - activeUser = ', activeUser, this.state.user)

    if (activeUser.hasOwnProperty('errors')) {
      this.setState({ activeUser: { errors: 'error fetching user' } })
      ReactGA.event({
        category: 'Authentication',
        action: 'User Authentication Failed',
      })
    } else {
      this.setState({ activeUser: activeUser })
      ReactGA.set({
        userId: activeUser.id,
        dimension1: activeUser.isAdminUser(),
        dimension2: process.env.REACT_APP_ENV,
        // any data that is relevant to the user session
        // that you would like to track with google analytics
      })
      ReactGA.event({
        category: 'Authentication',
        action: 'User loaded the App',
      })
    }
  }
  handleSignOut = async () => {
    try {
      await Auth.signOut()
      ReactGA.event({
        category: 'Authentication',
        action: 'User clicked logout',
      })
    } catch (err) {
      console.error('error signing out user', err)
    }
  }

  render() {
    const isPageLoadComplete = () => {
      if (
        this.state.activeUser === null ||
        this.state.activeUser === undefined
      ) {
        return false
      }
      return true
    }
    if (!isPageLoadComplete()) {
      return <PageLoader />
    }
    if (this.state.activeUser.hasOwnProperty('errors')) {
      ReactGA.event({
        category: 'App',
        action: 'Service is not available',
      })
      return (
        <div>
          Service is not available at this time. Please check back at a later
          time
        </div>
      )
    }

    if (this.state.activeUser.authUsername === 'dsr771') {
      console.info('activeUser', this.state.activeUser)
    }

    return (
      <AppErrorBoundary>
        <Routes
          activeUser={this.state.activeUser}
          handleSignOut={this.handleSignOut}
        />
      </AppErrorBoundary>
    )
  }
}

// export default App;
// export default withAuthenticator(App, true);
export default withAuthenticator(App, false)
// export default withAuthenticator(App, {includeGreetings: false}, [], null, theme)
