import GraphQLAdapter from './GraphQLAdapter'
import {
  USER_ENTITY_NUMBER_LIST,
  USER_ENTITY_STRING_LIST,
  USER_ENTITY_BOOLEAN_LIST,
} from '../../entities/User'
import { listUsers } from '../../../graphql/queries'
import { createUser, updateUser } from '../../../graphql/userMutations'
import {
  getActiveUser,
  getUser,
  getActiveUserEntity,
} from '../../../graphql/userQueries'
import { GraphQLQueryParams } from './GraphQLAdapter'

const _queryParams = { limit: 999999 }

export default class UsersGraphQLAdapter extends GraphQLAdapter {
  constructor() {
    super()
    this._adapter = new GraphQLAdapter()
  }

  async createEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // console.info('CuratedGraphQLAdapter: createEntity  entity= ',entity)
    const response = await this._adapter
      .mutation(createUser, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('createEntity = ',response)
    if (response) {
      return response.data.createUser
    } else {
      return []
    }
  }

  async fetchEntity(id) {
    const response = await this._adapter.query(getUser, { id: id }).catch(e => {
      return this.logDBError(e)
    })
    // console.log('CuratedGraphQLAdapter: fetchEntity: response=',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.getUser
    } else {
      return isError
    }
  }

  async updateEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // console.info('CuratedGraphQLAdapter: updateEntity  entity= ',entity)
    const response = await this._adapter
      .mutation(updateUser, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('UserGraphQLAdapter: updateEntity  response= ',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.updateUser
    } else {
      return isError
    }
  }

  // deleteEntity

  async fetchAll(queryParams) {
    if (queryParams === undefined) {
      queryParams = _queryParams
    } else {
      queryParams = { ..._queryParams, ...queryParams }
    }
    const response = await this._adapter
      .query(listUsers, queryParams)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.listUsers.items
    } else {
      return isError
    }
  }

  async fetchActiveUser(userId) {
    const response = await this._adapter
      .query(getActiveUser, { id: userId })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.log('UsersGraphQLAdapter: fetchActiveUser: response=',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.getUser
    } else {
      return isError
    }
  }

  async fetchActiveUserEntity(userId, userLevel) {
    let queryOptions = GraphQLQueryParams
    queryOptions.id = userId
    queryOptions.limit = 999
    queryOptions.acfilter = { level: { le: userLevel } }
    const response = await this._adapter
      .query(getActiveUserEntity, queryOptions)
      .catch(e => {
        return this.logDBError(e)
      })
    // console.log(
    //   'UsersGraphQLAdapter: fetchActiveUserEntity: response=',
    //   response,
    // )
    const isError = this.checkForError(response)
    if (!isError) {
      const pages = response.data.listPages.items
      const actionComponents = response.data.listActionComponents.items
      return {
        id: userId,
        user: response.data.getUser,
        pages: pages,
        actionComponents: actionComponents,
      }
    } else {
      return isError
    }
  }

  mapEntityToAdapter(entity) {
    if (entity.id === '_new_') {
      entity.id = null
    }

    // INT values should not be empty string
    USER_ENTITY_NUMBER_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = 0
      }
    })
    // STRING values should be null instead of ''
    USER_ENTITY_STRING_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = null
      }
    })
    // BOOLEAN values should be false instead of ''
    USER_ENTITY_BOOLEAN_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = false
      }
    })

    delete entity.actionComponents

    return entity
  }
}
