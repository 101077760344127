import React from 'react'

import AppActiveUserService from '../../../../../services/AppActiveUserService'
import { FetchActiveUserEntity } from '../../hooks/useActiveUserService'
import { Page } from '../../components/molecules/Page'

const AdminSeedData = ({ activeUser }) => {
  const userService = new AppActiveUserService()
  const data = FetchActiveUserEntity(
    activeUser.id,
    activeUser.currentClassRelease,
    userService,
  )
  console.log('FetchActiveUserEntity', data)
  const pageContent = {
    pageTitle: 'Seed Data',
    introText: 'Loading seed data if needed',
  }
  return <Page.WithIntro pageContent={pageContent}></Page.WithIntro>
}

export default AdminSeedData
