import { useState, useEffect } from 'react'

export const useActionComponents = (ticketService, orderReference) => {
  const [tickets, setTickets] = useState()

  useEffect(() => {
    if (ticketService && orderReference) {
      ticketService
        .getTicketsByOrderReference(orderReference)
        .catch(() => [])
        .then(tickets => {
          setTickets(tickets)
        })
    }
  }, [ticketService, orderReference])

  return tickets
}

export const FetchAllActionComponentsByLevel = (
  maxLevel,
  sortBy,
  actionComponentService = {},
  refresh,
) => {
  const [actionComponents, setActionComponents] = useState()
  useEffect(() => {
    if (
      sortBy &&
      actionComponentService &&
      actionComponentService.FetchAllActionComponentsByLevel
    ) {
      actionComponentService.clear()
      actionComponentService
        .FetchAllActionComponentsByLevel(maxLevel, sortBy)
        .catch(() => [])
        .then(data => {
          setActionComponents(data)
        })
    }
  }, [
    maxLevel,
    sortBy,
    actionComponentService,
    actionComponentService.fetchActionComponentsForLevel,
    setActionComponents,
  ])
  return actionComponents
}
