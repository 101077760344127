import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import AppFreestyleClassService from '../../../../../../services/AppFreestyleClassService'
import AppFreestyleSongService from '../../../../../../services/AppFreestyleSongService'
import PageLoader from '../../../components/molecules/PageLoader'
import { Page } from '../../../components/molecules/Page'
import AlertConfirmation from '../../../components/molecules/AlertConfirmation'
import { FREESTYLE_CLASS_TYPE } from '../../../../../../entities/FreestyleClassEntity'

const FreestyleClassDelete = ({ activeUser, classType, match, history }) => {
  const { classID } = match.params
  const [selectedClass, setSelectedClass] = React.useState(null)
  const pageContent = {
    pageTitle: 'Delete Freestyle Class:',
    introText: '',
  }

  const freestyleClassService = new AppFreestyleClassService()
  const freestyleSongService = new AppFreestyleSongService()

  const maxLevel = activeUser.currentClassRelease
    ? activeUser.currentClassRelease
    : 0

  useEffect(() => {
    ;(async classID => {
      const sortOrder = []
      freestyleClassService.clear()
      const data = await freestyleClassService.fetchFreestyleClassWithSongs(
        classID,
        maxLevel,
        sortOrder,
      )
      console.log('FreestyleClassDelete: data', data, maxLevel)
      setSelectedClass(data)
    })(classID)
  }, [classID, maxLevel, freestyleClassService])

  const deleteHandler = async selectedClass => {
    if ('freestyleSongs' in selectedClass) {
      selectedClass.freestyleSongs.forEach(async song => {
        await freestyleSongService.deleteFreestyleSong(song.id)
      })
    }
    await freestyleClassService.deleteFreestyleClass(selectedClass.id)
    history.push(`/${classType}/list`)
  }

  const cancelHandler = () => {
    history.push(`/${classType}/list`)
  }

  if (selectedClass !== null) {
    if (selectedClass.length === 0) {
      history.push(`/${classType}/list`) // Not Found
      return null
    }

    let canDelete = false
    if (activeUser.isAdminUser()) {
      canDelete = true
    }
    if (selectedClass.type === FREESTYLE_CLASS_TYPE) {
      if (activeUser.authUsername === selectedClass.owner) {
        canDelete = true
      }
    }

    if (canDelete) {
      return (
        <Page.WithTitle pageContent={pageContent}>
          <RenderDeleteConfirmButton
            {...{
              selectedClass,
              onConfirm: deleteHandler,
              onCancel: cancelHandler,
            }}
          />
        </Page.WithTitle>
      )
    }
    return (
      <Page.WithTitle pageContent={pageContent}>
        You do not have permission to delete this class.
      </Page.WithTitle>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(FreestyleClassDelete)

const RenderDeleteConfirmButton = ({ selectedClass, onConfirm, onCancel }) => {
  const songCount =
    'freestyleSongs' in selectedClass ? selectedClass.freestyleSongs.length : 0
  const songs = songCount ? ' with ' + songCount + ' songs ' : ''
  const confirmDeleteProps = {
    trigger: false,
    title: 'Are you sure?',
    content: `Deleting "${selectedClass.title}"${songs} is permanent.`,
    cancelLabel: 'Cancel',
    onCancel: onCancel,
    confirmLabel: 'Delete',
    onConfirm: onConfirm.bind(this, selectedClass),
  }
  return <AlertConfirmation {...confirmDeleteProps} />
}
