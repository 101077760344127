import React from 'react'
import ReactMarkdown from 'react-markdown/with-html'

import PageTitle from './PageTitle'
import { Spacing } from '../atoms/Spacing'

const PageIntro = ({ pageTitle, introText = '' }) => {
  if (introText === null) {
    introText = ''
  }
  return (
    <>
      <PageTitle pageTitle={pageTitle} />
      <Spacing p={2} bgcolor="background.paper">
        <ReactMarkdown source={introText} escapeHtml={false} />
      </Spacing>
    </>
  )
}

export default PageIntro
