/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    authUsername
    email
    phoneNumber
    firstName
    lastName
    currentClassRelease
    lastReleaseDate
    active
    status
    subscriber
    certificationDate
    admin
    address
    address2
    city
    state
    zip
    agreeTerms
    currentlyInstructing
    instructingLocation
    notes
    lastLogin
    actionComponents {
      nextToken
    }
    curatedClasses {
      nextToken
    }
    freestyleClasses {
      nextToken
    }
    owner
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    authUsername
    email
    phoneNumber
    firstName
    lastName
    currentClassRelease
    lastReleaseDate
    active
    status
    subscriber
    certificationDate
    admin
    address
    address2
    city
    state
    zip
    agreeTerms
    currentlyInstructing
    instructingLocation
    notes
    lastLogin
    actionComponents {
      nextToken
    }
    curatedClasses {
      nextToken
    }
    freestyleClasses {
      nextToken
    }
    owner
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    authUsername
    email
    phoneNumber
    firstName
    lastName
    currentClassRelease
    lastReleaseDate
    active
    status
    subscriber
    certificationDate
    admin
    address
    address2
    city
    state
    zip
    agreeTerms
    currentlyInstructing
    instructingLocation
    notes
    lastLogin
    actionComponents {
      nextToken
    }
    curatedClasses {
      nextToken
    }
    freestyleClasses {
      nextToken
    }
    owner
  }
}
`;
export const createPage = `mutation CreatePage($input: CreatePageInput!) {
  createPage(input: $input) {
    id
    pageTitle
    linkPath
    menuSection
    linkText
    linkIcon
    introText
    pageContent
    videoLink
    videoLabel
    buttonLink
    buttonLabel
    sortBy
    includeNavigationMenu
    includeFooter
    adminOnly
    owner
  }
}
`;
export const updatePage = `mutation UpdatePage($input: UpdatePageInput!) {
  updatePage(input: $input) {
    id
    pageTitle
    linkPath
    menuSection
    linkText
    linkIcon
    introText
    pageContent
    videoLink
    videoLabel
    buttonLink
    buttonLabel
    sortBy
    includeNavigationMenu
    includeFooter
    adminOnly
    owner
  }
}
`;
export const deletePage = `mutation DeletePage($input: DeletePageInput!) {
  deletePage(input: $input) {
    id
    pageTitle
    linkPath
    menuSection
    linkText
    linkIcon
    introText
    pageContent
    videoLink
    videoLabel
    buttonLink
    buttonLabel
    sortBy
    includeNavigationMenu
    includeFooter
    adminOnly
    owner
  }
}
`;
export const createCuratedClass = `mutation CreateCuratedClass($input: CreateCuratedClassInput!) {
  createCuratedClass(input: $input) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    classSongs {
      nextToken
    }
    owner
  }
}
`;
export const updateCuratedClass = `mutation UpdateCuratedClass($input: UpdateCuratedClassInput!) {
  updateCuratedClass(input: $input) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    classSongs {
      nextToken
    }
    owner
  }
}
`;
export const deleteCuratedClass = `mutation DeleteCuratedClass($input: DeleteCuratedClassInput!) {
  deleteCuratedClass(input: $input) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    classSongs {
      nextToken
    }
    owner
  }
}
`;
export const createClassSong = `mutation CreateClassSong($input: CreateClassSongInput!) {
  createClassSong(input: $input) {
    id
    classId
    song
    artist
    rpm_a
    rpm_b
    pr_a
    pr_b
    coaching_notes
    coaching_notes_simple
    sortOrder
    videoURL
    curatedClass {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    actionComponent {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    owner
  }
}
`;
export const updateClassSong = `mutation UpdateClassSong($input: UpdateClassSongInput!) {
  updateClassSong(input: $input) {
    id
    classId
    song
    artist
    rpm_a
    rpm_b
    pr_a
    pr_b
    coaching_notes
    coaching_notes_simple
    sortOrder
    videoURL
    curatedClass {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    actionComponent {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    owner
  }
}
`;
export const deleteClassSong = `mutation DeleteClassSong($input: DeleteClassSongInput!) {
  deleteClassSong(input: $input) {
    id
    classId
    song
    artist
    rpm_a
    rpm_b
    pr_a
    pr_b
    coaching_notes
    coaching_notes_simple
    sortOrder
    videoURL
    curatedClass {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    actionComponent {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    owner
  }
}
`;
export const createFreestyleClass = `mutation CreateFreestyleClass($input: CreateFreestyleClassInput!) {
  createFreestyleClass(input: $input) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    freestyleSongs {
      nextToken
    }
    owner
  }
}
`;
export const updateFreestyleClass = `mutation UpdateFreestyleClass($input: UpdateFreestyleClassInput!) {
  updateFreestyleClass(input: $input) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    freestyleSongs {
      nextToken
    }
    owner
  }
}
`;
export const deleteFreestyleClass = `mutation DeleteFreestyleClass($input: DeleteFreestyleClassInput!) {
  deleteFreestyleClass(input: $input) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    freestyleSongs {
      nextToken
    }
    owner
  }
}
`;
export const createFreestyleSong = `mutation CreateFreestyleSong($input: CreateFreestyleSongInput!) {
  createFreestyleSong(input: $input) {
    id
    classId
    song
    artist
    rpm_a
    rpm_b
    pr_a
    pr_b
    coaching_notes
    coaching_notes_simple
    sortOrder
    videoURL
    freestyleClass {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    actionComponent {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    owner
  }
}
`;
export const updateFreestyleSong = `mutation UpdateFreestyleSong($input: UpdateFreestyleSongInput!) {
  updateFreestyleSong(input: $input) {
    id
    classId
    song
    artist
    rpm_a
    rpm_b
    pr_a
    pr_b
    coaching_notes
    coaching_notes_simple
    sortOrder
    videoURL
    freestyleClass {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    actionComponent {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    owner
  }
}
`;
export const deleteFreestyleSong = `mutation DeleteFreestyleSong($input: DeleteFreestyleSongInput!) {
  deleteFreestyleSong(input: $input) {
    id
    classId
    song
    artist
    rpm_a
    rpm_b
    pr_a
    pr_b
    coaching_notes
    coaching_notes_simple
    sortOrder
    videoURL
    freestyleClass {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    actionComponent {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    owner
  }
}
`;
export const createActionComponent = `mutation CreateActionComponent($input: CreateActionComponentInput!) {
  createActionComponent(input: $input) {
    id
    name
    simpleCoaching
    sortBy
    folder
    rpm_low
    rpm_high
    pr_range
    coachingNotes
    level
    videoURL
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    classSongs {
      nextToken
    }
    freestyleSongs {
      nextToken
    }
    active
    owner
  }
}
`;
export const updateActionComponent = `mutation UpdateActionComponent($input: UpdateActionComponentInput!) {
  updateActionComponent(input: $input) {
    id
    name
    simpleCoaching
    sortBy
    folder
    rpm_low
    rpm_high
    pr_range
    coachingNotes
    level
    videoURL
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    classSongs {
      nextToken
    }
    freestyleSongs {
      nextToken
    }
    active
    owner
  }
}
`;
export const deleteActionComponent = `mutation DeleteActionComponent($input: DeleteActionComponentInput!) {
  deleteActionComponent(input: $input) {
    id
    name
    simpleCoaching
    sortBy
    folder
    rpm_low
    rpm_high
    pr_range
    coachingNotes
    level
    videoURL
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    classSongs {
      nextToken
    }
    freestyleSongs {
      nextToken
    }
    active
    owner
  }
}
`;
export const createDbErrorLog = `mutation CreateDbErrorLog($input: CreateDBErrorLogInput!) {
  createDBErrorLog(input: $input) {
    id
    error
  }
}
`;
export const updateDbErrorLog = `mutation UpdateDbErrorLog($input: UpdateDBErrorLogInput!) {
  updateDBErrorLog(input: $input) {
    id
    error
  }
}
`;
export const deleteDbErrorLog = `mutation DeleteDbErrorLog($input: DeleteDBErrorLogInput!) {
  deleteDBErrorLog(input: $input) {
    id
    error
  }
}
`;
