import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import AppActionComponentService from '../../../../../services/AppActionComponentService'
import { FetchAllActionComponentsByLevel } from '../../hooks/useActionComponentService'
import PageLoader from '../../components/molecules/PageLoader'
import User from '../../../../../entities/User'
import { Page } from '../../components/molecules/Page'
import { ActionComponentTable } from './ActionComponentTable'
import { FilterBar } from '../../components/atoms/FilterBar'
import { useDataRefresh } from '../../hooks/useDataRefresh'
import { LinkButton } from '../../components/atoms/LinkButton'
import { Spacing } from '../../components/atoms/Spacing'
import { BlankClassLayoutButtonLink } from '../../components/molecules/BlankClassLayoutButtonLink'
import { UserIsApproved } from '../../components/molecules/UserIsApproved'
// import AlertConfirmation from '../../components/molecules/AlertConfirmation'

const ActionComponentsPage = ({ activeUser, pageContent }) => {
  const [isLoading, setIsLoading] = useState(true)
  const defaultSortBy = ['folder', 'sortBy']
  const [sortBy] = useState(defaultSortBy)
  const [actionComponentRows, setActionComponentRows] = useState()
  const [refresh] = useDataRefresh() // setRefresh
  const maxLevel = activeUser.currentClassRelease
    ? activeUser.currentClassRelease
    : 0

  const actionComponentService = new AppActionComponentService()
  const fetchedData = FetchAllActionComponentsByLevel(
    maxLevel,
    sortBy,
    actionComponentService,
    refresh,
  )

  const onFilterChange = event => {
    const filter = event.target.value
    if (fetchedData.length) {
      const filteredData = RowFilter(fetchedData, filter)
      setActionComponentRows(filteredData)
    }
  }

  useEffect(() => {
    if (isLoading && fetchedData) {
      setActionComponentRows(fetchedData)
      setIsLoading(false)
    }
  }, [isLoading, fetchedData, setIsLoading, refresh])

  if (isLoading) {
    return <PageLoader />
  }

  if (actionComponentRows !== undefined && actionComponentRows !== null) {
    // console.log({ actionComponentRows })
    return (
      <Page.WithIntro pageContent={pageContent}>
        <UserIsApproved activeUser={activeUser}>
          <Spacing p={1}>
            {activeUser.isAdminUser() ? (
              <RenderAddButton {...{ activeUser }} />
            ) : null}
            <BlankClassLayoutButtonLink />
          </Spacing>
          <FilterBar
            onChange={onFilterChange}
            title="Filter Action Components"
          />
          {actionComponentRows.length ? (
            <ActionComponentTable
              {...{ activeUser, actionComponentRows, styles }}
            />
          ) : (
            <div>There are no action components found</div>
          )}
        </UserIsApproved>
      </Page.WithIntro>
    )
  } else {
    return <PageLoader />
  }
}

export default ActionComponentsPage
ActionComponentsPage.propTypes = {
  activeUser: PropTypes.instanceOf(User),
  pageContent: PropTypes.object,
}

const RowFilter = (data, filter) => {
  const filtered = data.filter(function(row) {
    const haystack =
      row.name +
      row.simpleCoaching +
      row.coachingNotes +
      row.owner +
      '' +
      row.folderLabel()
    const isMatch = haystack
      .toLocaleLowerCase()
      .includes(filter.toLocaleLowerCase())
    return isMatch
  })
  return filtered
}

const RenderAddButton = () => {
  return (
    <LinkButton to="/components/edit/_new_">Create New Component</LinkButton>
  )
}

const styles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  headline: {
    padding: 6,
  },
  table: {
    minWidth: 400,
  },
}))
