import { useState, useEffect } from 'react'

export const FetchUser = (userId = '', userService = {}) => {
  const [user, setUser] = useState()

  useEffect(() => {
    if (userId && userService && userService.fetchEntity) {
      userService
        .fetchEntity(userId)
        .catch(() => [])
        .then(data => {
          setUser(data)
        })
    }
  }, [userId, userService, setUser])

  return user
}

export const FetchAllUsers = (sortBy, userService = {}, refresh) => {
  const [users, setUsers] = useState()
  const [sortOrder] = useState(sortBy)

  useEffect(() => {
    if (sortOrder && userService) {
      userService.clear()
      userService
        .fetchAll(sortOrder)
        .catch(() => [])
        .then(data => {
          setUsers(data)
        })
    }
  }, [sortOrder, userService, setUsers, refresh])

  return users
}
