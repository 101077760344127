import React, { useState, useEffect } from 'react'
import ClassSongs from '../songs/ClassSongs'

import AppFreestyleClassService from '../../../../../../services/AppFreestyleClassService'
import PageLoader from '../../../components/molecules/PageLoader'
import { Page } from '../../../components/molecules/Page'
import { UserIsApproved } from '../../../components/molecules/UserIsApproved'
import { CreatedByName } from '../../../components/functions/CreatedByName'
import { Spacing } from '../../../components/atoms/Spacing'

const FreestyleLayoutPage = ({
  activeUser,
  classType,
  sharedFreestyle,
  match,
  history,
}) => {
  const { classID } = match.params
  const [sortBy] = useState(['sortBy'])
  const [classLayout, setLayout] = useState(null)
  // const maxLevel = activeUser.currentClassRelease
  //   ? activeUser.currentClassRelease
  //   : 0

  const freestyleClassService = new AppFreestyleClassService()

  useEffect(() => {
    ;(async classID => {
      freestyleClassService.clear()
      const data = await freestyleClassService.fetchFreestyleClassWithSongs(
        classID,
        sortBy,
      )
      if (data.createdByUser === null) {
        data.createdByUser = { id: activeUser.id } // assume ownership
      }
      // data.shared = false // assume shared @TODO: this needs to be optional for freestyle
      // console.log('fetchFreestyleClassWithSongs', data, classID)
      if (data === [] || 'errors' in data) {
        setLayout({})
      } else {
        setLayout(data)
      }
      let highLevel = 0
      data.freestyleSongs.forEach(song => {
        if (song.actionComponent.level > highLevel) {
          highLevel = song.actionComponent.level
        }
      })
      try {
        if (highLevel !== data.layoutLevel) {
          freestyleClassService.updateFreestyleClass({
            id: data.id,
            layoutLevel: highLevel,
          })
        }
      } catch (error) {
        console.log('error updating class level')
      }
    })(classID)
  }, [classID, sortBy, freestyleClassService, activeUser.id])

  if (classLayout === null) {
    return <PageLoader />
  }
  if (classLayout.id === classID) {
    // console.log('FreestyleLayoutPage:', { sharedFreestyle, classLayout })
    const pageType = sharedFreestyle ? 'Shared Freestyle' : 'Freestyle'
    return (
      <Page.WithTitle
        pageContent={{ pageTitle: `${pageType} Class - ${classLayout.title}` }}
      >
        <UserIsApproved activeUser={activeUser}>
          {CreatedByName(classLayout) !== ''
            ? 'By: ' + CreatedByName(classLayout)
            : null}
          <Spacing p={1}>
            USE OF TOUCH SCREENS IN CLASS WHEN INSTRUCTING Whether in Detailed
            or Simple View when teaching a class and you are using your iPhone
            or iPad to follow Class Layout, scroll/swipe then pause and repeat
            to unlock the scroll/swipe ability.
          </Spacing>
          <ClassSongs
            {...{ classLayout, classType, sharedFreestyle, activeUser }}
          />
        </UserIsApproved>
      </Page.WithTitle>
    )
  } else {
    return (
      <Page.WithTitle pageContent={{ pageTitle: 'Curated Class' }}>
        Class not found
      </Page.WithTitle>
    )
  }
}

export default FreestyleLayoutPage
