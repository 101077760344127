import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useTheme } from '@material-ui/core/styles'
import PageEntity from '../clean/entities/PageEntity'
import { MyAppBar } from '../clean/adapters/presenters/react/components/organisms/MyAppBar'
import isPageVisibleToUser from '../clean/adapters/presenters/react/components/molecules/isPageVisibleToUser'

function ResponsiveMenuDrawer({ pages, activeUser, handleSignOut, children }) {
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleLinkClick = () => {
    if (mobileOpen) {
      setMobileOpen(false)
    }
  }

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <RenderAdminMenu {...{ pages, activeUser, handleLinkClick, classes }} />
      <Divider />
      <RenderNavigationMenu
        {...{ pages, activeUser, handleLinkClick, classes }}
      />
      <Divider />
      <RenderFooterMenu {...{ pages, activeUser, handleLinkClick, classes }} />
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MyAppBar
        {...{
          activeUser,
          handleSignOut,
          handleDrawerToggle,
          classes,
        }}
      />
      <nav className={classes.drawer} aria-label="main menu">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            // container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {/* <div className={classes.toolbar} /> */}
        {children}
      </main>
    </div>
  )
}

ResponsiveMenuDrawer.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.instanceOf(PageEntity)),
  activeUser: PropTypes.object,
  children: PropTypes.node,
}

export default ResponsiveMenuDrawer

const drawerWidth = 240
const menuHiddenBreakpoint = 'md'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up(menuHiddenBreakpoint)]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(6),
    // padding: theme.spacing(3),
  },
  appBar: {
    backgroundColor: '#000000',
    [theme.breakpoints.up(menuHiddenBreakpoint)]: {
      // width: `calc(100% - ${drawerWidth}px)`, // appBar shifts to allow drawer
      // marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer + 1, // appBar covers full top of page
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(menuHiddenBreakpoint)]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
}))

const RenderAdminMenu = ({ pages, activeUser, handleLinkClick }) => {
  if (activeUser.isAdminUser() && pages.length > 0) {
    let children = []
    pages.map(page => {
      if (
        page.menuSection === 'Admin' &&
        isPageVisibleToUser(page, activeUser)
      ) {
        children.push(
          <ListItemLink
            key={page.linkPath}
            to={page.linkPath}
            onClick={handleLinkClick}
          >
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={page.linkText} />
          </ListItemLink>,
        )
      }
      return null
    })
    return <List>{children}</List>
  } else {
    return null
  }
}

const RenderNavigationMenu = ({ pages, activeUser, handleLinkClick }) => {
  let children = []
  pages.map(page => {
    if (
      page.menuSection === 'Main' &&
      page.includeNavigationMenu &&
      isPageVisibleToUser(page, activeUser)
    ) {
      children.push(
        <ListItemLink
          key={page.linkPath}
          to={page.linkPath}
          onClick={handleLinkClick}
        >
          {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          <ListItemText primary={page.linkText} />
        </ListItemLink>,
      )
    }
    return null
  })
  return <List>{children}</List>
}

const RenderFooterMenu = ({ pages, activeUser, handleLinkClick }) => {
  let children = []
  pages.map(page => {
    if (
      (page.menuSection === 'Main' || page.menuSection === 'Custom') &&
      page.includeFooter &&
      isPageVisibleToUser(page, activeUser)
    ) {
      if (isInternalPage(page.linkPath)) {
        children.push(
          <ListItemLink
            key={page.linkPath}
            to={page.linkPath}
            onClick={handleLinkClick}
          >
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={page.linkText} />
          </ListItemLink>,
        )
      } else {
        children.push(
          <ExternalLink
            key={page.linkPath}
            href={page.linkPath}
            target="_blank"
            rel="noreferrer"
            onClick={handleLinkClick}
          >
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText primary={page.linkText} />
          </ExternalLink>,
        )
      }
    }
    return null
  })
  return <List>{children}</List>
}

function ListItemLink(props) {
  return <ListItem button component={NavLink} {...props} />
}
function ExternalLink(props) {
  return <ListItem button component="a" {...props} />
}

function isInternalPage(link) {
  if (link.substring(0, 1) === '/') {
    return true
  }
  return false
}
