import React from 'react'
import PropTypes from 'prop-types'

import { TrackEvent } from '../atoms/TrackEvent'
import { ExternalLinkButton } from '../atoms/ExternalLinkButton'

export const ExternalLinkButtonTracker = ({
  linkText,
  href,
  target,
  trackerData,
}) => {
  if (href !== '' && href !== null) {
    const onClick = () => TrackEvent(trackerData)
    return (
      <ExternalLinkButton {...{ href, target, onClick }}>
        {linkText}
      </ExternalLinkButton>
    )
  } else {
    return null
  }
}
ExternalLinkButtonTracker.propTypes = {
  linkText: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.string,
  trackerData: PropTypes.object,
}
