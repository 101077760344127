import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import User from '../../../../../entities/User'
import AdminUsersPage from '../users/AdminUsersPage'
import PageListPage from '../sitePages/PageListPage'
import UserEdit from '../users/UserEdit'
import PageEdit from '../sitePages/PageEdit'
import AdminSeedData from './AdminSeedData'
import ActionComponentEdit from '../actionComponents/ActionComponentEdit'
import PageEntity from '../../../../../entities/PageEntity'
import { Tools } from './Tools'

const AdminRoutes = props => {
  AdminRoutes.propTypes = {
    activeUser: PropTypes.instanceOf(User),
  }
  // console.log('AdminRoutes: props=',props)
  const activeUser = props.activeUser

  const getPageProps = linkPath => {
    const page = props.pages.filter(function(page) {
      return page.linkPath === linkPath
    })
    // console.log('getPageProps', page, linkPath, props.pages)
    if (page.length === 1) {
      return { pageContent: page[0] }
    } else {
      return { pageContent: new PageEntity() }
    }
  }

  if (activeUser.isAdminUser()) {
    return (
      <>
        <Route
          path="/admin/users/list"
          exact
          render={props => (
            <AdminUsersPage
              activeUser={activeUser}
              {...getPageProps('/admin/users/list')}
            />
          )}
        />
        <Route
          path="/admin/users/edit/:userId"
          exact
          render={props => <UserEdit />}
        />
        <Route
          path="/components/edit/:actionComponentId"
          exact
          render={props => (
            <ActionComponentEdit {...props} activeUser={activeUser} />
          )}
        />
        <Route
          path="/admin/pages/list"
          exact
          render={props => (
            <PageListPage
              activeUser={activeUser}
              {...getPageProps('/admin/pages/list')}
            />
          )}
        />
        <Route
          path="/admin/pages/edit/:pageId"
          exact
          render={props => <PageEdit />}
        />
        <Route
          path="/admin/seed_data"
          exact
          render={props => <AdminSeedData activeUser={activeUser} />}
        />
        <Route
          path="/admin/tools"
          exact
          render={props => (
            <Tools activeUser={activeUser} {...getPageProps('/admin/tools')} />
          )}
        />
      </>
    )
  }
  return null
}

export default AdminRoutes
