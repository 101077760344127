export default class UserService {
  constructor(repository, adapter) {
    this._repository = repository
    this._adapter = adapter
  }
  createUser(entity) {
    return this._repository.createEntity(entity)
  }

  fetchUser(id) {
    return this._repository.fetchEntity(id)
  }

  updateUser(entity) {
    return this._repository.updateEntity(entity)
  }

  // delete

  batchUpdateUsers(users) {
    users.forEach(user => {
      this.updateUser(user)
    })
    return true
  }

  fetchAll(sortBy) {
    return this._repository.fetchAll(sortBy)
  }

  fetchActiveUser(id) {
    return this._repository.fetchActiveUser(id)
  }

  addUserEntity(entity) {
    this._repository.addUserEntity(entity)
    return this._repository.get()[0]
  }

  clear() {
    this._repository.clear()
  }
}
