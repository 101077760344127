import React, { useEffect, useState } from 'react'

import AppCuratedClassService from '../../../../../../services/AppCuratedClassService'
import AppCuratedSongService from '../../../../../../services/AppCuratedSongService'
import AppFreestyleClassService from '../../../../../../services/AppFreestyleClassService'
import PageLoader from '../../../components/molecules/PageLoader'
import { Page } from '../../../components/molecules/Page'
import AlertConfirmation from '../../../components/molecules/AlertConfirmation'
import { CURATED_CLASS_TYPE } from '../../../../../../entities/CuratedClassEntity'
import { FREESTYLE_CLASS_TYPE } from '../../../../../../entities/FreestyleClassEntity'
import AppFreestyleSongService from '../../../../../../services/AppFreestyleSongService'

export const CopyFreestyleClass = ({ activeUser, match, history }) => {
  const { classID, destinationType } = match.params
  const [sortBy] = useState(['sortOrder'])
  const [classLayout, setLayout] = useState(null)
  const classType = FREESTYLE_CLASS_TYPE
  const pageContent = {
    pageTitle: 'Copying Freestyle Class Layout: Please wait',
    introText: '',
  }
  const curatedClassService = new AppCuratedClassService()
  const curatedSongService = new AppCuratedSongService()
  const freestyleClassService = new AppFreestyleClassService()
  const freestyleSongService = new AppFreestyleSongService()

  useEffect(() => {
    ;(async classID => {
      freestyleClassService.clear()
      const data = await freestyleClassService.fetchFreestyleClassWithSongs(
        classID,
        sortBy,
      )
      if (data.createdByUser === null) {
        data.createdByUser = { id: activeUser.id } // assume ownership
      }
      data.shared = false // assume shared @TODO: this needs to be optional for curated
      // console.log('fetchFreestyleClassWithSongs', data, classID)
      if (data === [] || 'errors' in data) {
        setLayout({})
      } else {
        setLayout(data)
      }
    })(classID)
  }, [classID, sortBy, freestyleClassService, activeUser.id])

  if (classLayout === null) {
    return <PageLoader />
  }

  const copyHandler = async (classLayout, destinationType) => {
    let newLayout = { ...classLayout }
    delete newLayout.classSongs
    newLayout.createdByUser = { id: activeUser.id }
    newLayout.owner = activeUser.authUsername
    newLayout.type = destinationType
    newLayout.id = null
    delete newLayout.classPDFLink
    newLayout.title = 'Copy of ' + classLayout.title

    const classSongs =
      classLayout.type === CURATED_CLASS_TYPE
        ? classLayout.classSongs
        : classLayout.freestyleSongs

    if (destinationType === FREESTYLE_CLASS_TYPE) {
      const newClass = await freestyleClassService.createFreestyleClass(
        newLayout,
      )

      classSongs.forEach(async song => {
        let newSong = song
        newSong.id = null
        delete newSong.classSongActionComponentId
        newSong.owner = activeUser.authUsername
        newSong.freestyleClass = { id: newClass.id }
        await freestyleSongService.createFreestyleSong(newSong)
      })

      // Add delay to allow time to songs to be created on DB before trying to read them
      setTimeout(function() {
        history.push(`/${destinationType}/class/${newClass.id}/songs`)
      }, 3000)
    }

    if (destinationType === CURATED_CLASS_TYPE) {
      const newClass = await curatedClassService.createCuratedClass(newLayout)

      classSongs.forEach(async song => {
        let newSong = song
        song.id = null
        delete song.classSongActionComponentId
        song.curatedClass = { id: newClass.id }
        await curatedSongService.createCuratedSong(newSong)
      })

      history.push(`/${destinationType}/class/${newClass.id}/songs`)
    }
  }

  const cancelHandler = () => {
    history.push(`/${classType}/class/${classID}/songs`)
  }

  if (classLayout !== null) {
    if (classLayout.length === 0) {
      history.push(`/${classType}/class/${classID}/songs`) // Not Found
      return null
    }

    let canCopy = false
    if (activeUser.isAdminUser()) {
      canCopy = true
    }
    if (
      classLayout.type === FREESTYLE_CLASS_TYPE &&
      classLayout.layoutLevel <= activeUser.currentClassRelease
    ) {
      canCopy = true
    }

    if (canCopy) {
      return (
        <Page.WithTitle pageContent={pageContent}>
          <RenderCopyConfirmButton
            {...{
              classLayout,
              destinationType,
              onConfirm: copyHandler,
              onCancel: cancelHandler,
            }}
          />
        </Page.WithTitle>
      )
    }
    return (
      <Page.WithTitle pageContent={pageContent}>
        You do not have permission to copy this layout.
      </Page.WithTitle>
    )
  } else {
    return <PageLoader />
  }
}

const RenderCopyConfirmButton = ({
  classLayout,
  destinationType,
  onConfirm,
  onCancel,
}) => {
  const destination =
    destinationType === FREESTYLE_CLASS_TYPE
      ? 'Guided Freestyle'
      : 'Curated Layouts'
  const confirmCopyProps = {
    trigger: false,
    title: 'Are you sure?',
    content: `you want to copy "${classLayout.title}" to ${destination}`,
    cancelLabel: 'Cancel',
    onCancel: onCancel,
    confirmLabel: 'Copy',
    onConfirm: onConfirm.bind(this, classLayout, destinationType),
  }
  return <AlertConfirmation {...confirmCopyProps} />
}
