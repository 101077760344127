import { useState, useEffect } from 'react'

export const FetchPage = (pageId = '', pageService = {}) => {
  const [page, setPage] = useState()

  useEffect(() => {
    if (pageId && pageService && pageService.fetchEntity) {
      pageService
        .fetchEntity(pageId)
        .catch(() => [])
        .then(data => {
          setPage(data)
        })
    }
  }, [pageId, pageService, setPage])

  return page
}

export const FetchAllPages = (sortBy, pageService = {}, refresh) => {
  // console.log('usePageService: FetchAllPages', sortBy, refresh)
  const [pages, setPages] = useState()

  useEffect(() => {
    if (sortBy && pageService && pageService.fetchAllPages) {
      pageService.clear()
      pageService
        .fetchAllPages(sortBy)
        .catch(() => [])
        .then(data => {
          setPages(data)
        })
    }
  }, [sortBy, pageService, pageService.fetchAllPages, setPages, refresh])

  return pages
}
