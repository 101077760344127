import React from 'react'
import { Grid } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

const ClassDisplayModeButtons = props => {
  const [songDisplayMode, setSongDisplayMode] = React.useState('detailed')

  const handleChange = (event, newDisplayMode) => {
    setSongDisplayMode(newDisplayMode)
    props.changeHandler(newDisplayMode)
  }

  const children = [
    <ToggleButton key={1} value="detailed">
      Detailed
    </ToggleButton>,
    <ToggleButton key={2} value="class">
      Simple
    </ToggleButton>,
  ]

  return (
    <Grid item xs={6} lg={6}>
      <ToggleButtonGroup
        size="small"
        value={songDisplayMode}
        exclusive
        onChange={handleChange}
      >
        {children}
      </ToggleButtonGroup>
    </Grid>
  )
}

export default ClassDisplayModeButtons
