export const CreatedByName = row => {
  if (row.createdByUser === null || row.createdByUser === undefined) {
    return ''
  }
  if (
    row.createdByUser.firstName !== undefined &&
    row.createdByUser.firstName !== null &&
    row.createdByUser.lastName !== undefined &&
    row.createdByUser.lastName !== null
  ) {
    return row.createdByUser.firstName + ' ' + row.createdByUser.lastName
  }
  return ''
}
