import React from 'react'
import PropTypes from 'prop-types'
import { TableHead, TableRow, TableCell } from '@material-ui/core'

import User from '../../../../../entities/User'
import { LinkButton } from '../../components/atoms/LinkButton'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import PersonIcon from '@material-ui/icons/Person'
import { RenderBoolean } from '../../components/atoms/RenderBoolean'

export const UserRowHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Edit</TableCell>
        <TableCell></TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Email/Username</TableCell>
        <TableCell align="center">Status</TableCell>
        <TableCell align="center">Active</TableCell>
        <TableCell align="center">Subscriber</TableCell>
        <TableCell align="center">Level</TableCell>
        <TableCell>Registration Date</TableCell>
      </TableRow>
    </TableHead>
  )
}
UserRowHeader.propTypes = {}

export const UserRow = ({ user }) => {
  return (
    <TableRow key={user.id}>
      <TableCell>
        <LinkButton to={`/admin/users/edit/${user.id}`}>Edit</LinkButton>
      </TableCell>
      <TableCell component="th" scope="row">
        <AdminIcon admin={user.isAdminUser()} />
      </TableCell>
      <TableCell component="th" scope="row">
        {user.lastName}, {user.firstName}
      </TableCell>
      <TableCell>
        {user.email}
        <br />
        {user.authUsername}
      </TableCell>
      <TableCell align="center">{user.statusLabel()}</TableCell>
      <TableCell align="center">{RenderBoolean(user.active)}</TableCell>
      <TableCell align="center">{RenderBoolean(user.subscriber)}</TableCell>
      <TableCell align="center">{user.currentClassRelease}</TableCell>
      <TableCell align="center">{user.lastLogin}</TableCell>
    </TableRow>
  )
}
UserRow.propTypes = {
  user: PropTypes.instanceOf(User),
  activeUser: PropTypes.instanceOf(User),
}

const AdminIcon = ({ admin }, props) => {
  if (admin === true) {
    return <SupervisorAccountIcon {...props} />
  } else {
    return <PersonIcon {...props} />
  }
}
