import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
	Paper: {
		padding: 20,
		minHeight: 200,
		marginTop: 10,
		marginBottom: 10
	}
}));

const PageLoader = (props) => {
	const variant = (props.variant !== undefined) ? props.variant : 'h5'
	const message = (props.message !== undefined) ? props.message : 'Loading...'
	const styles = useStyles();
	return (
		<Paper className={styles.Paper}>
			<CircularProgress className={styles.progress} />
			<Typography variant={variant}>{message}</Typography>
		</Paper>
	)
}

export default PageLoader
