import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Link as MUILink } from '@material-ui/core'

export const Link = ({ to, children }) => {
  return (
    <MUILink component={RouterLink} className="header" {...{ to }}>
      {children}
    </MUILink>
  )
}
Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
}
