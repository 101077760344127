import FreestyleSongEntity from '../../entities/FreestyleSongEntity'
import Repository from './Repository'

export default class FreestyleSongRepository extends Repository {
  constructor(adapter) {
    super()
    this._adapter = adapter
  }

  addEntity(entity) {
    this.add(new FreestyleSongEntity(entity))
  }

  getClassSong(classId) {
    let query = { id: classId }
    return this.find(query)
  }

  _mapFetchedDataToLayouts(fetchedData) {
    this._data = fetchedData.map(data => new FreestyleSongEntity(data))
  }

  _mapFetchedDataToEntity(fetchedData) {
    if (fetchedData === [] || fetchedData === undefined) {
      return
    }
    if (Array.isArray(fetchedData)) {
      fetchedData.map(data => this.add(new FreestyleSongEntity(data)))
    } else {
      this.add(new FreestyleSongEntity(fetchedData))
    }
    return
  }

  // _mapperIn(data = {}) {
  // 	const mappedData = { id: data.id, title: data.title, description: data.description,
  // 		status: data.status, level: data.level, playListURL: data.playListURL}
  // 	const masterClass = new FreestyleSongEntity(mappedData)
  // 	this._data.push(masterClass)
  // }
}
