import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

import { PlayVideoIcon } from '../../components/atoms/icons/PlayVideo'

export const RenderVideoButton = ({ url }) => {
  if (url && url !== '') {
    return (
      <Button
        variant="contained"
        href={url}
        target="_blank"
        rel="noopener"
        title="Play Video"
      >
        <PlayVideoIcon />
      </Button>
    )
  } else {
    return null
  }
}
RenderVideoButton.propTypes = {
  url: PropTypes.string,
}
