import React from 'react'
import { Page } from '../clean/adapters/presenters/react/components/molecules/Page'

const CertificationPage = props => {
  // const activeUser = props.activeUser ? props.activeUser : []
  const pageContent = props.pageContent ? props.pageContent : null

  return <Page.WithIntro pageContent={pageContent}></Page.WithIntro>
}

export default CertificationPage
