import React from 'react'

import { Page } from '../clean/adapters/presenters/react/components/molecules/Page'

const CustomPage = ({ pageContent }) => {
  console.log('CustomPage')
  return <Page.WithIntro pageContent={pageContent}></Page.WithIntro>
}

export default CustomPage
