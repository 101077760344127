import React, { useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import AppFreestyleSongService from '../../../../../../services/AppFreestyleSongService'
import FREESTYLE_CLASS_TYPE from '../../../../../../entities/FreestyleClassEntity'
import PageLoader from '../../../components/molecules/PageLoader'
import SimpleSelect from '../../../components/molecules/SimpleSelect'
import { Page } from '../../../components/molecules/Page'
import { RPMRange } from '../../../components/atoms/RPMRange'

const SongEdit = ({ activeUser, actionComponents, match, history }) => {
  const { songID, classID } = match.params
  const [freestyleSong, setFreestyleSong] = React.useState(null)

  const freestyleSongService = new AppFreestyleSongService()

  useEffect(() => {
    if (songID !== '_new_') {
      ;(async songID => {
        const data = await freestyleSongService.fetchFreestyleSong(songID)
        // console.log('SongEdit: data',data)
        setFreestyleSong(data)
      })(songID)
    } else {
      freestyleSongService.clear()
      const newPage = freestyleSongService.addFreestyleSongEntity({
        id: '_new_',
        freestyleClass: { id: classID },
        type: FREESTYLE_CLASS_TYPE,
        actionComponent: { id: '_new_' },
      })
      setFreestyleSong(newPage)
    }
    // console.log('SongEdit: data',freestyleSong)
  }, [songID, classID, freestyleSongService])

  const handleChange = name => event => {
    let newValue = Object.assign(
      Object.create(Object.getPrototypeOf(freestyleSong)),
      freestyleSong,
    ) // update value while preserving class object
    newValue[name] = event.target.value
    if (name === 'freestyleSongActionComponentId') {
      const component = actionComponents.filter(
        component => component.id === event.target.value,
      )[0]
      newValue['actionComponent'] = component
      newValue['coaching_notes_simple'] = component.simpleCoaching
      newValue['coaching_notes'] = component.coachingNotes
      newValue['videoURL'] = ''
    }
    setFreestyleSong(newValue)
  }

  const saveHandler = async updated => {
    if (updated.id !== '_new_') {
      await freestyleSongService.updateFreestyleSong(updated)
    } else {
      updated.id = null
      await freestyleSongService.createFreestyleSong(updated)
    }
    history.goBack()
  }

  const styles = useStyles()

  if (freestyleSong !== null) {
    const pageContent = {
      pageTitle: 'Edit Song:',
      introText: '',
    }
    // console.log('FreestyleSongEdit', freestyleSong)
    return (
      <Page.WithTitle pageContent={pageContent}>
        {freestyleSong.length !== 0 ? (
          <Card key={freestyleSong.id} className={styles.card}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-song"
                    label="Song Title"
                    value={freestyleSong.song}
                    onChange={handleChange('song')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="outlined-required-artist"
                    label="Artist"
                    value={freestyleSong.artist}
                    onChange={handleChange('artist')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SimpleSelect
                    fullWidth
                    required
                    label="Action Component"
                    options={actionComponents}
                    optionValue="id"
                    optionLabel="name"
                    selectedValue={freestyleSong.freestyleSongActionComponentId}
                    variant="outlined"
                    onChangeHandler={handleChange(
                      'freestyleSongActionComponentId',
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  RPM:{' '}
                  {RPMRange(
                    freestyleSong.actionComponent.rpm_low,
                    freestyleSong.actionComponent.rpm_high,
                  )}
                  , PR: {freestyleSong.actionComponent.pr_range}, Release:{' '}
                  {freestyleSong.actionComponent.level}, <br />
                  {freestyleSong.actionComponent.coachingNotes}
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  key={freestyleSong.freestyleSongActionComponentId + 'simple'}
                >
                  <TextField
                    fullWidth
                    multiline
                    rowsMax="4"
                    id="outlined-required-coaching_notes_simple"
                    label="Simple Coaching Notes"
                    value={freestyleSong.coaching_notes_simple}
                    onChange={handleChange('coaching_notes_simple')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  key={freestyleSong.freestyleSongActionComponentId + 'full'}
                >
                  <TextField
                    fullWidth
                    multiline
                    rowsMax="4"
                    id="outlined-required-coaching_notes"
                    label="Coaching Notes"
                    value={freestyleSong.coaching_notes}
                    onChange={handleChange('coaching_notes')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-rpm_a"
                    label="RPM A"
                    value={freestyleSong.rpm_a}
                    onChange={handleChange('rpm_a')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                    helperText={SuggestRPM(freestyleSong.actionComponent)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    id="outlined-required-rpm_b"
                    label="RPM B"
                    value={freestyleSong.rpm_b}
                    onChange={handleChange('rpm_b')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                    helperText="Optional. Use when instructing 2 RPMs"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-pr_a"
                    label="Personal Resistance A"
                    value={freestyleSong.pr_a}
                    onChange={handleChange('pr_a')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                    helperText={SuggestPR(freestyleSong.actionComponent)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    id="outlined-required-pr_b"
                    label="Personal Resistance B"
                    value={freestyleSong.pr_b}
                    onChange={handleChange('pr_b')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                    helperText="Optional. Use when instructing 2 Resistance levels"
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    multiline
                    rowsMax="4"
                    id="outlined-required-videoURL"
                    label="video URL"
                    value={freestyleSong.videoURL}
                    onChange={handleChange('videoURL')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    id="outlined-required-sortOrder"
                    label="Song Order"
                    value={freestyleSong.sortOrder}
                    onChange={handleChange('sortOrder')}
                    className={styles.textField}
                    margin="normal"
                    variant="outlined"
                    helperText="This is the sorting order of songs for this class layout"
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3}>
								<SimpleSelect fullWidth label="Active" options={[{value:true,label:"Yes"},{value:false, label:"No"}]} selectedValue={song.active} variant="outlined" onChangeHandler={handleChange('active')} />
							</Grid> */}
              </Grid>
            </CardContent>

            <CardActions>
              <NavLink to={`/freestyle/class/${classID}/songs`}>
                <Button size="small">Cancel</Button>
              </NavLink>
              <Button
                onClick={() => {
                  saveHandler(freestyleSong)
                }}
                size="small"
                color="primary"
              >
                Save
              </Button>
            </CardActions>
          </Card>
        ) : (
          <div>Song Not Found</div>
        )}
      </Page.WithTitle>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(SongEdit)

const SuggestRPM = actionComponent => {
  const range = RPMRange(actionComponent.rpm_low, actionComponent.rpm_high)
  if (range !== null) {
    return 'Suggested ' + range
  } else {
    return ''
  }
}
const SuggestPR = actionComponent => {
  const pr = actionComponent.pr_range
  if (pr !== null && pr !== undefined) {
    return 'Suggested ' + pr
  } else {
    return ''
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    minWidth: 275,
    marginBottom: 5,
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
