import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import AppCuratedClassService from '../../../../../../services/AppCuratedClassService'
import { FetchAllCuratedClasssByLevel } from '../../../hooks/useCuratedClassService'
import { CURATED_CLASS_TYPE } from '../../../../../../entities/CuratedClassEntity'
import { Page } from '../../../components/molecules/Page'
import PageLoader from '../../../components/molecules/PageLoader'
import User from '../../../../../../entities/User'
import { UserIsApproved } from '../../../components/molecules/UserIsApproved'
import { useDataRefresh } from '../../../hooks/useDataRefresh'
import { FilterBar } from '../../../components/atoms/FilterBar'
import { ClassTable } from '../ClassTable'
import { Spacing } from '../../../components/atoms/Spacing'
import { LinkButton } from '../../../components/atoms/LinkButton'
import { BlankClassLayoutButtonLink } from '../../../components/molecules/BlankClassLayoutButtonLink'

const CuratedPage = ({ activeUser, pageContent }) => {
  const [isLoading, setIsLoading] = useState(true)
  const defaultSortBy = ['layoutLevel', 'group', 'sortOrder']
  const [sortBy] = useState(defaultSortBy)
  const [classRows, setClassRows] = useState()
  const [refresh] = useDataRefresh(0) // setRefresh
  const classType = CURATED_CLASS_TYPE
  const maxLevel = activeUser.currentClassRelease
    ? activeUser.currentClassRelease
    : 0

  const curatedClassService = new AppCuratedClassService()
  const fetchedData = FetchAllCuratedClasssByLevel(
    maxLevel,
    classType,
    sortBy,
    curatedClassService,
    refresh,
  )

  const onFilterChange = event => {
    const filter = event.target.value
    if (fetchedData.length) {
      const filteredData = ClassRowFilter(fetchedData, filter)
      setClassRows(filteredData)
    }
  }

  useEffect(() => {
    if (isLoading && fetchedData) {
      setClassRows(fetchedData)
      setIsLoading(false)
    }
  }, [isLoading, fetchedData, setIsLoading])

  // console.log('CuratedPage: ', { activeUser, fetchedData })
  if (isLoading) {
    return <PageLoader />
  }

  if (classRows !== undefined && classRows !== null) {
    return (
      <Page.WithIntro pageContent={pageContent}>
        <BlankClassLayoutButtonLink />

        <UserIsApproved activeUser={activeUser}>
          {activeUser.isAdminUser() ? (
            <Spacing p={1}>
              <RenderAddButton {...{ activeUser, classType }} />
            </Spacing>
          ) : null}
          <FilterBar onChange={onFilterChange} title="Filter Curated Layouts" />
          {classRows.length ? (
            <ClassTable {...{ activeUser, classRows, classType, styles }} />
          ) : (
            <div>There are no classes found</div>
          )}
        </UserIsApproved>
      </Page.WithIntro>
    )
  } else {
    return <PageLoader />
  }
}

export default CuratedPage
CuratedPage.propTypes = {
  activeUser: PropTypes.instanceOf(User),
  pageContent: PropTypes.object,
}

const RenderAddButton = ({ classType }) => {
  return (
    <LinkButton to={`/${classType}/edit/_new_`}>Create New Layout</LinkButton>
  )
}

const ClassRowFilter = (data, filter) => {
  const filtered = data.filter(row => {
    const haystack = row.title + row.owner + row.description + ''
    const isMatch = haystack
      .toLocaleLowerCase()
      .includes(filter.toLocaleLowerCase())
    return isMatch
  })
  return filtered
}

const styles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginTop: theme.spacing(3),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(2),
  },
  headline: {
    padding: 6,
  },
  table: {
    minWidth: 650,
  },
}))
