import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import AppCuratedSongService from '../../../../../../services/AppCuratedSongService'
import PageLoader from '../../../components/molecules/PageLoader'
import { Page } from '../../../components/molecules/Page'
import AlertConfirmation from '../../../components/molecules/AlertConfirmation'
import { CURATED_CLASS_TYPE } from '../../../../../../entities/CuratedClassEntity'
import { FREESTYLE_CLASS_TYPE } from '../../../../../../entities/FreestyleClassEntity'

const CuratedSongDelete = ({ activeUser, match, history }) => {
  const { songID, classID } = match.params
  const [curatedSong, setCuratedSong] = React.useState(null)
  const classType = CURATED_CLASS_TYPE
  const pageContent = {
    pageTitle: 'Delete Curated Song:',
    introText: '',
  }

  const curatedSongService = new AppCuratedSongService()

  useEffect(() => {
    ;(async songID => {
      const data = await curatedSongService.fetchCuratedSong(songID)
      // console.log('CuratedSongDelete: data', data)
      setCuratedSong(data)
    })(songID)
    // console.log('CuratedSongDelete: data',curatedSong)
  }, [songID, classID, curatedSongService])

  const deleteHandler = async songID => {
    await curatedSongService.deleteCuratedSong(songID)
    history.push(`/${classType}/class/${classID}/songs`)
  }

  const cancelHandler = () => {
    history.push(`/${classType}/class/${classID}/songs`)
  }

  if (curatedSong !== null) {
    if (curatedSong.length === 0) {
      history.push(`/${classType}/class/${classID}/songs`) // Not Found
      return null
    }

    let canDelete = false
    if (
      curatedSong.curatedClass.type === CURATED_CLASS_TYPE &&
      activeUser.isAdminUser()
    ) {
      canDelete = true
    }
    if (curatedSong.type === FREESTYLE_CLASS_TYPE) {
      if (activeUser.authUsername === curatedSong.owner) {
        canDelete = true
      }
    }

    if (canDelete) {
      return (
        <Page.WithTitle pageContent={pageContent}>
          <RenderDeleteConfirmButton
            {...{
              song: curatedSong,
              classType,
              classID,
              onConfirm: deleteHandler,
              onCancel: cancelHandler,
            }}
          />
        </Page.WithTitle>
      )
    }
    return (
      <Page.WithTitle pageContent={pageContent}>
        You do not have permission to delete this song.
      </Page.WithTitle>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(CuratedSongDelete)

const RenderDeleteConfirmButton = ({
  song,
  classType,
  classID,
  onConfirm,
  onCancel,
}) => {
  const confirmDeleteProps = {
    trigger: false,
    title: 'Are you sure?',
    content: `Deleting "${song.song}" by "${song.artist}" is permanent.`,
    cancelLabel: 'Cancel',
    onCancel: onCancel,
    confirmLabel: 'Delete',
    onConfirm: onConfirm.bind(this, song.id, classType, classID),
  }
  return <AlertConfirmation {...confirmDeleteProps} />
}
