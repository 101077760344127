import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import PageLoader from '../../components/molecules/PageLoader'
import AppUserService from '../../../../../services/AppUserService'
import { FetchAllUsers } from '../../hooks/useUserService'
import { useDataRefresh } from '../../hooks/useDataRefresh'
import { Page } from '../../components/molecules/Page'
import { Button } from '../../components/atoms/Button'
import { MasterReleaseCard } from './MasterReleaseCard'
import { UserStatusCard } from './UserStatusCard'

import User from '../../../../../entities/User'
import { Spacing } from '../../components/atoms/Spacing'

export const Tools = ({ activeUser, pageContent }) => {
  const defaultSortBy = ['lastName', 'firstName']
  const [sortBy] = useState(defaultSortBy)
  const [userRows, setUserRows] = useState()
  const [refresh, setRefresh] = useDataRefresh(0) // setRefresh

  const userService = new AppUserService()
  const allUsers = FetchAllUsers(sortBy, userService, refresh)
  useEffect(() => {
    if (allUsers !== undefined) {
      setUserRows(
        allUsers.filter(user => {
          return user.lastName !== 'ZTest'
        }),
      )
    }
  }, [allUsers, setUserRows, refresh])

  if (userRows === undefined) {
    return <PageLoader />
  }

  const reloadUsers = () => {
    setRefresh()
  }

  if (userRows !== undefined && userRows !== null) {
    return (
      <Page.WithIntro pageContent={pageContent}>
        <Spacing p={1}>
          <UserStatusCard {...{ userRows }} />
        </Spacing>
        <Spacing p={1}>
          <MasterReleaseCard {...{ userRows, userService, reloadUsers }} />
        </Spacing>
      </Page.WithIntro>
    )
  } else {
    return <div>No Users Found</div>
  }
}
Tools.propTypes = {
  activeUser: PropTypes.instanceOf(User),
  pageContent: PropTypes.object,
}
