export default class FreestyleSongService {
  constructor(repository, adapter) {
    this._repository = repository
    this._adapter = adapter
  }

  createFreestyleSong(entity) {
    return this._repository.createEntity(entity)
  }

  fetchFreestyleSong(id) {
    return this._repository.fetchEntity(id)
  }

  updateFreestyleSong(entity) {
    return this._repository.updateEntity(entity)
  }

  deleteFreestyleSong(id) {
    return this._repository.deleteEntity(id)
  }

  fetchAllFreestyleSongs(sortBy) {
    return this._repository.fetchAll(sortBy)
  }

  fetchAllFreestyleSongsByLevel(maxLevel, classType, sortBy) {
    return this._repository.fetchFreestyleSongsForLevel(
      maxLevel,
      classType,
      sortBy,
    )
  }

  addFreestyleSongEntity(entity) {
    this._repository.addEntity(entity)
    return this._repository.get()[0]
  }

  clear() {
    this._repository.clear()
  }
}
