import React from 'react'
import PropTypes from 'prop-types'

import { Link } from './Link'
import { Button } from './Button'

export const LinkButton = ({
  to,
  children,
  variant = 'outlined',
  color = 'default',
  size = 'small',
}) => {
  return (
    <Link {...{ to }}>
      <Button {...{ variant, color, size }}>{children}</Button>
    </Link>
  )
}

LinkButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
}
