import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody } from '@material-ui/core'

import User from '../../../../../entities/User'
import {
  ActionComponentRow,
  ActionComponentRowHeader,
} from './ActionComponentRow'

export const ActionComponentTable = ({
  activeUser,
  actionComponentRows,
  styles,
}) => {
  return (
    <Table className={styles.table} size="small">
      <ActionComponentRowHeader {...{ activeUser }} />
      <TableBody>
        {actionComponentRows.map(actionComponent => (
          <ActionComponentRow
            key={actionComponent.id}
            {...{ actionComponent, activeUser }}
          />
        ))}
      </TableBody>
    </Table>
  )
}
ActionComponentTable.propTypes = {
  activeUser: PropTypes.instanceOf(User),
  actionComponentRows: PropTypes.array,
  styles: PropTypes.func,
}
