import React from 'react'
// import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { TableHead, TableRow, TableCell } from '@material-ui/core'

import ActionComponentEntity from '../../../../../entities/ActionComponentEntity'
import User from '../../../../../entities/User'
import { RenderVideoButton } from '../../components/molecules/VideoLinkButton'
import { Link } from '../../components/atoms/Link'
import { Button } from '../../components/atoms/Button'
// import AlertConfirmation from '../../components/molecules/AlertConfirmation'

export const ActionComponentRowHeader = ({ activeUser, classType }) => {
  return (
    <TableHead>
      <TableRow>
        {activeUser.isAdminUser() ? <TableCell>Edit</TableCell> : null}
        <TableCell>Folder</TableCell>
        <TableCell>Name</TableCell>
        <TableCell align="center">Simple</TableCell>
        <TableCell align="center">RPM Range</TableCell>
        <TableCell align="center">PR Range</TableCell>
        <TableCell align="center">Coaching Notes</TableCell>
        <TableCell align="center">Release</TableCell>
        <TableCell align="center">Demo Video</TableCell>
      </TableRow>
    </TableHead>
  )
}

export const ActionComponentRow = ({ actionComponent, activeUser }) => {
  // , onDeletePage = () => {}
  return (
    <TableRow key={actionComponent.id}>
      {activeUser.isAdminUser() ? (
        <TableCell>
          {renderEditButton(actionComponent.id)}{' '}
          {/* <RenderDeleteButton item={actionComponent} /> */}
        </TableCell>
      ) : null}
      <TableCell component="th" scope="actionComponent">
        {actionComponent.folderLabel()}
      </TableCell>
      <TableCell>{actionComponent.name}</TableCell>
      <TableCell align="center">{actionComponent.simpleCoaching}</TableCell>
      <TableCell align="center">{renderRPMRange(actionComponent)}</TableCell>
      <TableCell align="center">
        {renderPRRange(actionComponent.pr_range)}
      </TableCell>
      <TableCell align="center">{actionComponent.coachingNotes}</TableCell>
      <TableCell align="center">{actionComponent.level}</TableCell>
      <TableCell align="center">
        <RenderVideoButton url={actionComponent.videoURL} />
      </TableCell>
    </TableRow>
  )
}
ActionComponentRow.propTypes = {
  actionComponent: PropTypes.instanceOf(ActionComponentEntity),
  activeUser: PropTypes.instanceOf(User),
  // onDeletePage: PropTypes.func,
}

const renderEditButton = id => {
  return (
    <Link to={`/components/edit/${id}`}>
      <Button>Edit</Button>
    </Link>
  )
}

const renderPRRange = pr => {
  if (parseInt(pr) === 0) {
    return ''
  }
  return pr
}

const renderRPMRange = row => {
  if (!row) {
    return ''
  }
  if (row.rpm_low === 0 && row.rpm_high === 0) {
    return ''
  }
  if (row.rpm_low !== 0 && row.rpm_high === 0) {
    return row.rpm_low
  }
  if (row.rpm_low !== 0 && row.rpm_high !== 0) {
    return row.rpm_low + ' - ' + row.rpm_high
  }
}

// const RenderDeleteButton = ({ item, onDeletePage }) => {
//   const confirmDelete = {
//     actionButtonLabel: 'Delete',
//     title: 'Are you sure?',
//     content: `Deleting the ${item.pageTitle} page is permanent.`,
//     cancelLabel: 'Cancel',
//     onCancel: () => {},
//     confirmLabel: 'Delete',
//     onConfirm: onDeletePage.bind(this, item.id),
//   }
//   return <AlertConfirmation {...confirmDelete} />
// }
