export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    authUsername
    email
    phoneNumber
    firstName
    lastName
    currentClassRelease
    active
    status
    subscriber
    certificationDate
    admin
    address
    address2
    city
    state
    zip
    agreeTerms
    currentlyInstructing
    instructingLocation
    notes
    lastLogin
    owner
  }
}
`

export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    authUsername
    email
    phoneNumber
    firstName
    lastName
    currentClassRelease
    active
    status
    subscriber
    certificationDate
    admin
    address
    address2
    city
    state
    zip
    agreeTerms
    currentlyInstructing
    instructingLocation
    notes
    lastLogin
    owner
  }
}
`

// export const setUserLoggedIn = `mutation SetUserLoggedIn($input: UpdateUserInput!) {
//   updateUser(input: $input) {
//     id
//     lastLogin
//   }
// }
// `;
