import React from 'react'
import PropTypes from 'prop-types'
import {
  TableCell,
  TableRow,
  Grid,
  Typography,
  TableHead,
} from '@material-ui/core'

import User from '../../../../../../entities/User'
import CuratedSongEntity from '../../../../../../entities/CuratedSongEntity'
import FreestyleSongEntity from '../../../../../../entities/FreestyleSongEntity'
import { FREESTYLE_CLASS_TYPE } from '../../../../../../entities/FreestyleClassEntity'
import { RenderPR } from '../../../components/atoms/RenderPR'
import { RPMRange } from '../../../components/atoms/RPMRange'
import { RenderVideoButton } from '../../../components/molecules/VideoLinkButton'
import { LinkButton } from '../../../components/atoms/LinkButton'

export const SongRowHeader = ({
  songDisplayMode,
  sharedFreestyle,
  activeUser,
}) => {
  switch (songDisplayMode) {
    case 'class':
      return null
    default:
      return (
        <TableHead>
          <TableRow>
            {sharedFreestyle ? null : <TableCell>Edit</TableCell>}
            <TableCell>#</TableCell>
            <TableCell>Song/Artist</TableCell>
            <TableCell align="center">RPM Range</TableCell>
            <TableCell align="center">RPM</TableCell>
            <TableCell align="center">PR Range</TableCell>
            <TableCell align="center">PR</TableCell>
            <TableCell align="center">Simple Coaching Notes</TableCell>
            <TableCell align="center">Coaching Notes</TableCell>
            <TableCell>Demo Video</TableCell>
          </TableRow>
        </TableHead>
      )
  }
}
SongRowHeader.propTypes = {
  songDisplayMode: PropTypes.string,
  sharedFreestyle: PropTypes.bool,
  activeUser: PropTypes.instanceOf(User),
  classFullNotes: PropTypes.bool,
}

export const SongRow = ({
  song,
  classID,
  classType = 'curated',
  songDisplayMode,
  sharedFreestyle,
  activeUser,
  classFullNotes,
}) => {
  // console.log('SongRow', song.song, classType)
  if (songDisplayMode === 'class') {
    return (
      <TableRow key={song.id}>
        <TableCell component="th" scope="row">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={12} lg={12}>
              <Typography variant="h6">
                {song.song} - {song.artist}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="h6">
                {song.actionComponent !== null
                  ? song.actionComponent.name
                  : null}
                <br />
                PR: {RenderPR(song.pr_a, song.pr_b)}
                <br />
                RPM: {RPMRange(song.rpm_a, song.rpm_b, ' then ')}
                <br />
                {song.coaching_notes_simple}
              </Typography>
            </Grid>
            {classFullNotes ? (
              <Grid item xs={12} sm={6} lg={9}>
                <Typography variant="h6">{song.coaching_notes}</Typography>
              </Grid>
            ) : null}
          </Grid>
        </TableCell>
      </TableRow>
    )
  } else {
    return (
      <TableRow key={song.song}>
        {canEdit(song.owner, classType, sharedFreestyle, activeUser) ? (
          <RenderSongActions {...{ song, classID, classType }} />
        ) : (
          <TableCell />
        )}
        <TableCell align="center" style={{ verticalAlign: 'top' }}>
          {song.sortOrder}
        </TableCell>
        <TableCell align="center" style={{ verticalAlign: 'top' }}>
          {song.song}
          <br />
          {song.artist}
        </TableCell>
        <TableCell align="center" style={{ verticalAlign: 'top' }}>
          {song.actionComponent !== null
            ? RPMRange(
                song.actionComponent.rpm_low,
                song.actionComponent.rpm_high,
              )
            : 'N/A'}
        </TableCell>
        <TableCell align="center" style={{ verticalAlign: 'top' }}>
          {RPMRange(song.rpm_a, song.rpm_b, ' then ')}
        </TableCell>

        <TableCell align="center" style={{ verticalAlign: 'top' }}>
          {song.actionComponent !== null
            ? RenderPR(song.actionComponent.pr_range, 0)
            : 'N/A'}
        </TableCell>
        <TableCell align="center" style={{ verticalAlign: 'top' }}>
          {RenderPR(song.pr_a, song.pr_b)}
        </TableCell>
        <TableCell align="center" style={{ verticalAlign: 'top' }}>
          {song.coaching_notes_simple}
        </TableCell>
        <TableCell align="center" style={{ verticalAlign: 'top' }}>
          {song.coaching_notes}
        </TableCell>
        <TableCell scope="row" style={{ verticalAlign: 'top' }}>
          <RenderVideoButton url={song.videoURL} />
        </TableCell>
      </TableRow>
    )
  }
}
SongRow.propTypes = {
  song: PropTypes.oneOfType([
    PropTypes.instanceOf(CuratedSongEntity),
    PropTypes.instanceOf(FreestyleSongEntity),
  ]),
  classId: PropTypes.string,
  classType: PropTypes.string,
  songDisplayMode: PropTypes.string,
  sharedFreestyle: PropTypes.bool,
  activeUser: PropTypes.instanceOf(User),
}

const RenderSongActions = ({ song, classID, classType }) => {
  // /curated/class/:classLayoutId/songs/:songID/edit
  return (
    <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
      <LinkButton to={`/${classType}/class/${classID}/songs/${song.id}/edit`}>
        Edit
      </LinkButton>{' '}
      <LinkButton
        to={`/${classType}/class/${classID}/songs/${song.id}/delete`}
        color="secondary"
      >
        Delete
      </LinkButton>
    </TableCell>
  )
}

const canEdit = (owner, classType, sharedFreestyle, activeUser) => {
  if (activeUser.isAdminUser()) {
    return true
  }
  if (sharedFreestyle) {
    return false
  }
  if (classType === FREESTYLE_CLASS_TYPE && owner === activeUser.authUsername) {
    return true
  }
  return false
}
