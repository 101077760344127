import CuratedSongGraphQLAdapter from '../adapters/drivers/CuratedSongGraphQLAdapter'
import CuratedSongRepository from '../adapters/repositories/CuratedSongRepository'
import CuratedSongService from './CuratedSongService'
import ClassDecorator from './ClassDecorator'

class AppCuratedSongService {
  constructor() {
    this._initializeService()
    return this._curatedClassService
  }

  _initializeService() {
    this._adapter = new CuratedSongGraphQLAdapter()
    this._curatedClassRepository = new CuratedSongRepository(this._adapter)
    this._curatedClassService = new CuratedSongService(
      this._curatedClassRepository,
      this._adapter,
    )
  }
}
export default ClassDecorator.makeSingleton(AppCuratedSongService)
