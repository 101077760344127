import { useState, useEffect } from 'react'

export const FetchFreestyleClass = (
  freestyleClassId = '',
  freestyleClassService = {},
) => {
  const [freestyleClass, setFreestyleClass] = useState()

  useEffect(() => {
    if (
      freestyleClassId &&
      freestyleClassService &&
      freestyleClassService.fetchEntity
    ) {
      freestyleClassService
        .fetchEntity(freestyleClassId)
        .catch(() => [])
        .then(data => {
          setFreestyleClass(data)
        })
    }
  }, [freestyleClassId, freestyleClassService, setFreestyleClass])

  return freestyleClass
}

export const FetchFreestyleClassWithSongs = (
  freestyleClassId = '',
  freestyleClassService = {},
  sortBy = [],
) => {
  const [freestyleClass, setFreestyleClass] = useState()

  useEffect(() => {
    if (
      freestyleClassId &&
      freestyleClassService &&
      freestyleClassService.fetchFreestyleClassWithSongs
    ) {
      freestyleClassService
        .fetchFreestyleClassWithSongs(freestyleClassId, sortBy)
        .catch(() => [])
        .then(data => {
          setFreestyleClass(data)
        })
    }
  }, [freestyleClassId, sortBy, freestyleClassService, setFreestyleClass])

  return freestyleClass
}

export const FetchAllFreestyleClasses = (
  sortBy,
  classType,
  maxLevel,
  owner,
  freestyleClassService = {},
  refresh,
) => {
  const [freestyleClasses, setFreestyleClasses] = useState()
  useEffect(() => {
    if (
      sortBy &&
      freestyleClassService &&
      freestyleClassService.fetchFreestyleClasses
    ) {
      freestyleClassService
        .fetchFreestyleClasses(classType, maxLevel, sortBy)
        .catch(() => [])
        .then(data => {
          setFreestyleClasses(data)
        })
    }
  }, [
    sortBy,
    classType,
    maxLevel,
    owner,
    freestyleClassService,
    freestyleClassService.fetchFreestyleClasses,
    setFreestyleClasses,
  ])
  return freestyleClasses
}

export const FetchAllFreestyleClasssForOwner = (
  owner,
  sortBy,
  freestyleClassService = {},
  refresh,
) => {
  const [freestyleClasses, setFreestyleClasses] = useState()
  useEffect(() => {
    if (
      owner &&
      sortBy &&
      freestyleClassService &&
      freestyleClassService.fetchAllFreestyleClasssByLevel
    ) {
      freestyleClassService.clear()
      freestyleClassService
        .fetchAllFreestyleClasssForOwner(owner, sortBy)
        .catch(() => [])
        .then(data => {
          setFreestyleClasses(data)
        })
    }
  }, [
    owner,
    sortBy,
    freestyleClassService,
    freestyleClassService.fetchFreestyleClasssForOwner,
    setFreestyleClasses,
  ])
  return freestyleClasses
}

export const FetchAllFreestyleClasssByLevel = (
  maxLevel,
  sortBy,
  freestyleClassService = {},
  refresh,
) => {
  const [freestyleClasses, setFreestyleClasses] = useState()
  useEffect(() => {
    if (
      sortBy &&
      freestyleClassService &&
      freestyleClassService.fetchAllFreestyleClasssByLevel
    ) {
      freestyleClassService.clear()
      freestyleClassService
        .fetchAllFreestyleClasssByLevel(maxLevel, sortBy)
        .catch(() => [])
        .then(data => {
          setFreestyleClasses(data)
        })
    }
  }, [
    maxLevel,
    sortBy,
    freestyleClassService,
    freestyleClassService.fetchFreestyleClasssForLevel,
    setFreestyleClasses,
  ])
  return freestyleClasses
}
