import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core'

import User, {
  USER_NEW_SIGNUP,
  USER_WELCOME,
  USER_STARTED,
  USER_COMPLETE,
  USER_ACTIVE,
  USER_PAUSED,
} from '../../../../../entities/User'

const initUserStats = {
  newSignup: 0,
  welcome: 0,
  started: 0,
  complete: 0,
  activeStatus: 0,
  paused: 0,
  subscribed: 0,
  admin: 0,
  active: 0,
  levelBehind: 0,
}

export const UserStatusCard = ({ userRows }) => {
  const classes = useStyles()
  const userStats = ComputeUserStats(userRows)

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Instructor Status"
        subheader={`Total Instructors: ${userRows.length}, Active Instructors: ${userStats.active}`}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item xs={6} md={2} spacing={1}>
            <StatusCard title="Signup" count={userStats.newSignup} />
          </Grid>
          <Grid container item xs={6} md={2} spacing={1}>
            <StatusCard title="Welcome" count={userStats.welcome} />
          </Grid>
          <Grid container item xs={6} md={2} spacing={1}>
            <StatusCard title="Started" count={userStats.started} />
          </Grid>
          <Grid container item xs={6} md={2} spacing={1}>
            <StatusCard title="Complete" count={userStats.complete} />
          </Grid>
          <Grid container item xs={6} md={2} spacing={1}>
            <StatusCard title="Active" count={userStats.activeStatus} />
          </Grid>
          <Grid container item xs={6} md={2} spacing={1}>
            <StatusCard title="Paused" count={userStats.paused} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
UserStatusCard.propTypes = {
  userRows: PropTypes.arrayOf(PropTypes.instanceOf(User)),
}

const StatusCard = ({ title, count }) => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography className={classes.count} variant="body2" component="p">
          {count}
        </Typography>
      </CardContent>
    </Card>
  )
}

const ComputeUserStats = userRows => {
  if (userRows !== undefined && userRows.length) {
    let newStats = { ...initUserStats }
    userRows.forEach(user => {
      newStats.active = user.active ? newStats.active + 1 : newStats.active
      newStats.newSignup =
        user.status === USER_NEW_SIGNUP
          ? newStats.newSignup + 1
          : newStats.newSignup
      newStats.welcome =
        user.status === USER_WELCOME ? newStats.welcome + 1 : newStats.welcome
      newStats.started =
        user.status === USER_STARTED ? newStats.started + 1 : newStats.started
      newStats.complete =
        user.status === USER_COMPLETE
          ? newStats.complete + 1
          : newStats.complete
      newStats.activeStatus =
        user.status === USER_ACTIVE
          ? newStats.activeStatus + 1
          : newStats.activeStatus
      newStats.paused =
        user.status === USER_PAUSED ? newStats.paused + 1 : newStats.paused
    })
    return newStats
  }
  return null
}

const useStyles = makeStyles({
  card: { minWidth: 90 },
  title: { fontSize: 16 },
  pos: { marginBottom: 12 },
  count: { textAlign: 'center' },
})
