import PageEntity from '../../entities/PageEntity'
import Repository from './Repository'

export default class PageRepository extends Repository {
  constructor(adapter) {
    super()
    this._adapter = adapter
  }

  addEntity(data) {
    this.add(new PageEntity(data))
  }

  _mapFetchedDataToEntity(fetchedData) {
    if (fetchedData === [] || fetchedData === undefined) {
      return
    }
    if (Array.isArray(fetchedData)) {
      fetchedData.forEach(data => this.add(new PageEntity(data)))
    } else {
      this.add(new PageEntity(fetchedData))
    }
    return
  }
  // _mapperIn(data = {}) {
  // 	const mappedData = { id: data.id, title: data.title, description: data.description,
  // 		status: data.status, level: data.level, playListURL: data.playListURL}
  // 	const masterClass = new MasterClass(mappedData)
  // 	this._data.push(masterClass)
  // }
}
