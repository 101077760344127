import React from 'react'
import { Typography } from '@material-ui/core'
import { Page } from '../clean/adapters/presenters/react/components/molecules/Page'

const ProfilePage = ({ activeUser, pageContent }) => {
  // console.log('ProfilePage',props)
  // const styles = {
  //   Paper: {
  //     padding: 20,
  //     minHeight: 200,
  //     marginTop: 10,
  //     marginBottom: 10,
  //   },
  // }

  return (
    <Page.WithIntro pageContent={pageContent}>
      <Typography variant="">Username: {activeUser.authUsername}</Typography>
      <br />
      <Typography variant="">
        Name: {activeUser.firstName} {activeUser.lastName}
      </Typography>
      <br />
      <Typography variant="">Email: {activeUser.email}</Typography>
      <br />
      <Typography variant="">Status: {activeUser.statusLabel()}</Typography>
      <br />
      {/* <Typography variant="">
        {process.env.NODE_ENV} {process.env.REACT_APP_ENV}
      </Typography>
      <br /> */}
    </Page.WithIntro>
  )
}

export default ProfilePage
