import React from 'react'
import PropTypes from 'prop-types'
import { Button as MUIButton } from '@material-ui/core'

export const Button = ({
  variant = 'outlined',
  color = 'default',
  size = 'small',
  children,
  onClick,
}) => {
  return (
    <MUIButton {...{ size, color, variant, onClick }}>{children}</MUIButton>
  )
}
Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
}
