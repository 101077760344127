import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody } from '@material-ui/core'

import User from '../../../../../entities/User'
import { UserRow, UserRowHeader } from './UserRow'

export const UserTable = ({ users, activeUser, styles }) => {
  return (
    <Table className={styles.table} size="small" stickyHeader={true}>
      <UserRowHeader />
      <TableBody>
        {users.map(user => (
          <UserRow key={user.id} {...{ user, activeUser }} />
        ))}
      </TableBody>
    </Table>
  )
}
UserTable.propTypes = {
  users: PropTypes.array,
  activeUser: PropTypes.instanceOf(User),
  styles: PropTypes.func,
}
