import CuratedClassGraphQLAdapter from '../adapters/drivers/CuratedClassGraphQLAdapter'
import CuratedClassRepository from '../adapters/repositories/CuratedClassRepository'
import CuratedClassService from './CuratedClassService'
import ClassDecorator from './ClassDecorator'

class AppCuratedClassService {
  constructor() {
    this._initializeService()
    return this._curatedClassService
  }

  _initializeService() {
    this._adapter = new CuratedClassGraphQLAdapter()
    this._curatedClassRepository = new CuratedClassRepository(this._adapter)
    this._curatedClassService = new CuratedClassService(
      this._curatedClassRepository,
      this._adapter,
    )
  }
}
export default ClassDecorator.makeSingleton(AppCuratedClassService)
