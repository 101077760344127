import React from 'react'
import PropTypes from 'prop-types'
import { Select, FormControl, InputLabel, MenuItem } from '@material-ui/core'

const SimpleSelect = ({
  label = 'Select Option',
  options,
  optionValue = 'value',
  optionLabel = 'label',
  selectedValue,
  emptyLabel,
  variant = 'outlined',
  styles = { formControl: { minWidth: 120, verticalAlign: 0 } },
  onChangeHandler,
  fullWidth = false,
}) => {
  // emptyLabel = emptyLabel !== undefined ? emptyLabel : ''
  // styles = styles !== undefined ? styles : {formControl: {minWidth: 240}}

  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant}
      style={{ marginTop: '16px' }}
    >
      <InputLabel htmlFor={`${label}-simple`}>{label}</InputLabel>
      <Select value={selectedValue} onChange={onChangeHandler}>
        {emptyLabel !== undefined ? (
          <MenuItem key={0} value="">
            <em>{emptyLabel}</em>
          </MenuItem>
        ) : null}
        {options.map((option, index) => {
          let key = option[optionValue] ? option[optionValue] : index
          return (
            <MenuItem key={key} value={option[optionValue]}>
              {option[optionLabel]}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default SimpleSelect

SimpleSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  optionValue: PropTypes.string,
  optionLabel: PropTypes.string,
  selectedValue: PropTypes.any.isRequired,
  emptyLabel: PropTypes.string,
  variant: PropTypes.string,
  styles: PropTypes.object,
  clickHandler: PropTypes.func,
}
