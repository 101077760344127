export default class FreestyleClassService {
  constructor(repository, adapter) {
    this._repository = repository
    this._adapter = adapter
  }

  createFreestyleClass(entity) {
    return this._repository.createEntity(entity)
  }

  fetchFreestyleClass(id) {
    return this._repository.fetchEntity(id)
  }

  fetchFreestyleClassWithSongs(id, maxLevel, sortBy) {
    return this._repository.fetchEntityWithSongs(id, maxLevel, sortBy)
  }

  updateFreestyleClass(entity) {
    return this._repository.updateEntity(entity)
  }

  deleteFreestyleClass(id) {
    return this._repository.deleteEntity(id)
  }

  fetchAllFreestyleClasses(sortBy) {
    return this._repository.fetchAll(sortBy)
  }

  fetchAllFreestyleClasssForOwner(owner, sortBy) {
    return this._repository.fetchFreestyleClasssForOwner(owner, sortBy)
  }

  fetchAllFreestyleClasssByLevel(maxLevel, sortBy) {
    return this._repository.fetchFreestyleClasssForLevel(maxLevel, sortBy)
  }

  addFreestyleClassEntity(entity) {
    this._repository.addEntity(entity)
    return this._repository.get()[0]
  }

  clear() {
    this._repository.clear()
  }
}
