/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    authUsername
    email
    phoneNumber
    firstName
    lastName
    currentClassRelease
    lastReleaseDate
    active
    status
    subscriber
    certificationDate
    admin
    address
    address2
    city
    state
    zip
    agreeTerms
    currentlyInstructing
    instructingLocation
    notes
    lastLogin
    actionComponents {
      nextToken
    }
    curatedClasses {
      nextToken
    }
    freestyleClasses {
      nextToken
    }
    owner
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    nextToken
  }
}
`;
export const getPage = `query GetPage($id: ID!) {
  getPage(id: $id) {
    id
    pageTitle
    linkPath
    menuSection
    linkText
    linkIcon
    introText
    pageContent
    videoLink
    videoLabel
    buttonLink
    buttonLabel
    sortBy
    includeNavigationMenu
    includeFooter
    adminOnly
    owner
  }
}
`;
export const listPages = `query ListPages(
  $filter: ModelPageFilterInput
  $limit: Int
  $nextToken: String
) {
  listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      pageTitle
      linkPath
      menuSection
      linkText
      linkIcon
      introText
      pageContent
      videoLink
      videoLabel
      buttonLink
      buttonLabel
      sortBy
      includeNavigationMenu
      includeFooter
      adminOnly
      owner
    }
    nextToken
  }
}
`;
export const getCuratedClass = `query GetCuratedClass($id: ID!) {
  getCuratedClass(id: $id) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    classSongs {
      nextToken
    }
    owner
  }
}
`;
export const listCuratedClasss = `query ListCuratedClasss(
  $filter: ModelCuratedClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listCuratedClasss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    nextToken
  }
}
`;
export const getClassSong = `query GetClassSong($id: ID!) {
  getClassSong(id: $id) {
    id
    classId
    song
    artist
    rpm_a
    rpm_b
    pr_a
    pr_b
    coaching_notes
    coaching_notes_simple
    sortOrder
    videoURL
    curatedClass {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    actionComponent {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    owner
  }
}
`;
export const listClassSongs = `query ListClassSongs(
  $filter: ModelClassSongFilterInput
  $limit: Int
  $nextToken: String
) {
  listClassSongs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      classId
      song
      artist
      rpm_a
      rpm_b
      pr_a
      pr_b
      coaching_notes
      coaching_notes_simple
      sortOrder
      videoURL
      owner
    }
    nextToken
  }
}
`;
export const getFreestyleClass = `query GetFreestyleClass($id: ID!) {
  getFreestyleClass(id: $id) {
    id
    group
    title
    description
    active
    type
    shared
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    freestyleSongs {
      nextToken
    }
    owner
  }
}
`;
export const listFreestyleClasss = `query ListFreestyleClasss(
  $filter: ModelFreestyleClassFilterInput
  $limit: Int
  $nextToken: String
) {
  listFreestyleClasss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    nextToken
  }
}
`;
export const getFreestyleSong = `query GetFreestyleSong($id: ID!) {
  getFreestyleSong(id: $id) {
    id
    classId
    song
    artist
    rpm_a
    rpm_b
    pr_a
    pr_b
    coaching_notes
    coaching_notes_simple
    sortOrder
    videoURL
    freestyleClass {
      id
      group
      title
      description
      active
      type
      shared
      layoutLevel
      classPDFLink
      playlistURL
      sortOrder
      owner
    }
    actionComponent {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    owner
  }
}
`;
export const listFreestyleSongs = `query ListFreestyleSongs(
  $filter: ModelFreestyleSongFilterInput
  $limit: Int
  $nextToken: String
) {
  listFreestyleSongs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      classId
      song
      artist
      rpm_a
      rpm_b
      pr_a
      pr_b
      coaching_notes
      coaching_notes_simple
      sortOrder
      videoURL
      owner
    }
    nextToken
  }
}
`;
export const getActionComponent = `query GetActionComponent($id: ID!) {
  getActionComponent(id: $id) {
    id
    name
    simpleCoaching
    sortBy
    folder
    rpm_low
    rpm_high
    pr_range
    coachingNotes
    level
    videoURL
    createdByUser {
      id
      authUsername
      email
      phoneNumber
      firstName
      lastName
      currentClassRelease
      lastReleaseDate
      active
      status
      subscriber
      certificationDate
      admin
      address
      address2
      city
      state
      zip
      agreeTerms
      currentlyInstructing
      instructingLocation
      notes
      lastLogin
      owner
    }
    classSongs {
      nextToken
    }
    freestyleSongs {
      nextToken
    }
    active
    owner
  }
}
`;
export const listActionComponents = `query ListActionComponents(
  $filter: ModelActionComponentFilterInput
  $limit: Int
  $nextToken: String
) {
  listActionComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      simpleCoaching
      sortBy
      folder
      rpm_low
      rpm_high
      pr_range
      coachingNotes
      level
      videoURL
      active
      owner
    }
    nextToken
  }
}
`;
export const getDbErrorLog = `query GetDbErrorLog($id: ID!) {
  getDBErrorLog(id: $id) {
    id
    error
  }
}
`;
export const listDbErrorLogs = `query ListDbErrorLogs(
  $filter: ModelDBErrorLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listDBErrorLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      error
    }
    nextToken
  }
}
`;
