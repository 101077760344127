import Entity from './Entity'
import FreestyleSongEntity from './FreestyleSongEntity'

export const FREESTYLE_CLASS_TYPE = 'freestyle'

export default class FreestyleClassEntity extends Entity {
  id
  group
  title
  description
  active
  type
  shared
  layoutLevel
  classPDFLink
  playlistURL
  sortOrder
  createdByUser

  freestyleSongs

  constructor(initialValues = {}) {
    if (initialValues === null) {
      initialValues = {}
    }
    let _defaults = { group: 1, active: true }
    super({ ..._defaults, ...initialValues })

    if ('freestyleSongs' in initialValues) {
      const { freestyleSongs } = initialValues
      // console.log('FreestyleClassEntity:', freestyleSongs)
      if ('items' in freestyleSongs) {
        this.freestyleSongs = freestyleSongs.items.map(
          data => new FreestyleSongEntity(data),
        )
      }
    }
  }

  groupOptions() {
    return [
      { key: 0, value: '' },
      { key: 1, value: 'Foundational' },
      { key: 2, value: 'Building on Athleticism/Conditioning' },
      { key: 3, value: 'Adding 7 New Components/Upper Body and Layering' },
      { key: 4, value: 'Guided Freestyle Master Examples' },
    ]
  }

  groupLabel() {
    let options = this.groupOptions()
    if (this.group !== undefined && this.group > 0) {
      return options[this.group].value
    } else {
      return 'Not Grouped'
    }
  }
}

export const FREESTYLE_CLASS_ENTITY_NUMBER_LIST = [
  'group',
  'layoutLevel',
  'sortOrder',
]
export const FREESTYLE_CLASS_ENTITY_STRING_LIST = [
  'title',
  'description',
  'type',
  'classPDFLink',
  'playlistURL',
]
