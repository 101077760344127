export const createFreestyleClass = `mutation CreateFreestyleClass($input: CreateFreestyleClassInput!) {
  createFreestyleClass(input: $input) {
    id
    group
    title
    description
    active
    type
    layoutLevel
    classPDFLink
    playlistURL
    sortOrder
    createdByUser {
      id
      authUsername
      active
    }
    owner
  }
}
`

export const updateFreestyleClassWithoutSongs = `mutation UpdateFreestyleClassWithoutSongs($input: UpdateFreestyleClassInput!) {
  updateFreestyleClass(input: $input) {
    id
    group
    title
    description
    active
    layoutLevel
    classPDFLink
    playlistURL
    createdByUser {
      id
      authUsername
      active
    }
  }
}
`
