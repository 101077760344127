import React from 'react'
import { Paper, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  link: {
    margin: theme.spacing(3, 2),
  },
}))

const Footer = props => {
  const classes = useStyles()
  const copyrightYear = new Date().getFullYear()

  return (
    <Paper className={classes.root}>
      <Typography variant="body2">
        © 2019-{copyrightYear} BeatBoss Indoor Biking, LLC
      </Typography>
    </Paper>
  )
}

export default Footer
