import FreestyleClassEntity from '../../entities/FreestyleClassEntity'
import Repository from './Repository'

export default class FreestyleClassRepository extends Repository {
  constructor(adapter) {
    super()
    this._adapter = adapter
  }

  addEntity(entity) {
    this.add(new FreestyleClassEntity(entity))
  }

  async fetchFreestyleClasssForOwner(owner, sortBy) {
    let result = await this._adapter.fetchFreestyleClasssForOwner(owner)
    return this.processListResponse(result, sortBy)
  }

  async fetchFreestyleClasssForLevel(level, sortBy) {
    let result = await this._adapter.fetchFreestyleClasssForLevel(level)
    return this.processListResponse(result, sortBy)
  }

  async fetchEntityWithSongs(id, maxLevel, sortBy) {
    let result = await this._adapter.fetchEntityWithSongs(id, maxLevel, sortBy)
    return result !== null ? this.processEntityResponse(result, id) : []
  }

  getClassLayoutsForUser(userId) {
    let response = this._adapter.getClassLayoutsForUser(userId)
    this._mapFetchedDataToEntity(response)
    return this._data
  }

  _mapFetchedDataToEntity(fetchedData) {
    if (fetchedData === [] || fetchedData === undefined) {
      return
    }
    if (Array.isArray(fetchedData)) {
      fetchedData.map(data => this.add(new FreestyleClassEntity(data)))
    } else {
      this.add(new FreestyleClassEntity(fetchedData))
    }
    return
  }

  // _mapperIn(data = {}) {
  // 	const mappedData = { id: data.id, title: data.title, description: data.description,
  // 		status: data.status, level: data.level, playListURL: data.playListURL}
  // 	const masterClass = new FreestyleClassEntity(mappedData)
  // 	this._data.push(masterClass)
  // }
}
