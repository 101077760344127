import User from '../../entities/User'
import Repository from './Repository'
import UTCTimeNow from '../presenters/react/components/atoms/UTCTimeNow'

export default class UserRepository extends Repository {
  constructor(adapter) {
    super()
    this._adapter = adapter
  }

  addUser(data) {
    this.add(new User(data))
  }

  async fetchActiveUser(id) {
    // @TODO: verify requestor is user (adapter does auth?)
    const result = await this._adapter.fetchActiveUser(id)
    // console.log('UserRepository: fetchActiveUser result=',result)
    if (result == null || result.hasOwnProperty('errors')) {
      return result
    }
    this._mapFetchedDataToEntity(result)
    return this.find({ id: id })[0]
  }

  async userLoggedIn(user) {
    // console.log('userLoggedIn, user=',user)
    const userId = user.id
    const nowUTC = UTCTimeNow()
    const updateData = {
      id: userId,
      lastLogin: nowUTC,
    }
    await this.updateEntity(updateData)
    // console.log('UserRepository: userLoggedIn returns ',this.find({id:userId}), updateData)
    return this.find({ id: userId })[0]
  }

  _mapFetchedDataToEntity(fetchedData) {
    if (fetchedData === [] || fetchedData === undefined) {
      return
    }
    if (Array.isArray(fetchedData)) {
      fetchedData.map(data => this.add(new User(data)))
    } else {
      this.add(new User(fetchedData))
    }
    return
  }
  // _mapperIn(data = {}) {
  // 	const mappedData = { id: data.id, title: data.title, description: data.description,
  // 		status: data.status, level: data.level, playListURL: data.playListURL}
  // 	const masterClass = new MasterClass(mappedData)
  // 	this._data.push(masterClass)
  // }
}
