import UsersGraphQLAdapter from '../adapters/drivers/UsersGraphQLAdapter'
import UserRepository from '../adapters/repositories/UserRepository'
import UserService from './UserService'
import ClassDecorator from './ClassDecorator'

class AppUserService {
  constructor() {
    this._initializeUserService()
    return this._userService
  }

  _initializeUserService() {
    this._adapter = new UsersGraphQLAdapter()
    this._userRepository = new UserRepository(this._adapter)
    this._userService = new UserService(this._userRepository, this._adapter)
  }
}
export default ClassDecorator.makeSingleton(AppUserService)
