import Entity from './Entity'
import ActionComponentEntity from './ActionComponentEntity'
import PageEntity from './PageEntity'
import User from './User'

export default class ActiveUserEntity extends Entity {
  id

  user
  actionComponents
  pages

  constructor(initialValues = {}) {
    let _defaults = {}
    super({ ..._defaults, ...initialValues })

    if (initialValues !== null) {
      const { user, pages, actionComponents } = initialValues

      if (user !== undefined) {
        this.user = new User(user)
      }

      if (pages !== undefined) {
        this.pages = new PageEntity(pages)
      }
      if (actionComponents !== undefined) {
        this.actionComponents = new ActionComponentEntity(actionComponents)
      }
    }

    // if (initialValues !== null) {
    //   const { user } = initialValues
    //   if (user !== undefined) {
    //     this.user = new User(user)
    //   }

    //   const { actionComponents } = initialValues
    //   if (actionComponents !== undefined) {
    //     this.actionComponents.push(new ActionComponentEntity(actionComponents))
    //   }

    //   const { pages } = initialValues
    //   if (pages !== undefined) {
    //     this.pages.push(new PageEntity(pages))
    //   }
    // }
  }

  isAdminUser() {
    return this.admin === true
  }
  isActiveUser() {
    return this.active === true
  }

  isSubscriber() {
    return this.subscriber === true
  }

  statusOptions() {
    return [
      { key: 0, value: 'New Signup' },
      { key: 1, value: 'Welcome' },
      { key: 2, value: 'Started' },
      { key: 3, value: 'Complete' },
      { key: 4, value: 'Active' },
      { key: 5, value: 'Paused' },
    ]
  }

  statusLabel() {
    let statuses = this.statusOptions()
    let label = 'Waiting For Approval'
    if (this.status !== undefined) {
      let data = statuses.find(label => {
        return label.key === this.status
      })
      if (data !== undefined) {
        return data.value
      }
    }
    return label
  }
}
