export default class CuratedClassService {
  constructor(repository, adapter) {
    this._repository = repository
    this._adapter = adapter
  }

  createCuratedClass(entity) {
    return this._repository.createEntity(entity)
  }

  fetchCuratedClass(id) {
    return this._repository.fetchEntity(id)
  }

  fetchCuratedClassWithSongs(id, sortBy) {
    return this._repository.fetchEntityWithSongs(id, sortBy)
  }

  updateCuratedClass(entity) {
    return this._repository.updateEntity(entity)
  }

  deleteCuratedClass(id) {
    return this._repository.deleteEntity(id)
  }

  fetchAllCuratedClasses(sortBy) {
    return this._repository.fetchAll(sortBy)
  }

  fetchAllCuratedClasssByLevel(maxLevel, classType, sortBy) {
    return this._repository.fetchCuratedClasssForLevel(
      maxLevel,
      classType,
      sortBy,
    )
  }

  addCuratedClassEntity(entity) {
    this._repository.addEntity(entity)
    return this._repository.get()[0]
  }

  clear() {
    this._repository.clear()
  }
}
