import React, { useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PageLoader from '../../components/molecules/PageLoader'
import SimpleSelect from '../../components/molecules/SimpleSelect'
import { Page } from '../../components/molecules/Page'
import AppPageService from '../../../../../services/AppPageService'

const PageEdit = props => {
  const pageId = props.match.params.pageId
  // console.log('props',props)
  const [page, setPage] = React.useState(null)

  const pageService = new AppPageService()

  useEffect(() => {
    if (pageId !== '_new_') {
      ;(async pageId => {
        const data = await pageService.fetchPage(pageId)
        // console.log('PageEdit: data',data)
        setPage(data)
      })(pageId)
    } else {
      pageService.clear()
      const newPage = pageService.addPageEntity({
        id: '_new_',
        adminOnly: false,
        menuSection: 'Main',
        sortBy: 1,
        linkPath: '/',
        includeFooter: false,
        includeNavigationMenu: true,
      })
      setPage(newPage)
    }
    // console.log('PageEdit: data',page)
  }, [pageId, pageService])

  const handleChange = name => event => {
    let newValue = Object.assign(
      Object.create(Object.getPrototypeOf(page)),
      page,
    ) // update value while preserving class object
    newValue[name] = event.target.value
    setPage(newValue)
  }

  const saveHandler = async updated => {
    if (updated.id !== '_new_') {
      await pageService.updatePage(updated)
    } else {
      updated.id = null
      await pageService.createPage(updated)
    }
    props.history.push('/admin/pages/list')
  }

  const classes = useStyles()

  if (page !== undefined && page !== null) {
    // console.log('page',page)
    const pageContent = {
      pageTitle: 'Page Edit',
      introText: `Editing Notes: (Note: changes will not be visible on the site until you reload the site, refresh the page)
      sortBy = sorting position on the screen, sorted ascending within menu section
      Menu: Main or Admin
      Link Text: This displays on the site as the link
      IntroText: Will display on the top portion of the page
      Content: (not used at this time)
      Include in Main Menu: Show the link in the top menu
      Include in Footer: Show the link in the footer links
      Admin Only: Link should only appear when an Admin user is viewing
      Link Icon: (not used at this time, will provide an icon next to a link)
      Link Path: This is the link in the browser URL for the page
      SortBy: Links are sorted by this in the top, footer, and admin link
      sections`,
    }
    return (
      <Page.WithIntro pageContent={pageContent}>
        <Card key={page.id} className={classes.card}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <SimpleSelect
                  fullWidth
                  input
                  label="Menu Section"
                  options={[
                    { key: 'Main', label: 'Main' },
                    { key: 'Admin', label: 'Admin' },
                    { key: 'Custom', label: 'Custom' },
                  ]}
                  optionValue="key"
                  optionLabel="label"
                  selectedValue={page.menuSection}
                  variant="outlined"
                  onChangeHandler={handleChange('menuSection')}
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-pageTitle"
                  label="Page Title"
                  value={page.pageTitle}
                  onChange={handleChange('pageTitle')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-linkText"
                  label="Link Text"
                  value={page.linkText}
                  onChange={handleChange('linkText')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-linkPath"
                  label="Link Path"
                  value={page.linkPath}
                  onChange={handleChange('linkPath')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  required
                  id="outlined-required-introText"
                  label="Intro Text"
                  value={page.introText}
                  onChange={handleChange('introText')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  required
                  id="outlined-required-pageContent"
                  label="Page Content"
                  value={page.pageContent}
                  onChange={handleChange('pageContent')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <SimpleSelect
                  fullWidth
                  input
                  label="Include in Upper Menu"
                  options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },
                  ]}
                  optionValue="value"
                  optionLabel="label"
                  selectedValue={page.includeNavigationMenu}
                  variant="outlined"
                  onChangeHandler={handleChange('includeNavigationMenu')}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <SimpleSelect
                  fullWidth
                  input
                  label="Include in Lower Menu"
                  options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },
                  ]}
                  optionValue="value"
                  optionLabel="label"
                  selectedValue={page.includeFooter}
                  variant="outlined"
                  onChangeHandler={handleChange('includeFooter')}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <SimpleSelect
                  fullWidth
                  input
                  label="Admin Only"
                  options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },
                  ]}
                  optionValue="value"
                  optionLabel="label"
                  selectedValue={page.adminOnly}
                  variant="outlined"
                  onChangeHandler={handleChange('adminOnly')}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-linkIcon"
                  label="Link Icon"
                  value={page.linkIcon}
                  onChange={handleChange('linkIcon')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-videoLabel"
                  label="Video Label"
                  value={page.videoLabel}
                  onChange={handleChange('videoLabel')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-videoLink"
                  label="Video Link URL"
                  value={page.videoLink}
                  onChange={handleChange('videoLink')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-buttonLabel"
                  label="Button Label"
                  value={page.buttonLabel}
                  onChange={handleChange('buttonLabel')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-buttonLink"
                  label="Button Link URL"
                  value={page.buttonLink}
                  onChange={handleChange('buttonLink')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-sortBy"
                  label="sortBy"
                  value={page.sortBy}
                  onChange={handleChange('sortBy')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>

          <CardActions>
            <NavLink to="/admin/pages/list">
              <Button size="small">Cancel</Button>
            </NavLink>
            <Button
              onClick={() => {
                saveHandler(page)
              }}
              size="small"
              color="primary"
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </Page.WithIntro>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(PageEdit)

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    minWidth: 275,
    marginBottom: 5,
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
