import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

const PageTitle = ({ pageTitle = 'Title', variant = 'h5' }) => {
  return <Typography variant={variant}>{pageTitle}</Typography>
}

export default PageTitle

PageTitle.propTypes = {
  pageTitle: PropTypes.string,
  variant: PropTypes.string,
}
