import PropTypes from 'prop-types'

export const RenderPR = (pr_a, pr_b) => {
  if (isEmpty(pr_a) && isEmpty(pr_b)) {
    return ''
  }
  if (!isEmpty(pr_a) && isEmpty(pr_b)) {
    return pr_a
  }
  if (!isEmpty(pr_a) && !isEmpty(pr_b)) {
    if (pr_a === pr_b) {
      return pr_a
    }
    return pr_a + ', ' + pr_b
  }
}
RenderPR.PropTypes = {
  pr_a: PropTypes.number,
  pr_b: PropTypes.number,
}

function isEmpty(value) {
  if (value === null || value === 0 || parseInt(value) === 0) {
    return true
  }
  return false
}
