import ActionComponentGraphQLAdapter from '../adapters/drivers/ActionComponentGraphQLAdapter'
import ActionComponentRepository from '../adapters/repositories/ActionComponentRepository'
import ActionComponentService from './ActionComponentService'
import ClassDecorator from './ClassDecorator'

class AppActionComponentService {
  constructor() {
    this._initializeAppService()
    return this._actionComponentService
  }

  _initializeAppService() {
    this._adapter = new ActionComponentGraphQLAdapter()
    this._actionComponentRepository = new ActionComponentRepository(
      this._adapter,
    )
    this._actionComponentService = new ActionComponentService(
      this._actionComponentRepository,
      this._adapter,
    )
  }
}
export default ClassDecorator.makeSingleton(AppActionComponentService)
