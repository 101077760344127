import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import PageLoader from '../../../components/molecules/PageLoader'
import SimpleSelect from '../../../components/molecules/SimpleSelect'
import AppFreestyleClassService from '../../../../../../services/AppFreestyleClassService'
import { Page } from '../../../components/molecules/Page'
import { FREESTYLE_CLASS_TYPE } from '../../../../../../entities/FreestyleClassEntity'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    minWidth: 275,
    marginBottom: 5,
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const FreestyleEdit = ({ activeUser, classType, match, history }) => {
  const freestyleId = match.params.freestyleId
  const [freestyleClass, setFreestyleClass] = React.useState(null)

  const freestyleClassService = new AppFreestyleClassService()

  useEffect(() => {
    if (freestyleId !== '_new_') {
      ;(async freestyleId => {
        const data = await freestyleClassService.fetchFreestyleClass(
          freestyleId,
        )
        if (data.createdByUser === null || data.createdByUser.id === null) {
          data.createdByUser = { id: activeUser.id } // assume ownership
        }
        if (!data.errors) {
          setFreestyleClass(data)
        } else {
          console.log('FreestyleEdit: data', data)
        }
      })(freestyleId)
    } else {
      freestyleClassService.clear()
      const newClass = freestyleClassService.addFreestyleClassEntity({
        id: '_new_',
        title: '',
        layoutLevel: activeUser.currentClassRelease,
        type: FREESTYLE_CLASS_TYPE,
        active: true,
        createdByUser: { id: activeUser.id },
      })
      setFreestyleClass(newClass)
    }
  }, [
    freestyleId,
    freestyleClassService,
    activeUser.id,
    activeUser.currentClassRelease,
  ])

  const handleChange = name => event => {
    let newValue = Object.assign(
      Object.create(Object.getPrototypeOf(freestyleClass)),
      freestyleClass,
    ) // update value while preserving class object
    newValue[name] = event.target.value
    setFreestyleClass(newValue)
  }

  const saveHandler = async updated => {
    if (updated.id !== '_new_') {
      await freestyleClassService.updateFreestyleClass(updated)
    } else {
      updated.id = null
      await freestyleClassService.createFreestyleClass(updated)
    }
    returnToPreviousPage()
  }

  const returnToPreviousPage = () => {
    history.go(-1)
  }

  const styles = useStyles()
  if (freestyleClass !== null) {
    return (
      <Page.WithTitle
        pageContent={{
          pageTitle: `Edit Freestyle Class: ${freestyleClass.title}`,
        }}
      >
        <Card key={freestyleClass.id} className={styles.card}>
          <CardContent>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} sm={6} md={3}>
                <SimpleSelect
                  fullWidth
                  label="Group"
                  options={freestyleClass.groupOptions()}
                  optionValue="key"
                  optionLabel="value"
                  selectedValue={freestyleClass.group}
                  variant="outlined"
                  onChangeHandler={handleChange('group')}
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-title"
                  label="Title"
                  value={freestyleClass.title}
                  onChange={handleChange('title')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  multiline
                  rowsMax="4"
                  required
                  id="outlined-required-description"
                  label="Description"
                  value={freestyleClass.description}
                  onChange={handleChange('description')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="outlined-required-playlistURL"
                  label="Playlist URL"
                  value={freestyleClass.playlistURL}
                  onChange={handleChange('playlistURL')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="outlined-required-classPDFLink"
                  label="Class PDF Link"
                  value={freestyleClass.classPDFLink}
                  onChange={handleChange('classPDFLink')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="outlined-required-sortOrder"
                  label="Sort Order"
                  value={freestyleClass.sortOrder}
                  onChange={handleChange('sortOrder')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <SimpleSelect
                  fullWidth
                  label="Active"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  selectedValue={freestyleClass.active}
                  variant="outlined"
                  onChangeHandler={handleChange('active')}
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  id="outlined-required-layoutLevel"
                  label="Release"
                  value={freestyleClass.layoutLevel}
                  onChange={handleChange('layoutLevel')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                  helperText="Optional Class Level. This will be set when adding songs with action components"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <SimpleSelect
                  fullWidth
                  label="Shared"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  selectedValue={freestyleClass.shared}
                  variant="outlined"
                  onChangeHandler={handleChange('shared')}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button onClick={() => returnToPreviousPage()} size="small">
              Cancel
            </Button>
            <Button
              onClick={() => {
                saveHandler(freestyleClass)
              }}
              size="small"
              color="primary"
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </Page.WithTitle>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(FreestyleEdit)
