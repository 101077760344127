import FreestyleClassGraphQLAdapter from '../adapters/drivers/FreestyleClassGraphQLAdapter'
import FreestyleClassRepository from '../adapters/repositories/FreestyleClassRepository'
import FreestyleClassService from './FreestyleClassService'
import ClassDecorator from './ClassDecorator'

class AppFreestyleClassService {
  constructor() {
    this._initializeService()
    return this._freestyleClassService
  }

  _initializeService() {
    this._adapter = new FreestyleClassGraphQLAdapter()
    this._freestyleClassRepository = new FreestyleClassRepository(this._adapter)
    this._freestyleClassService = new FreestyleClassService(
      this._freestyleClassRepository,
      this._adapter,
    )
  }
}
export default ClassDecorator.makeSingleton(AppFreestyleClassService)
