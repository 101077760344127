import CuratedClassEntity from '../../entities/CuratedClassEntity'
import Repository from './Repository'

export default class CuratedClassRepository extends Repository {
  constructor(adapter) {
    super()
    this._adapter = adapter
  }

  addEntity(entity) {
    this.add(new CuratedClassEntity(entity))
  }

  async fetchCuratedClasssForLevel(level, type, sortBy) {
    let result = await this._adapter.fetchCuratedClasssForLevel(level, type)
    return this.processListResponse(result, sortBy)
  }

  async fetchEntityWithSongs(id, sortBy) {
    let result = await this._adapter.fetchEntityWithSongs(id, sortBy)
    // return this.processListResponse(result, sortBy)
    return result !== null ? this.processEntityResponse(result, id) : []
  }

  getClassLayoutsForUser(userId) {
    let response = this._adapter.getClassLayoutsForUser(userId)
    this._mapFetchedDataToEntity(response)
    return this._data
  }

  _mapFetchedDataToEntity(fetchedData) {
    if (fetchedData === [] || fetchedData === undefined) {
      return
    }
    if (Array.isArray(fetchedData)) {
      fetchedData.map(data => this.add(new CuratedClassEntity(data)))
    } else {
      this.add(new CuratedClassEntity(fetchedData))
    }
    return
  }

  // _mapperIn(data = {}) {
  // 	const mappedData = { id: data.id, title: data.title, description: data.description,
  // 		status: data.status, level: data.level, playListURL: data.playListURL}
  // 	const masterClass = new CuratedClassEntity(mappedData)
  // 	this._data.push(masterClass)
  // }
}
