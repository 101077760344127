import Entity from './Entity'
import ActionComponentEntity from './ActionComponentEntity'
import FreestyleClassEntity from './FreestyleClassEntity'

export default class FreestyleSongEntity extends Entity {
  id
  song
  artist
  rpm_a
  rpm_b
  pr_a // (pr_a, pr_b )  when editing/viewing  80 (54)
  pr_b
  coaching_notes
  coaching_notes_simple
  sortOrder
  videoURL
  owner

  freestyleSongActionComponentId // id of connected ActionComponentEntity
  freestyleSongFreestyleClassId // id of connected FreestyleClassEntity

  freestyleClass
  actionComponent

  constructor(initialValues = {}) {
    let _defaults = { sortOrder: 1 }
    super({ ..._defaults, ...initialValues })

    FREESTYLE_SONG_ENTITY_STRING_LIST.forEach(field => {
      if (this[field] === null) {
        this[field] = ''
      }
    })
    FREESTYLE_SONG_ENTITY_NUMBER_LIST.forEach(field => {
      if (this[field] === null) {
        this[field] = 0
      }
    })

    if (initialValues !== null) {
      const { freestyleClass, actionComponent } = initialValues
      if (freestyleClass !== undefined) {
        this.freestyleClass = new FreestyleClassEntity(freestyleClass)
        if (freestyleClass !== null) {
          this.freestyleSongFreestyleClassId = freestyleClass.id
        }
      }
      if (actionComponent !== undefined) {
        this.actionComponent = new ActionComponentEntity(actionComponent)
        if (actionComponent !== null) {
          this.freestyleSongActionComponentId = actionComponent.id
        }
      }
    }
  }

  prOptions(prOption) {
    const options = [
      'none',
      'L',
      'M',
      'H',
      'LMH',
      'LM',
      'MH',
      'L and M/H alt',
      'H then L',
      'L then H',
    ]
    return options[prOption]
  }
}

export const FREESTYLE_SONG_ENTITY_NUMBER_LIST = ['rpm_a', 'rpm_b', 'sortOrder']
export const FREESTYLE_SONG_ENTITY_STRING_LIST = [
  'song',
  'artist',
  'coaching_notes',
  'coaching_notes_simple',
  'pr_a',
  'pr_b',
  'videoURL',
]
