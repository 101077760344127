import React from 'react'
import { FacebookProvider, Group } from 'react-facebook'
import { Page } from '../clean/adapters/presenters/react/components/molecules/Page'

const SocialPage = ({ pageContent }) => {
  return (
    <Page.WithIntro pageContent={pageContent}>
      <FacebookProvider appId="416592098984209">
        <Group
          href="https://www.facebook.com/groups/254624822092544"
          width="300"
          showSocialContext={true}
          showMetaData={true}
          skin="light"
        />
      </FacebookProvider>
      {/* <iframe title="soc" src="https://www.facebook.com/plugins/comment_embed.php?href=https%3A%2F%2Fwww.facebook.com%2Fgroups%2F254624822092544%2F&width=560&include_parent=false&appId=416592098984209&height=202" width="560" height="202" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe> */}
    </Page.WithIntro>
  )
}

export default SocialPage
