import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { TableHead, TableRow, TableCell, Link } from '@material-ui/core'

import PageEntity from '../../../../../entities/PageEntity'
import User from '../../../../../entities/User'
import { Button } from '../../components/atoms/Button'

export const SitePageRowHeader = ({ activeUser }) => {
  return (
    <TableHead>
      <TableRow>
        {activeUser.isAdminUser() ? <TableCell>Edit</TableCell> : null}
        <TableCell>Page Title</TableCell>
        {/* <TableCell>linkIcon</TableCell> */}
        <TableCell>Link Text</TableCell>
        <TableCell>Menu Section</TableCell>
        <TableCell align="center">Upper Menu</TableCell>
        <TableCell align="center">Lower Menu</TableCell>
        <TableCell align="center">Admin</TableCell>
        <TableCell>linkPath</TableCell>
        <TableCell align="center">sortBy</TableCell>
      </TableRow>
    </TableHead>
  )
}

export const SitePageRow = ({ page, activeUser }) => {
  // , onDeletePage = () => {}
  return (
    <TableRow key={page.id}>
      {activeUser.isAdminUser() ? (
        <TableCell>
          {renderEditButton(page.id)}
          {/* <RenderDeleteButton item={page} onDeletePage={onDeletePage} /> */}
        </TableCell>
      ) : null}
      <TableCell component="th" scope="page">
        {page.pageTitle}
      </TableCell>
      {/* <TableCell>{page.linkIcon}</TableCell> */}
      <TableCell>{page.linkText}</TableCell>
      <TableCell align="center">{page.menuSection}</TableCell>
      <TableCell align="center">
        {RenderBoolean(page.includeNavigationMenu)}
      </TableCell>
      <TableCell align="center">{RenderBoolean(page.includeFooter)}</TableCell>
      <TableCell align="center">{RenderBoolean(page.adminOnly)}</TableCell>
      <TableCell>{page.linkPath}</TableCell>
      <TableCell align="center">{page.sortBy}</TableCell>
    </TableRow>
  )
}
SitePageRow.propTypes = {
  page: PropTypes.instanceOf(PageEntity),
  activeUser: PropTypes.instanceOf(User),
  // onDeletePage: PropTypes.func,
}

const RenderBoolean = (value, labels) => {
  if (labels === undefined) {
    labels = ['N', 'Y']
  }
  if (value !== true) {
    return labels[0]
  } else {
    return labels[1]
  }
}

const renderEditButton = id => {
  return (
    <Link
      component={RouterLink}
      to={`/admin/pages/edit/${id}`}
      className="header"
    >
      <Button>Edit</Button>
    </Link>
  )
}

// const RenderDeleteButton = ({ item, onDeletePage }) => {
//   const confirmDelete = {
//     actionButtonLabel: 'Delete',
//     title: 'Are you sure?',
//     content: `Deleting the ${item.pageTitle} page is permanent.`,
//     cancelLabel: 'Cancel',
//     onCancel: () => {},
//     confirmLabel: 'Delete',
//     onConfirm: onDeletePage.bind(this, item.id),
//   }
//   return <AlertConfirmation {...confirmDelete} />
// }
