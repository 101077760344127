import React from 'react'
import { Paper, Typography, TextField, Button, Grid, FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';

const classes = {
  paper: {
    padding: 20,
    minHeight: 200,
    marginTop: 10,
    marginBottom: 10
  },
  container: {
    padding:20,
    marginLeft: 10,
  }
}
const LayoutAddPage = (props) => {
  const classLayoutId = props.match.params.classLayoutId
  const classRepository = props.classRepository
  // const activeUser = props.activeUser ? props.activeUser : {}
  const [values, setValues] = React.useState({
    id:'1234',
    title:'',
    description:'',
    status:'0',
    level:'',
    playListURL:'https://open.spotify.com/playlist/...'
  })
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleSubmit = () => {
    console.log("handleSubmit: ",values)
    // @TODO: Add validation
    // use repository to add a new master class
    classRepository.add(values)
    props.history.push('/layouts')
  }
  const handleCancel = () => {
    // @TODO: Add verify
    props.history.push('/layouts')
  }
  const renderHeader = () => {
    let title = 'Add Master Class'
    if (parseInt(classLayoutId) > 0) {
      title = 'Edit Master Class'
    }
    return (
      <Typography variant="h5">{title}</Typography>
    )
  }
  return (
    <Paper style={classes.paper}>
      {renderHeader()}
      {/* layout fields = id,title,description,status,level,playListURL */}
      <form className={classes.container} noValidate autoComplete="off">
        <Grid container spacing={1} margin={5}>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              id="standard-title"
              label="Title"
              fullWidth
              className={classes.textField}
              value={values.title}
              onChange={handleChange('title')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <TextField
              id="standard-description"
              label="Description"
              fullWidth
              className={classes.textField}
              value={values.description}
              onChange={handleChange('description')}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="status-native-simple">Status</InputLabel>
              <Select
                native
                value={values.status}
                onChange={handleChange('status')}
                inputProps={{
                  name: 'status',
                  id: 'status-native-simple',
                }}
              >
                <option value={0}>Inactive</option>
                <option value={1}>Released</option>
              </Select>
              <FormHelperText>This class will not be visible while it is inactive</FormHelperText>
            </FormControl>
          </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
        <TextField
          id="standard-level"
          label="Release"
          className={classes.textField}
          value={values.level}
          onChange={handleChange('level')}
          margin="normal"
        />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
        <TextField
          id="standard-playListURL"
          label="Playlist URL"
          fullWidth
          className={classes.textField}
          value={values.playListURL}
          onChange={handleChange('playListURL')}
          margin="normal"
        />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Button variant="outlined" className={classes.button} onClick={() => handleCancel()}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" className={classes.button} onClick={() => handleSubmit()}>
          Save
        </Button>
        </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default LayoutAddPage;
