import { useState, useEffect } from 'react'

export const FetchCuratedClass = (
  curatedClassId = '',
  curatedClassService = {},
) => {
  const [curatedClass, setCuratedClass] = useState()

  useEffect(() => {
    if (
      curatedClassId &&
      curatedClassService &&
      curatedClassService.fetchEntity
    ) {
      curatedClassService
        .fetchEntity(curatedClassId)
        .catch(() => [])
        .then(data => {
          setCuratedClass(data)
        })
    }
  }, [curatedClassId, curatedClassService, setCuratedClass])

  return curatedClass
}

export const FetchAllCuratedClasses = (
  sortBy,
  classType,
  maxLevel,
  curatedClassService = {},
  refresh,
) => {
  const [curatedClasses, setCuratedClasses] = useState()
  useEffect(() => {
    if (
      sortBy &&
      curatedClassService &&
      curatedClassService.fetchCuratedClasses
    ) {
      curatedClassService
        .fetchCuratedClasses(classType, maxLevel, sortBy)
        .catch(() => [])
        .then(data => {
          setCuratedClasses(data)
        })
    }
  }, [
    classType,
    maxLevel,
    sortBy,
    curatedClassService,
    curatedClassService.fetchCuratedClasses,
    setCuratedClasses,
  ])
  return curatedClasses
}

export const FetchAllCuratedClasssByLevel = (
  maxLevel,
  classType,
  sortBy,
  curatedClassService = {},
  refresh,
) => {
  const [curatedClasses, setCuratedClasses] = useState()
  useEffect(() => {
    if (
      classType &&
      sortBy &&
      curatedClassService &&
      curatedClassService.fetchAllCuratedClasssByLevel
    ) {
      curatedClassService.clear()
      curatedClassService
        .fetchAllCuratedClasssByLevel(maxLevel, classType, sortBy)
        .catch(() => [])
        .then(data => {
          setCuratedClasses(data)
        })
    }
  }, [
    maxLevel,
    classType,
    sortBy,
    curatedClassService,
    curatedClassService.fetchCuratedClasssForLevel,
    setCuratedClasses,
  ])
  return curatedClasses
}
