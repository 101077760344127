import React, { useEffect } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import PageLoader from '../../../components/molecules/PageLoader'
import SimpleSelect from '../../../components/molecules/SimpleSelect'
import AppCuratedClassService from '../../../../../../services/AppCuratedClassService'
import { Page } from '../../../components/molecules/Page'
import { CURATED_CLASS_TYPE } from '../../../../../../entities/CuratedClassEntity'
import { FREESTYLE_CLASS_TYPE } from '../../../../../../entities/FreestyleClassEntity'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    minWidth: 275,
    marginBottom: 5,
  },
  textField: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const CuratedEdit = ({ activeUser, classType, match, history }) => {
  const curatedId = match.params.curatedId
  const [curatedClass, setCuratedClass] = React.useState(null)

  const curatedClassService = new AppCuratedClassService()

  useEffect(() => {
    if (curatedId !== '_new_') {
      ;(async curatedId => {
        const data = await curatedClassService.fetchCuratedClass(curatedId)
        if (data.createdByUser === null) {
          data.createdByUser = { id: activeUser.id } // assume ownership
        }
        data.shared = false // assume shared @TODO: this needs to be optional for curated
        if (!data.errors) {
          setCuratedClass(data)
        } else {
          console.log('CuratedEdit: data', data)
        }
      })(curatedId)
    } else {
      curatedClassService.clear()
      const newClass = curatedClassService.addCuratedClassEntity({
        id: '_new_',
        title: '',
        type: CURATED_CLASS_TYPE,
        active: true,
        createdByUser: { id: activeUser.id },
      })
      setCuratedClass(newClass)
    }
  }, [curatedId, curatedClassService, activeUser.id])

  const handleChange = name => event => {
    let newValue = Object.assign(
      Object.create(Object.getPrototypeOf(curatedClass)),
      curatedClass,
    ) // update value while preserving class object
    newValue[name] = event.target.value
    // console.log('handleChange: after',newValue)
    setCuratedClass(newValue)
  }

  const saveHandler = async updated => {
    if (updated.id !== '_new_') {
      await curatedClassService.updateCuratedClass(updated)
    } else {
      updated.id = null
      const response = await curatedClassService.createCuratedClass(updated)
      console.log(response)
    }
    if (curatedClass.type === FREESTYLE_CLASS_TYPE) {
      history.push('/freestyle/list')
    }
    history.push('/curated/list')
  }

  const styles = useStyles()
  // console.log('curatedClass ', curatedClass)
  if (curatedClass !== null) {
    return (
      <Page.WithTitle
        pageContent={{ pageTitle: `Edit Curated Class: ${curatedClass.title}` }}
      >
        <Card key={curatedClass.id} className={styles.card}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3}>
                <SimpleSelect
                  fullWidth
                  label="Group"
                  options={curatedClass.groupOptions()}
                  optionValue="key"
                  optionLabel="value"
                  selectedValue={curatedClass.group}
                  variant="outlined"
                  onChangeHandler={handleChange('group')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-title"
                  label="Title"
                  value={curatedClass.title}
                  onChange={handleChange('title')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-layoutLevel"
                  label="Release"
                  value={curatedClass.layoutLevel}
                  onChange={handleChange('layoutLevel')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  multiline
                  rowsMax="4"
                  required
                  id="outlined-required-description"
                  label="Description"
                  value={curatedClass.description}
                  onChange={handleChange('description')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="outlined-required-playlistURL"
                  label="Playlist URL"
                  value={curatedClass.playlistURL}
                  onChange={handleChange('playlistURL')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="outlined-required-classPDFLink"
                  label="Class PDF Link"
                  value={curatedClass.classPDFLink}
                  onChange={handleChange('classPDFLink')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="outlined-required-sortOrder"
                  label="Sort Order"
                  value={curatedClass.sortOrder}
                  onChange={handleChange('sortOrder')}
                  className={styles.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SimpleSelect
                  fullWidth
                  label="Active"
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  selectedValue={curatedClass.active}
                  variant="outlined"
                  onChangeHandler={handleChange('active')}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <NavLink to="/curated/list">
              <Button size="small">Cancel</Button>
            </NavLink>
            <Button
              onClick={() => {
                saveHandler(curatedClass)
              }}
              size="small"
              color="primary"
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </Page.WithTitle>
    )
  } else {
    return <PageLoader />
  }
}

export default withRouter(CuratedEdit)
