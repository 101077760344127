import { useState, useEffect } from 'react'

import PageEntity from '../../../../entities/PageEntity'
import ActionComponent from '../../../../entities/ActionComponentEntity'

export const FetchActiveUserEntity = (
  userId = '',
  userLevel = -1,
  activeUserService = {},
) => {
  const [activeUser, setActiveUser] = useState()
  // console.log('useActiveUserService - FetchActiveUserEntity',userId,userLevel,activeUserService)
  useEffect(() => {
    if (
      userId &&
      activeUserService &&
      activeUserService.fetchActiveUserEntity
    ) {
      activeUserService
        .fetchActiveUserEntity(userId, userLevel)
        .catch(() => [])
        .then(data => {
          setActiveUser(data)
        })
    }
  }, [userId, userLevel, activeUserService, setActiveUser])

  if (activeUser !== null && activeUser !== undefined) {
    activeUser.pages = activeUser.pages.map(page => new PageEntity(page))
    activeUser.actionComponents = activeUser.actionComponents.map(
      component => new ActionComponent(component),
    )
  }

  return activeUser
}
