import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { TableHead, TableRow, TableCell, Link } from '@material-ui/core'

// import CuratedClassEntity from '../../../../../entities/CuratedClassEntity'
// import FreestyleClassEntity from '../../../../../entities/CuratedClassEntity'
// import { CURATED_CLASS_TYPE } from '../../../../../entities/CuratedClassEntity'
import { FREESTYLE_CLASS_TYPE } from '../../../../../entities/FreestyleClassEntity'
import User from '../../../../../entities/User'
import { CreatedByName } from '../../components/functions/CreatedByName'
import { LinkButton } from '../../components/atoms/LinkButton'

export const ClassRowHeader = ({ classType, activeUser }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Edit</TableCell>
        {classType !== FREESTYLE_CLASS_TYPE ? (
          <TableCell>Grouping</TableCell>
        ) : null}
        <TableCell>Layout</TableCell>
        <TableCell align="center">Description</TableCell>
        <TableCell align="center">Release</TableCell>
        <TableCell align="center">By</TableCell>
      </TableRow>
    </TableHead>
  )
}
ClassRowHeader.propTypes = {
  classType: PropTypes.string,
  activeUser: PropTypes.instanceOf(User),
}

export const ClassRow = ({
  classRow,
  classType,
  sharedFreestyle,
  activeUser,
}) => {
  return (
    <TableRow key={classRow.id}>
      <TableCell align="center" style={{ verticalAlign: 'top' }}>
        {canEdit(classRow, classType, sharedFreestyle, activeUser) ? (
          <RenderClassActions {...{ classRow, classType }} />
        ) : null}
      </TableCell>
      {classType !== FREESTYLE_CLASS_TYPE ? (
        <TableCell style={{ verticalAlign: 'top' }}>
          {classRow.groupLabel()}
        </TableCell>
      ) : null}
      <TableCell
        component="th"
        scope="classRow"
        style={{ verticalAlign: 'top' }}
      >
        <RenderClassLink id={classRow.id} {...{ classType, sharedFreestyle }}>
          {classRow.title}
        </RenderClassLink>
      </TableCell>
      <TableCell align="center" style={{ maxWidth: 400, verticalAlign: 'top' }}>
        {classRow.description}
      </TableCell>
      <TableCell align="center" style={{ verticalAlign: 'top' }}>
        {classRow.layoutLevel}
      </TableCell>
      <TableCell align="center" style={{ verticalAlign: 'top' }}>
        {CreatedByName(classRow)}
      </TableCell>
    </TableRow>
  )
}
ClassRow.propTypes = {
  classRow: PropTypes.object,
  // classRow: PropTypes.oneOfType([
  //   PropTypes.instanceOf(CuratedClassEntity),
  //   PropTypes.instanceOf(FreestyleClassEntity),
  // ]),
  classType: PropTypes.string,
  sharedFreestyle: PropTypes.bool,
  activeUser: PropTypes.instanceOf(User),
}

const RenderClassActions = ({ classRow, classType }) => {
  // /(curated/freestyle)/edit/:classID
  const classID = classRow.id
  return (
    <>
      <LinkButton to={`/${classType}/edit/${classID}`}>Edit</LinkButton>{' '}
      <LinkButton to={`/${classType}/delete/${classID}`} color="secondary">
        Delete
      </LinkButton>
    </>
  )
}

const canEdit = (classRow, classType, sharedFreestyle, activeUser) => {
  if (activeUser.isAdminUser()) {
    return true
  }
  if (sharedFreestyle) {
    return false
  }
  if (
    classType === FREESTYLE_CLASS_TYPE &&
    classRow.owner === activeUser.authUsername
  ) {
    return true
  }
  return false
}

const RenderClassLink = ({ id, classType, sharedFreestyle, children }) => {
  let classLink
  if (sharedFreestyle) {
    classLink = 'shared'
  } else {
    classLink = classType
  }
  return (
    <Link
      component={RouterLink}
      to={`/${classLink}/class/${id}/songs`}
      className="header"
    >
      {children}
    </Link>
  )
}
