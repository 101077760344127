import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Typography,
  Grid,
  Card,
  CardActions,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  makeStyles,
  TextField,
} from '@material-ui/core'

import SimpleSelect from '../clean/adapters/presenters/react/components/molecules/SimpleSelect'
import AppUserService from '../clean/services/AppUserService'

const inputFields = {
  firstName: '',
  lastName: '',
  currentlyInstructing: false,
  instructingLocation: '',
  certificationDate: '',
}

const userService = new AppUserService()
const useStyles = makeStyles({
  card: {
    maxWidth: 1024,
    marginBottom: 30,
    marginTop: 5,
  },
  media: {
    height: 300,
  },
})

const Terms = ({ activeUser, pageContent, handleSignOut }) => {
  const [inputData, setInputData] = React.useState(inputFields)
  let history = useHistory()

  const handleChange = name => event => {
    let newValue = Object.assign(
      Object.create(Object.getPrototypeOf(inputData)),
      inputData,
    ) // update value while preserving class object
    newValue[name] = event.target.value
    // console.log('newValue', newValue)
    setInputData(newValue)
  }

  const saveHandler = async () => {
    const updatedUser = {
      ...{ id: activeUser.id },
      ...inputData,
      ...{ agreeTerms: true },
    }
    await userService.updateUser(updatedUser)
    history.go(0)
  }

  const classes = useStyles()

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={10}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image="/bb_class_cropped.jpg"
              title="BeatBossON™ Instructor Portal Terms of Use"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Please accept the Terms of Use to continue
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {pageContent.introText}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  Please enter the following and Accept the Terms of Use at the
                  bottom to continue
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-firstName"
                  label="First Name"
                  value={inputData.firstName}
                  onChange={handleChange('firstName')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="outlined-required-lastName"
                  label="Last Name"
                  value={inputData.lastName}
                  onChange={handleChange('lastName')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <SimpleSelect
                  fullWidth
                  input
                  label="Are you currently using your BeatBoss™ Certification for instructing?"
                  options={[
                    { value: false, label: 'No' },
                    { value: true, label: 'Yes' },
                  ]}
                  optionValue="value"
                  optionLabel="label"
                  selectedValue={inputData.currentlyInstructing}
                  variant="outlined"
                  onChangeHandler={handleChange('currentlyInstructing')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-required-instructingLocation"
                  label="What is the location(s) of your classes?"
                  value={inputData.instructingLocation}
                  onChange={handleChange('instructingLocation')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-required-certificationDate"
                  label="What is the date of your BeatBoss™ Certification?"
                  value={inputData.certificationDate}
                  onChange={handleChange('certificationDate')}
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Button size="small" color="primary" onClick={saveHandler}>
                  Accept
                </Button>
                <Button size="small" color="primary" onClick={handleSignOut}>
                  Logout
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Terms
