export const PageSeedData = [
  {
    id: '064b0f23-0434-47ea-949c-6d51a5c7b158',
    adminOnly: 'FALSE',
    includeFooter: 'FALSE',
    includeNavigationMenu: 'TRUE',
    introText:
      'Every month you will receive a fresh, new and progressing BeatBossBASIC™ Curated Class Layout. This page is a space where you can come to find those Class Layouts. Each Curated Class Layout provides a written Layout, a Demonstration Video of each song on the Layouts, and a suggested corresponding Spotify Playlist. We provide the structure that is needed to grow as a BeatBossBASIC™ Certified Instructor, but also give you the freedom to: 1. Choose music that works best for you (when selecting songs follow the ranges provided on the Class Layouts beside each Action/Component in the Class Layouts).  2. Freedom to personally time the Action/Components within each song  3. After Class Layout 2, the ability to re-order the Actions/Components within each Class Layout. The first 6 Class Layouts are your "Setting The Stage" Class Layouts; 1 & 2 are married, 3 & 4 are married and 5 & 6 are married. Take your time within each Class Layout for the very best growth experience and to set yourself up for more options to come.',
    linkIcon: 'icon',
    linkPath: '/curated/list',
    linkText: 'Curated Class Layouts',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'Curated Class Layouts',
    sortBy: 2,
  },
  {
    id: '22491550-f76d-41b7-912e-87863e2846d7',
    adminOnly: 'FALSE',
    includeFooter: 'FALSE',
    includeNavigationMenu: 'FALSE',
    introText: 'User Profile',
    linkIcon: 'icon',
    linkPath: '/profile',
    linkText: 'Profile',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'User Profile',
    sortBy: 20,
  },
  {
    id: '42210058-c2d1-4908-a2da-87b53294ef38',
    adminOnly: 'FALSE',
    includeFooter: 'TRUE',
    includeNavigationMenu: 'TRUE',
    introText:
      'Welcome to your first steps of getting BeatBossBASIC™Certified! Here are all your tools needed to be successful during the 16 Hour BeatBossBASIC ™ Certification and the first weeks after the 16 Hours while you are prepping and filming your BeatBossBASIC™Pre-Class and Class Layout 1 Proficiency Videos to upload to us. You can return here at any time to review or update to the latest BeatBossBASIC™ Overview. Certification tools: Overview, On-ramp videos, review videos for all components, link to upload videos (please name each video appropriately.',
    linkIcon: 'icon',
    linkPath: '/certification_tools',
    linkText: 'Certification Tools',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'Certification Tools',
    sortBy: 5,
  },
  {
    id: '4382b5f8-496e-4b90-9eae-cfedfae38542',
    adminOnly: 'FALSE',
    includeFooter: 'FALSE',
    includeNavigationMenu: 'TRUE',
    introText:
      'This page is a space where you can come to find all individual BeatBossBASIC™ Actions/Components. This all encompassing Library of Actions/Components is used to review already learned Actions/Components and learn new Actions/Components added each month.  This Library is also used in Guided Freestyle™ to create your own Class Layouts using individual Actions/Components.',
    linkIcon: 'icon',
    linkPath: '/components/view',
    linkText: 'Action Components',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'Actions/Components Library',
    sortBy: 3,
  },
  {
    id: '52d59a48-2c58-4d2d-83cf-1112e23adfb8',
    adminOnly: 'FALSE',
    includeFooter: 'TRUE',
    includeNavigationMenu: 'FALSE',
    introText:
      'Welcome to the BeatBossBASIC™ Certified Instructors Community Group of Facebook! This private group is where you can share and ask questions with all other Certified BeatBossBASIC™ Instructors. Getting support from other instructors is key to building community and support.',
    linkIcon: 'icon',
    linkPath: '/social',
    linkText: 'Social',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'BeatBossBASIC™ Social Community',
    sortBy: 5,
  },
  {
    id: '538dce2e-bb0e-495f-a857-b5683eee761a',
    adminOnly: 'FALSE',
    includeFooter: 'FALSE',
    includeNavigationMenu: 'TRUE',
    introText:
      'At the beginning of month 7 you will have access to the amazing BeatBoss™  Guided Freestyle™ option! This is a space where you will be able to create your own BeatBossBASIC™ Class Layouts using individual BeatBoss Actions/Components housed in the Actions/Components Library. Each month we will add new Actions/Components to build out your BeatBossBASIC™ Library of offerings.',
    linkIcon: 'icon',
    linkPath: '/freestyle/list',
    linkText: 'Guided Freestyle',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'Guided Freestyle™',
    sortBy: 4,
  },
  {
    id: '581b2c86-d619-4e81-945c-58b2ea3ab700',
    adminOnly: 'FALSE',
    includeFooter: 'TRUE',
    includeNavigationMenu: 'TRUE',
    introText:
      'Welcome BeatBossBASIC™ Certified Instructors! Congratulations on becoming officially BeatBossBASIC™ Certified and welcome to the official BeatBossONline™ Instructor Network; CE (Continuing Education) Instructor Portal.  From here, you will take all that you initially learned during the 16 hour Certification and Proficiency Video Submissions with Coaching Notes and keep growing step by step, month by month. We are here for you every step of the way providing all-encompassing tools you will need to keep growing as the amazing BeatBossBASIC™ Certified Instructor that you are! Please take a moment to listen to the WELCOME Video and to learn more about all the great offerings on the BeatBossONline™ Network.',
    linkIcon: 'icon',
    linkPath: '/',
    linkText: 'Home',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'Home',
    sortBy: 1,
  },
  {
    id: '65a5e6c6-ac73-4f9d-9ee3-b6555ef6833d',
    adminOnly: 'TRUE',
    includeFooter: 'FALSE',
    includeNavigationMenu: 'FALSE',
    introText: 'Edit site pages.',
    linkIcon: 'icon',
    linkPath: '/admin/pages/list',
    linkText: 'Admin/Pages',
    menuSection: 'Admin',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'Site Pages',
    sortBy: 2,
  },
  {
    id: '6c0e06c5-3e9a-4c68-b731-61c076f92d42',
    adminOnly: 'FALSE',
    includeFooter: 'TRUE',
    includeNavigationMenu: 'FALSE',
    introText:
      "Have you seen an Upper Body Add-on's/created an Upper Body Add-on or Drill that you would like to upload as a suggestion to use in your BeatBossBASIC™ Classes? Here is a space you can share with BeatBossHQ those ideas! Simply Upload a video of your suggestion (video yourself demonstrating), we will review and potentially add to the BeatBossBASIC™ Class Layouts and Action/Components Library if approved! We consider the Add-on and Drill submissions based on supporting BeatBoss™ Methodology and Science Purpose before approving.",
    linkIcon: 'icon',
    linkPath: '/suggest_upload',
    linkText: 'Suggestion Upload',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content - https://chat.whatsapp.com/DIduxQdzNhK3DWt9ZESzcK',
    pageTitle: 'Idea/Suggestion Upload',
    sortBy: 4,
  },
  {
    id: '8d8a6d4a-7c55-4a27-94f8-d411bd839838',
    adminOnly: 'FALSE',
    includeFooter: 'TRUE',
    includeNavigationMenu: 'TRUE',
    introText:
      'Welcome to the BeatBossONline™ Instructor Coaching  Zoom Studio. In this space you will have opportunity to participate in affordable individual and group coaching to escalate your skills as a BeatBossBASIC™ Certified Instructor. Meet with BeatBoss™ Master Trainers and learn directly from them! We will have themed group coaching packages, along with ala carte individual coaching offerings that will enhance your growth as a BeatBossBASIC™ Certified Instructor.  To enter the Zoom Studio please simply click on the link on this page.',
    linkIcon: 'icon',
    linkPath: '/online_coaching',
    linkText: 'Online Coaching',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content - https://zoom.us/j/6512853424',
    pageTitle: 'Online Instructor Coaching',
    sortBy: 6,
  },
  {
    id: 'b776129d-9f2a-4aae-9f2c-5626d21410e2',
    adminOnly: 'FALSE',
    includeFooter: 'TRUE',
    includeNavigationMenu: 'FALSE',
    introText: 'Tools',
    linkIcon: 'icon',
    linkPath: '/tools',
    linkText: 'Additional Tools',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'Additional Tools',
    sortBy: 6,
  },
  {
    id: 'd4298dc1-fb38-46bb-b3ef-e109f9e4f188',
    adminOnly: 'FALSE',
    includeFooter: 'TRUE',
    includeNavigationMenu: 'FALSE',
    introText:
      'We highly recommend all BeatBossBASIC&trade; Certified Instructors and studios/health clubs that use our instructors to utilize two        different Class Naming Opportunities along with utilizing BeatBoss&trade; Approved hashtags when making social media posts.        <br/><br/>       Two class naming opportunities:<br/>       1. BeatBoss&trade; Indoor Biking or BeatBoss&trade; Indoor Cycling       <br/><br/>       2. Rhythm Ride (or another studio club specific name) followed by: Powered by BeatBoss&trade;       <br/><br/>       Class Description opportunities:<br/>       1. Your ultimate introduction to the amazing BeatBoss&trade; Methodology. This high calorie burn class is uniquely inspired by Road, Mountain, and        BMX Biking.  BeatBoss&trade; uses Riding The Beat and RPM/WATTS for the ultimate indoor cycling experience! All levels welcome.       <br/><br/>       2. Inspired by Road, Mountain, and BMX Biking. BeatBoss&trade; uses Riding The Beat and RPM/WATTS for the ultimate indoor cycling experience!        All levels welcome!       <br/><br/>        BeatBoss™️ Approved Hashtags for social media usage:       <br/><br/>       #beatboss #beatbossproud #beatbosscertified #beatbossworld #beatbossnation #beatbossbiking #purposetotheparty',
    linkIcon: 'icon',
    linkPath: '/brand_usage',
    linkText: 'BeatBoss™ Brand usage Guidelines',
    menuSection: 'Main',
    owner: 'dsr771',
    pageContent:
      'We require classes that are taught by Certified BeatBoss™ Instructors and studios/health clubs that use BeatBoss™ Certified Instructors utilize two different class naming options when naming classes for schedules along with utilizing BeatBoss™ approved hashtags when making social media posts. Two class naming opportunities: 1. BeatBoss™ Indoor Biking or BeatBoss™ Indoor Cycling   2. Rhythm Ride (or another studio club specific name) followed by: Powered by BeatBoss™ (Rhythm Ride Powered by BeatBoss™.  Class Description opportunities: 1. Your ultimate introduction to the amazing BeatBoss™Methodology. This high calorie burn class is uniquely inspired by Road, Mountain, and BMX Biking.  BeatBoss™uses Riding The Beat and RPM/WATTS for the ultimate indoor cycling experience! All levels welcome.  2. Inspired by Road, Mountain, and BMX Biking. BeatBoss™uses Riding The Beat and RPM/WATTS for the ultimate indoor cycling experience! All levels welcome. BeatBoss™️approved Hashtags for social media usage: #beatboss #beatbossproud #beatbosscertified #beatbossworld #beatbossnation #beatbossbiking #purposetotheparty',
    pageTitle: 'BeatBoss™ Brand Usage Guidelines and Logos',
    sortBy: 7,
  },
  {
    id: 'fc0478e1-cdc5-489d-8b22-e2b559b3682d',
    adminOnly: 'TRUE',
    includeFooter: 'FALSE',
    includeNavigationMenu: 'FALSE',
    introText: 'Edit users',
    linkIcon: 'icon',
    linkPath: '/admin/users/list',
    linkText: 'Admin/Users',
    menuSection: 'Admin',
    owner: 'dsr771',
    pageContent: 'content',
    pageTitle: 'User List',
    sortBy: 1,
  },
]

export const ActionComponentSeedData = [
  {
    id: '08ffaeea-c25b-47d1-99f5-316b87578f85',
    active: 'TRUE',
    coachingNotes:
      'WITHOUT MUSIC: Hi my name is, BeatBoss Certified, Ride the Beat, Inspired by Road, Mountain, BMX for greater cross training purposes, bike computer, finding PR, how to stop suddenly, how to modify, talk test, drink water all throughout, lets get started',
    folder: 1,
    name: 'Pre Class Script',
    owner: 'BeatBossAndi',
    pr_range: 0,
    rpm_high: 0,
    rpm_low: 0,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 3,
    level: 1,
    videoURL:
      'https://drive.google.com/file/d/1VHlN5e1bzYOaOGlmDWAkZjyWMV1p60sj/view?usp=sharing',
  },
  {
    id: '0d32f083-ee9a-48af-b5d0-4d9ab7dce407',
    active: 'TRUE',
    coachingNotes:
      'WITHOUT MUSIC: Hi my name is, BeatBoss Certified, Ride the Beat, Inspired by Road, Mountain, BMX for greater cross training purposes, bike computer, finding PR, how to stop suddenly, how to modify, talk test, drink water all throughout, lets get started',
    folder: 1,
    name: 'Pre Class Script',
    owner: 'dsr771',
    pr_range: 0,
    rpm_high: 0,
    rpm_low: 0,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 1,
    level: 1,
    videoURL:
      'https://drive.google.com/file/d/1VRcseUtZgpbbG4eAz7HXyU4_K6-Ii1Mq/view?usp=sharing',
  },
  {
    id: '1a5977ae-e097-447b-b523-81e4c779b0cf',
    active: 'TRUE',
    coachingNotes: 'Ride the Beat, 4 Count Jump, Rinse and Repeat',
    folder: 5,
    name: '4 Count Jump',
    owner: 'BeatBossAndi',
    pr_range: 'L M H',
    rpm_high: 115,
    rpm_low: 50,
    simpleCoaching: '4 Count Jump',
    sortBy: 2,
    level: 2,
    videoURL:
      'https://drive.google.com/file/d/1DOIamrjoAYd7ujguvSzZE66VO8CjaFWb/view?usp=sharing',
  },
  {
    id: '1f40af79-3dfe-4ad4-b901-efc33d4c4583',
    active: 'TRUE',
    coachingNotes: 'Ride the Beat, 2 Count Jump, Rinse and Repeat',
    folder: 5,
    name: '2 Count Jump',
    owner: 'BeatBossAndi',
    pr_range: 'L M H',
    rpm_high: 115,
    rpm_low: 50,
    simpleCoaching: '2 Count Jump',
    sortBy: 3,
    level: 9,
    videoURL: 'video',
  },
  {
    id: '287d8843-0efe-4ac6-b2f4-e9688eb9d6b4',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Steady PR, Steady RPM, 1st, 2nd, 3rd Handlebar options, Long and Strong Spine, (See Corresponding BWD%)',
    folder: 3,
    name: 'Seated Sprint',
    owner: 'BeatBossAndi',
    pr_range: 'L',
    rpm_high: 130,
    rpm_low: 111,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 5,
    level: 1,
    videoURL: 'video',
  },
  {
    id: '497c5640-cb8b-40a6-a5ec-bf356f9eeafe',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Steady PR, Steady RPM, 4th Handlebar Position, Long and Strong Spine, (See Corresponding BWD%)',
    folder: 3,
    name: 'Standing Drafting A',
    owner: 'BeatBossAndi',
    pr_range: 'L M H',
    rpm_high: 130,
    rpm_low: 40,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 8,
    level: 1,
    videoURL: 'video',
  },
  {
    id: '6151805e-3816-4230-a5f3-4a7bd679b2bc',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Hands in 2nd Out of Saddle then transition to Hands in 3rd Hover Ride the Beat, then verbally and non-verbally cue 4 Medium Count Slider then back into 4 Medium Count Hover then execute movement up to 8 times, “Rinse and Repeat”',
    folder: 9,
    name: 'Hover Into Slider 4 Count',
    owner: 'BeatBossAndi',
    pr_range: 'M H',
    rpm_high: 79,
    rpm_low: 45,
    simpleCoaching: 'Hover Into Slider 4 Count',
    sortBy: 5,
    level: 1,
    videoURL:
      'https://drive.google.com/file/d/1HaMEHArfgYHlqMY0DL6OB2u4jGT-KmYv/view?usp=sharing',
  },
  {
    id: '6218aa70-1c5e-4daf-9a7d-1c393a658804',
    active: 'TRUE',
    coachingNotes:
      'WITHOUT MUSIC: Hi my name is, BeatBoss Certified, Ride the Beat, Inspired by Road, Mountain, BMX for greater cross training purposes, bike computer, finding PR, how to stop suddenly, how to modify, talk test, drink water all throughout, lets get started',
    folder: 1,
    name: 'Pre Class Script',
    owner: 'BeatBossAndi',
    pr_range: 0,
    rpm_high: 0,
    rpm_low: 0,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 2,
    level: 1,
    videoURL:
      'https://drive.google.com/file/d/1wAaXiJpYL1mHOkvhQ8DzgdEf8oRv9oWo/view?usp=sharing',
  },
  {
    id: '6ccbe7b0-f4f4-4196-90e6-87e85dc931dc',
    active: 'TRUE',
    coachingNotes: 'Ride the Beat, Pulse Rhythmic Press, Rinse and Repeat',
    folder: 9,
    name: 'Pulse Rhythmic Press',
    owner: 'BeatBossAndi',
    pr_range: 'M H',
    rpm_high: 79,
    rpm_low: 45,
    simpleCoaching: 'Pulse(s)',
    sortBy: 3,
    level: 2,
    videoURL: 'video',
  },
  {
    id: '7293fc11-e756-4669-a944-35194a8ff732',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Steady PR, Steady RPM, 1st, 2nd, 3rd Handlebar options, Long and Strong Spine, (See Corresponding BWD%)',
    folder: 3,
    name: 'Standing Drafting B',
    owner: 'BeatBossAndi',
    pr_range: 'L M H',
    rpm_high: 130,
    rpm_low: 40,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 9,
    level: 1,
    videoURL: 'video',
  },
  {
    id: '828bc723-3e4b-49df-bf73-525c4b2cb55c',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Steady PR, Steady RPM, 1st, 2nd, 3rd Handlebar options, Long and Strong Spine, (See Corresponding BWD%)',
    folder: 3,
    name: 'Standing Hill',
    owner: 'BeatBossAndi',
    pr_range: 'M H',
    rpm_high: 79,
    rpm_low: 40,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 2,
    level: 1,
    videoURL: 'video',
  },
  {
    id: '83382faf-8bff-4bdc-ab3c-911860e80dbf',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Proper Form Head to Toe, Handlebar Changes, “Rinse and Repeat”',
    folder: 2,
    name: 'Warm Up',
    owner: 'BeatBossAndi',
    pr_range: 'L M',
    rpm_high: 110,
    rpm_low: 70,
    simpleCoaching: 'Warm Up',
    sortBy: 1,
    level: 1,
    videoURL:
      'https://drive.google.com/file/d/1sLbo7qpwc77uD6j15LuUwlcMls5PDIJ6/view?usp=sharing',
  },
  {
    id: '9c668cd1-3527-4371-bce5-c86678d7e0bf',
    active: 'TRUE',
    coachingNotes: 'Ride the Beat, Fast Single Presses, "Rinse and Repeat',
    folder: 9,
    name: 'Fast Single Rhythmic Press',
    owner: 'BeatBossAndi',
    pr_range: 'M H',
    rpm_high: 79,
    rpm_low: 45,
    simpleCoaching: 'Fast Single Press',
    sortBy: 2,
    level: 2,
    videoURL: 'video',
  },
  {
    id: '9f782bd6-d3dd-475a-b774-0f585afc3044',
    active: 'TRUE',
    coachingNotes: 'Ride the Beat, Speed Jump (1 Count)',
    folder: 5,
    name: 'Speed Jump (1 Count)',
    owner: 'BeatBossAndi',
    pr_range: 'L',
    rpm_high: 115,
    rpm_low: 80,
    simpleCoaching: 'Speed Jump (1 Count)',
    sortBy: 4,
    level: 9,
    videoURL: 'video',
  },
  {
    id: 'a1a89107-66f4-4af0-bcfe-678df65c8c5e',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Proper Form Head to Toe, Handlebar Changes, rider’s right hand 3 rd/rider’s left hand 2nd 8 Count then 4 Count, Optional: 8 Medium Count Out of Saddle and In the Saddle, “Rinse and Repeat”',
    folder: 2,
    name: 'Warm Up Advancing',
    owner: 'BeatBossAndi',
    pr_range: 'L M',
    rpm_high: 110,
    rpm_low: 70,
    simpleCoaching:
      'PROPER FORM, HANDLEBAR CHANGES, RIDERS’ RIGHT HAND 3RD/RIDERS’ LEFT HAND 2ND 8 CT THEN 4 CT OPTIONAL 8 MEDIUM CT OUT OF SADDLE/IN THE SADDLE',
    sortBy: 3,
    level: 6,
    videoURL:
      'https://drive.google.com/file/d/1oq_Qa-Dn5SIqAMK9_bkuGzS1NedMICR_/view?usp=sharing',
  },
  {
    id: 'aecba231-9936-4b9b-ad6f-80dce721354d',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Steady PR, Steady RPM, 1st, 2nd, 3rd Handlebar options, Long and Strong Spine, (See Corresponding BWD%)',
    folder: 3,
    name: 'Standing Jog',
    owner: 'BeatBossAndi',
    pr_range: 'L',
    rpm_high: 110,
    rpm_low: 80,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 4,
    level: 1,
    videoURL: 'video',
  },
  {
    id: 'b9186dec-e993-48b6-a6a4-4a361a36bf7c',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Steady PR, Steady RPM, 4th Handlebar Position, Long and Strong Spine, (See Corresponding BWD%)',
    folder: 3,
    name: 'Seated Drafting',
    owner: 'BeatBossAndi',
    pr_range: 'L M H',
    rpm_high: 130,
    rpm_low: 40,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 7,
    level: 1,
    videoURL:
      'https://drive.google.com/file/d/1ZFYg4YF_CglUWN0r_hiUYkYK1aX_GL1T/view?usp=sharing',
  },
  {
    id: 'c4020af7-dd5b-479d-b969-e6224c932551',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Steady PR, Steady RPM, 1st, 2nd, 3rd Handlebar options, Long and Strong Spine, (See Corresponding BWD%)',
    folder: 3,
    name: 'Seated Hill',
    owner: 'dsr771',
    pr_range: 'M H',
    rpm_high: 79,
    rpm_low: 40,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 1,
    level: 1,
    videoURL: 'video',
  },
  {
    id: 'c48cdfad-9501-4dfb-9e4b-b1c7248314ee',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Hands in 2nd Out of Saddle then transition to Hands in 3rd Hover Ride the Beat, then verbally and non-verbally cue 4 Medium Count Slider then back into 4 Medium Count Hover then execute movement up to 8 times, Then 2 Count “Rinse and Repeat”',
    folder: 9,
    name: 'Hover Into Slider 2 Count',
    owner: 'BeatBossAndi',
    pr_range: 'M H',
    rpm_high: 79,
    rpm_low: 45,
    simpleCoaching: 'Hover Into Slider 2 Count',
    sortBy: 6,
    level: 2,
    videoURL:
      'https://drive.google.com/file/d/1qTM_hlUDh5kzq42npB8GV6xn8kHhxQTL/view?usp=sharing',
  },
  {
    id: 'd1848f1f-49b5-4cb6-a63d-a975a0aa73ee',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Grinder first High then Low, Rinse and Repeat',
    folder: 9,
    name: 'Grinder',
    owner: 'BeatBossAndi',
    pr_range: 'H',
    rpm_high: 55,
    rpm_low: 40,
    simpleCoaching: 'Grinder High and Low',
    sortBy: 4,
    level: 1,
    videoURL:
      'https://drive.google.com/file/d/107aryvpd6hAeRXPOhnF3NgWYU2zhbjuS/view?usp=sharing',
  },
  {
    id: 'd611b010-8904-4b2a-964d-4636a496bb26',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Proper Form Head to Toe, Handlebar Changes, Ride the Beat, Out of Saddle 8 Count, In the Saddle 8 Count, “Rinse and Repeat”',
    folder: 2,
    name: 'Warm Up',
    owner: 'BeatBossAndi',
    pr_range: 'L M',
    rpm_high: 110,
    rpm_low: 70,
    simpleCoaching:
      'Warm Up with 8 Count Out of Saddle and In The Saddle addition',
    sortBy: 2,
    level: 2,
    videoURL:
      'https://drive.google.com/file/d/1DOHhtOgMMeul_mlOt-P2Hmo_bqljBiBo/view?usp=sharing',
  },
  {
    id: 'dc329a4e-3398-4a79-a9bb-41d5a35c5708',
    active: 'TRUE',
    coachingNotes: 'RIde the Beat, 8 Count Jump, Rinse and Repeat',
    folder: 5,
    name: '8 Count Jump',
    owner: 'BeatBossAndi',
    pr_range: 'L M H',
    rpm_high: 115,
    rpm_low: 50,
    simpleCoaching: '8 Count Jump',
    sortBy: 1,
    level: 1,
    videoURL:
      'https://drive.google.com/file/d/1bQADcJin_bPxCJeGIh6P3b7lAsEzD-S6/view?usp=sharing',
  },
  {
    id: 'e4aaa3d4-d35b-4b5f-ab52-1440ed346613',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Steady PR, Steady RPM, 1st, 2nd, 3rd Handlebar options, Long and Strong Spine, (See Corresponding BWD%)',
    folder: 3,
    name: 'Seated Jog',
    owner: 'BeatBossAndi',
    pr_range: 'L',
    rpm_high: 110,
    rpm_low: 80,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 3,
    level: 1,
    videoURL: 'video',
  },
  {
    id: 'f0a25f70-8697-45df-8335-efdedc122489',
    active: 'TRUE',
    coachingNotes: 'Ride the Beat, Slow Single Presses, "Rinse and Repeat',
    folder: 9,
    name: 'Slow Single Rhythmic Press',
    owner: 'dsr771',
    pr_range: 'M H',
    rpm_high: 79,
    rpm_low: 45,
    simpleCoaching: 'Slow Single Press',
    sortBy: 1,
    level: 1,
    videoURL:
      'https://drive.google.com/file/d/1qQFqhulc3YB25tN8Z7Y91Ky2xNwIhMLX/view?usp=sharing',
  },
  {
    id: 'f371458a-90d3-4421-b867-7a38d1ec416f',
    active: 'TRUE',
    coachingNotes:
      'Ride the Beat, Steady PR, Steady RPM, 1st, 2nd, 3rd Handlebar options, Long and Strong Spine, (See Corresponding BWD%)',
    folder: 3,
    name: 'Standing Sprint',
    owner: 'BeatBossAndi',
    pr_range: 'L',
    rpm_high: 130,
    rpm_low: 111,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 6,
    level: 1,
    videoURL: 'video',
  },
  {
    id: 'f3a07e22-6907-4630-b4a0-a01803ed2603',
    active: 'TRUE',
    coachingNotes:
      "WITHOUT MUSIC: Hi my name is, BeatBoss Certified, Ride the Beat, Inspired by Road, Mountain, BMX for greater cross training purposes, today's ride is focused on athletic conditioning with riding the beat and BB Drills, BWD%, and Universal Riding Positions using Coach by Color/Stages Flight/FTP/Heart Rate Zone Color, bike computer, finding PR, how to stop suddenly, how to modify, talk test, drink water all throughout, lets get started",
    folder: 1,
    name: 'Pre Class Script BeatBossPOWER',
    owner: 'BeatBossAndi',
    pr_range: 0,
    rpm_high: 0,
    rpm_low: 0,
    simpleCoaching: 'See Coaching Notes',
    sortBy: 4,
    level: 0,
    videoURL: 'video',
  },
]

export const CuratedClassSeedData = [
  {
    id: 'c1778c1f-d78b-41aa-9e25-3a83e53c1d61',
    group: 1,
    title: 'Master Class Layout 1',
    description:
      'Basic level master class layout. Utilize this curated layout as a detailed desk reference',
    active: true,
    type: 'curated',
    layoutLevel: 1,
    playlistURL:
      'https://open.spotify.com/playlist/0w9lc297jIZbVqBScV53SN?si=7qFqx0wcRreZaU3lordIng&fbclid=IwAR0-z81nuZv2NzL-WM0Qxa29s4aG5_EHZR9XhbalcXBPPXi8HpvfIifahgM',
    createdByUser: null,
    owner: 'dsr771',
  },
]

export const ClassSongSeedData = [
  {
    id: 'song_20191110_1',
    song: 'Watch Out For This',
    artist: 'Major Lazer',
    bpm_range_low: 70,
    bpm_range_high: 110,
    rpm_a: 108,
    rpm_b: 54,
    pr_range: 'L',
    pr_a: 'L',
    pr_b: 'L',
    coaching_notes:
      'Warm Up: Ride the Beat, Proper Form Head to Toe, Handlebar Changes, Ride the Beat, "Rinse and Repeat"',
    coaching_notes_simple: 'Warm Up: Ride the Beat',
    sortOrder: 1,
    videoURL: '',
    active: true,
    curatedClass: { id: 'c1778c1f-d78b-41aa-9e25-3a83e53c1d61' },
  },
  {
    id: 'song_20191110_2',
    song: 'Trespassing',
    artist: 'Adam Lambert',
    bpm_range_low: 45,
    bpm_range_high: 80,
    rpm_a: 60,
    rpm_b: 0,
    pr_range: 'LMH',
    pr: 'M',
    pr_b: 'M',
    coaching_notes: 'Ride the Beat, Slow Single Presses, "Rinse and Repeat"',
    coaching_notes_simple: 'Slow Single Presses',
    sortOrder: 1,
    videoURL: '',
    active: true,
    curatedClass: { id: 'c1778c1f-d78b-41aa-9e25-3a83e53c1d61' },
  },
]
