const isPageVisibleToUser = (page,activeUser) => {
	if (page.adminOnly !== true) {
		return true
	}
	if (page.adminOnly && activeUser.isAdminUser()) {
		return true
	} else {
		return false
	}
}

export default isPageVisibleToUser