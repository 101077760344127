import GraphQLAdapter from './GraphQLAdapter'
import { listFreestyleSongs, getFreestyleSong } from '../../../graphql/queries'
import {
  createFreestyleSong,
  updateFreestyleSong,
  deleteFreestyleSong,
} from '../../../graphql/mutations'
// import { createFreestyleSong, updateFreestyleSongWithoutSongs } from '../../../graphql/classSongMutations'
// import { listFreestyleSongsWithComponent } from '../../../graphql/classSongQueries'
import {
  FREESTYLE_SONG_ENTITY_STRING_LIST,
  FREESTYLE_SONG_ENTITY_NUMBER_LIST,
} from '../../entities/FreestyleSongEntity'
// import { FreestyleSongSeedData } from './JSONSeedData'

const _queryParams = { limit: 999999 }

export default class FreestyleSongGraphQLAdapter extends GraphQLAdapter {
  constructor() {
    super()
    this._adapter = new GraphQLAdapter()
  }

  async createEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // console.info('FreestyleSongGraphQLAdapter: createEntity  entity= ', entity)
    const response = await this._adapter
      .mutation(createFreestyleSong, { input: entity })
      .catch(e => {
        this.logDBError(e)
      })
    // console.info('createEntity = ',response)
    if (response) {
      return response.data.createFreestyleSong
    } else {
      return []
    }
  }

  async fetchEntity(id) {
    const response = await this._adapter
      .query(getFreestyleSong, { id: id })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.log('FreestyleSongGraphQLAdapter: fetchEntity: response=',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.getFreestyleSong
    } else {
      return isError
    }
  }

  async updateEntity(entity) {
    entity = this.mapEntityToAdapter(entity)
    // console.info('FreestyleSongGraphQLAdapter: updateEntity  entity= ', entity)
    const response = await this._adapter
      .mutation(updateFreestyleSong, { input: entity })
      .catch(e => {
        return this.logDBError(e)
      })
    // console.info('FreestyleSongGraphQLAdapter: updateEntity  response= ',response)
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.updateFreestyleSong
    } else {
      return isError
    }
  }

  // deleteEntity
  async deleteEntity(id) {
    // console.info('FreestyleSongGraphQLAdapter: deleteEntity  id= ', id)
    const response = await this._adapter
      .mutation(deleteFreestyleSong, { input: { id: id } })
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.deleteFreestyleSong === null) {
        return null
      }
      return response.data.deleteFreestyleSong
    } else {
      return isError
    }
  }

  async fetchAll(queryParams) {
    if (queryParams === undefined) {
      queryParams = _queryParams
    } else {
      queryParams = { ..._queryParams, ...queryParams }
    }
    const response = await this._adapter
      .query(listFreestyleSongs, queryParams)
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      if (response.data.listActionComponents.items.length === 0) {
        this._seedData()
      }
      return response.data.listFreestyleSongs.items
    } else {
      return isError
    }
  }

  async fetchFreestyleSongsForLevel(level) {
    const response = await this._adapter
      .query(listFreestyleSongs, { filter: { layoutLevel: { le: level } } })
      .catch(e => {
        return this.logDBError(e)
      })
    const isError = this.checkForError(response)
    if (!isError) {
      return response.data.listFreestyleSongs.items
    } else {
      return isError
    }
  }

  mapEntityToAdapter(entity) {
    if (entity.id === '_new_') {
      entity.id = null
    }
    if (entity.freestyleSongActionComponentId) {
      entity.actionComponent = { id: entity.freestyleSongActionComponentId }
    }
    if (
      typeof entity.actionComponent !== 'undefined' &&
      entity.actionComponent !== null
    ) {
      entity.freestyleSongActionComponentId = entity.actionComponent.id
    }

    if (entity.freestyleSongFreestyleClassId) {
      entity.freestyleClass = { id: entity.freestyleSongFreestyleClassId }
    }
    if (
      typeof entity.freestyleClass !== 'undefined' &&
      entity.freestyleClass !== null
    ) {
      entity.freestyleSongFreestyleClassId = entity.freestyleClass.id
    }
    // INT values should not be empty string
    FREESTYLE_SONG_ENTITY_NUMBER_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = 0
      }
    })
    // STRING values should be null instead of ''
    FREESTYLE_SONG_ENTITY_STRING_LIST.forEach(field => {
      if (entity[field] === '') {
        entity[field] = null
      }
    })

    delete entity.actionComponent
    delete entity.freestyleClass

    return entity
  }

  // _seedData() {
  //   FreestyleSongSeedData.map(action => {
  //     this.createEntity(action)
  //     return null
  //   })
  // }
}
