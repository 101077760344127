import React from 'react'
import { PropTypes } from 'prop-types'
import { Container, Paper, Button } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'

import PageTitle from './PageTitle'
import PageIntro from './PageIntro'

export const Page = ({ children }) => (
  <Container disableGutters>{children}</Container>
)
Page.propTypes = {
  children: PropTypes.node,
}

Page.WithTitle = ({ pageContent, children, ...props }) => {
  const styles = {
    paper: {
      padding: 20,
      minHeight: 200,
      marginTop: 10,
      marginBottom: 10,
    },
  }

  return (
    <Page {...props}>
      <Paper style={styles.paper}>
        <PageTitle pageTitle={pageContent.pageTitle} />
        {children}
      </Paper>
    </Page>
  )
}
Page.WithTitle.propTypes = {
  pageContent: PropTypes.object,
  children: PropTypes.node,
}

Page.WithIntro = ({ pageContent, children, ...props }) => {
  const styles = {
    paper: {
      padding: 20,
      minHeight: 200,
      marginTop: 10,
      marginBottom: 10,
    },
  }

  return (
    <Page {...props}>
      <Paper style={styles.paper}>
        <PageIntro
          pageTitle={pageContent.pageTitle}
          introText={pageContent.introText}
        />
        {pageContent.buttonLink ? (
          <RenderButton
            label={pageContent.buttonLabel}
            url={pageContent.buttonLink}
          />
        ) : null}
        {pageContent.videoLink ? (
          <RenderVideoButton
            label={pageContent.videoLabel}
            url={pageContent.videoLink}
          />
        ) : null}
        {children}
      </Paper>
    </Page>
  )
}
Page.WithIntro.propTypes = {
  pageContent: PropTypes.object,
  children: PropTypes.node,
}

const RenderButton = ({ label = 'Link', url }) => {
  return (
    <Button variant="contained" href={url} target="_external">
      <LinkIcon />
      {label}
    </Button>
  )
}

const RenderVideoButton = ({ label = 'Video', url }) => {
  return (
    <Button variant="contained" href={url} target="_external_video">
      <LinkIcon />
      {label}
    </Button>
  )
}
